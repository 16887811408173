import { useDispatch } from 'react-redux';
import { Modal, Paper, Grid, FormLabel } from "@mui/material";
import { Close } from "@mui/icons-material";

import { handleFreezeRenting, showFreezeRentingModal } from './masterlistSlice';

const FreezeRentingModal = () => {
  const dispatch = useDispatch();

  const submit = async () => {
    try {
      dispatch(handleFreezeRenting());
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="freeze-masterlist-data-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[30%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close className="float-right cursor-pointer" onClick={() => dispatch(showFreezeRentingModal(false))} />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Freeze Renting</p>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
                <FormLabel component="legend">Are you sure you want to freeze this paying subscriber?</FormLabel>
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <button onClick={submit} className="bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md">Confirm</button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}

export default FreezeRentingModal;