import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Paper,
  Grid,
  TextField,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import {
  handleUpdateAffiliateCampaign,
  selectState,
  showEditModal,
} from "./affiliateCampaignSlice";

const EditCampaignModal = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();

  const affiliateCampaign= state.selectedCampaign;
  const [endDate, setEndDate] = useState(affiliateCampaign.endDate);

  const submit = () => {
    try {
      const payload = {
        endDate
      };
      dispatch(handleUpdateAffiliateCampaign(payload));
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Modal
      open={true}
      aria-labelledby="edit-affiliate-campaign-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[20%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showEditModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Edit Affiliate Campaign</p>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <TextField
                id="endDate"
                label="End Date"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
                margin="normal"
                variant="outlined"
                type="date"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <button
                onClick={submit}
                className="bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md"
              >
                Update
              </button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default EditCampaignModal;
