import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Table, TableBody, TableCell, TableContainer, TableRow, TableSortLabel, Paper, Checkbox, TablePagination, TableFooter } from "@mui/material";
import styles from './Appointment.module.css';
import { StickyTableHead } from '../../../helpers/GeneralComponent';

import ViewOrderGroupModal from './ViewOrderGroupModal';
import DeleteAppointmentModal from './DeleteAppointmentModal';
import ParkitNav from '../../../components/ParkitNav/ParkitNav';
import { TableToolbar } from './TableToolbar';
import TablePaginationActions from './TablePaginationActions';
import { handleGetAppointments, setSelectedAppointment, showViewOrderGroupModal, selectState, setPage, setRowsPerPage, setSort, setSortBy, setErrorMessage, setSuccessMessage, setSelectedId } from './appointmentSlice';
import { selectState as selectParkitNav } from '../../../components/ParkitNav/parkitNavSlice';
import useToasts from '../../../hooks/useToasts';

export function Appointment() {
  const state = useSelector(selectState);
  const parkitNavState = useSelector(selectParkitNav);
  const dispatch = useDispatch();
  const { toastError, toastSuccess } = useToasts();

  useEffect(() => {
    dispatch(handleGetAppointments());
  }, [state.sort, state.sortBy, dispatch]);

  useEffect(() => {
    if (state.successMessage !== "") {
      toastSuccess(state.successMessage);
      dispatch(setSuccessMessage(""));
    }
  }, [dispatch, toastSuccess, state.successMessage]);

  useEffect(() => {
    if (state.errorMessage !== "") {
      toastError(state.errorMessage);
      dispatch(setErrorMessage(""));
    }
  }, [dispatch, toastError, state.errorMessage]);

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetAppointments());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetAppointments());
  };

  const handleRequestSort = (property) => {
    const isDescending = state.sortBy === property && state.sort === 'desc';
    dispatch(setSort(isDescending ? 'asc' : 'desc'));
    dispatch(setSortBy(property));
  };

  return (
    <div className={`${parkitNavState.open ? 'ml-60' : 'ml-12'}`}>
      <ParkitNav />
      <div className="p-10 text-left">
        <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
          <div className="h-[64px] sticky left-0 top-0 z-[1] right-0 bg-white">
            <div className="h-[64px] absolute left-0 top-0 right-0">
              <TableToolbar
                numSelected={state.selectedId === null ? 0 : 1}
              />
            </div>
          </div>
          <Table size="small">
            <StickyTableHead>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Customer Email</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Customer First Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Customer Contact Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>
                  <TableSortLabel
                    active={state.sortBy === 'eventDateTime'}
                    direction={state.sortBy === 'eventDateTime' ? state.sort : 'desc'}
                    onClick={() => handleRequestSort('eventDateTime')}
                  >
                    Appointment Date
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={styles.tableHeader}>Deliverit Order Group</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Status</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Meetup Address</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Office Type</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Event Summary</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Event Remark</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Auto Activate</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Listing ID</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Renting ID</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Appointment ID</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Created At</TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {
                state.appointments.length > 0 ? (
                  state.appointments.map((appointment) => (
                    <TableRow
                      key={appointment.id}
                      hover
                      onClick={() => { dispatch(setSelectedId(state.selectedId === appointment.id ? null : appointment.id)); dispatch(setSelectedAppointment(state.selectedId === appointment.id ? null : appointment)) }}
                      role="checkbox"
                      aria-checked={state.selectedId === appointment.id ? true : false}
                      selected={state.selectedId === appointment.id ? true : false}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={state.selectedId === appointment.id ? true : false}
                        />
                      </TableCell>
                      <TableCell align="left" className={styles.tableCell}>{appointment.customerEmail}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{appointment.customerFirstName}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{appointment.customerContact}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{appointment.appointmentDate}</TableCell>
                      <TableCell align="left" className={styles.tableCell}><Button variant="text" size="small" onClick={() => dispatch(showViewOrderGroupModal(true))} sx={{ textTransform: "none" }} disabled={state.selectedId === appointment.id}>View</Button></TableCell>
                      <TableCell align="left" className={styles.tableCell}>{appointment.status}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{appointment.meetupAddress}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{appointment.officeType}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{appointment.summary}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{appointment.remark}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{appointment.autoActivate? "YES" : "NO"}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{appointment.listingId}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{appointment.rentingId}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{appointment.id}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{appointment.createdAt}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={27} align="center">
                      <p>No appointment.</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100, 500, 1000]}
                  colSpan={4}
                  count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                  rowsPerPage={state.rowsPerPage}
                  page={state.page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      {state.viewOrderGroupModalOpen && <ViewOrderGroupModal />}
      {state.deleteModalOpen && <DeleteAppointmentModal />}
    </div>
  );
}