import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade } from "@mui/material";
import { closeCreateDeliveryRateModal, handleGetDeliveryRateProfiles } from './deliveryRateSlice';
import { Close } from "@mui/icons-material";
import styles from './DeliveryRate.module.css';
import { createDeliveryRateProfile } from './deliveryRateAPI';

export function CreateDeliveryRate(props) {
  const dispatch = useDispatch();
  const initialState = {
    form: {
      name: "",
      carRate: {
        base: "3.2",
        min: "6.0",
        tier1: "0.85",
        tier2: "1.05",
        next: "1.45",
        discount: "0",
        additionalStop: "2.0",
        doorToDoor: "200"
      },
      motorRate: {
        base: "3.2",
        min: "5.0",
        tier1: "0.65",
        tier2: "0.9",
        next: "1.10",
        discount: "0",
        additionalStop: "1.0",
        doorToDoor: "200"
      },
    },
    error: false,
    errorMessage: ""
  };

  const [deliveryRateState, _setDeliveryRateState] = useStateWithCallbackLazy({
    ...initialState
  });

  const deliveryRateStateRef = useRef(deliveryRateState);

  const setDeliveryRateState = data => {
    deliveryRateStateRef.current = data;
    _setDeliveryRateState(data);
  };

  const onFormValueChange = (data) => {
    setDeliveryRateState({
      ...deliveryRateState,
      form: {
        ...deliveryRateState.form,
        [data.name]: data.value
      }
    })
  }

  const onCarRateFormValueChange = (data) => {
    setDeliveryRateState({
      ...deliveryRateState,
      form: {
        ...deliveryRateState.form,
        carRate: {
          ...deliveryRateState.form.carRate,
          [data.name]: data.value
        }
      }
    })
  }

  const onMotorRateFormValueChange = (data) => {
    setDeliveryRateState({
      ...deliveryRateState,
      form: {
        ...deliveryRateState.form,
        motorRate: {
          ...deliveryRateState.form.motorRate,
          [data.name]: data.value
        }
      }
    })
  }

  const submit = async (props) => {
    let error = false;
    
    for (var key in deliveryRateState.form.carRate) {
      if (deliveryRateState.form.carRate[key] === null || deliveryRateState.form.carRate[key] === "") {
        error = true;
        setDeliveryRateState({...deliveryRateState, error: true, errorMessage: "All fields are required"});
        return;
      }
    }

    for (var motorkey in deliveryRateState.form.motorRate) {
      if (deliveryRateState.form.motorRate[motorkey] === null || deliveryRateState.form.motorRate[motorkey] === "") {
        error = true;
        setDeliveryRateState({...deliveryRateState, error: true, errorMessage: "All fields are required"});
        return;
      }
    }

    if (deliveryRateState.form.carRate.discount > 1 || deliveryRateState.form.motorRate.discount > 1) {
      error = true;
      setDeliveryRateState({...deliveryRateState, error: true, errorMessage: "Discount value cannot be more than 100"});
      return;
    }

    let deliveryRatePayload = {
      ...deliveryRateState.form
    }
    
    if (!error) {
      try {
        await createDeliveryRateProfile(deliveryRatePayload);
        dispatch(closeCreateDeliveryRateModal());
        dispatch(handleGetDeliveryRateProfiles());
      } catch (error) {
        setDeliveryRateState({...deliveryRateState, error: true, errorMessage: error.message});
      }
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="add-order-group-modal"
      className={styles.modal}
    >
      <Paper className={`${styles.modalContainer} scrollbar-hidden`}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeCreateDeliveryRateModal())}/>
          <h3 className={styles.subtitle}>Delivery Rate <em style={{fontSize: 12}}>(All fields are required)</em></h3>
          <Fade in={deliveryRateState.error}>
            <p className={styles.errorMessage}>{deliveryRateState.errorMessage}</p>
          </Fade>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <p>Name</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="name"
                  name="name"
                  value={deliveryRateState.form.name}
                  onChange={e => onFormValueChange({name: "name", value: e.target.value})}
                  placeholder="Name"
                  className={styles.textInput}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <p>Car Rate</p>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <label className={styles.label}>Base Fare</label>
                  <Paper className={styles.textInputContainer}>
                    <input
                      id="carbase"
                      name="carbase"
                      value={deliveryRateState.form.carRate.base}
                      onChange={e => onCarRateFormValueChange({name: "base", value: e.target.value})}
                      placeholder="4.2"
                      type="number"
                      InputProps={{
                          onWheel: (e) => e.target.blur()
                      }}
                      step="0.01"
                      min="0"
                      className={styles.textInput}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label className={styles.label}>Min Fare</label>
                  <Paper className={styles.textInputContainer}>
                    <input
                      id="carmin"
                      name="carmin"
                      value={deliveryRateState.form.carRate.min}
                      onChange={e => onCarRateFormValueChange({name: "min", value: e.target.value})}
                      placeholder="6.0"
                      type="number"
                      InputProps={{
                          onWheel: (e) => e.target.blur()
                      }}
                      step="0.01"
                      min="0"
                      className={styles.textInput}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label className={styles.label}>Tier 1</label>
                  <Paper className={styles.textInputContainer}>
                    <input
                      id="cartier1"
                      name="cartier1"
                      value={deliveryRateState.form.carRate.tier1}
                      onChange={e => onCarRateFormValueChange({name: "tier1", value: e.target.value})}
                      placeholder="1.85"
                      type="number"
                      InputProps={{
                          onWheel: (e) => e.target.blur()
                      }}
                      step="0.01"
                      min="0"
                      className={styles.textInput}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label className={styles.label}>Tier 2</label>
                  <Paper className={styles.textInputContainer}>
                    <input
                      id="cartier1"
                      name="cartier1"
                      value={deliveryRateState.form.carRate.tier2}
                      onChange={e => onCarRateFormValueChange({name: "tier2", value: e.target.value})}
                      placeholder="2.05"
                      type="number"
                      InputProps={{
                          onWheel: (e) => e.target.blur()
                      }}
                      step="0.01"
                      min="0"
                      className={styles.textInput}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label className={styles.label}>Subsequent KM</label>
                  <Paper className={styles.textInputContainer}>
                    <input
                      id="carnext"
                      name="carnext"
                      value={deliveryRateState.form.carRate.next}
                      onChange={e => onCarRateFormValueChange({name: "next", value: e.target.value})}
                      placeholder="2.45"
                      type="number"
                      InputProps={{
                          onWheel: (e) => e.target.blur()
                      }}
                      step="0.01"
                      min="0"
                      className={styles.textInput}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label className={styles.label}>Additional Stop</label>
                  <Paper className={styles.textInputContainer}>
                    <input
                      id="carstop"
                      name="carstop"
                      value={deliveryRateState.form.carRate.additionalStop}
                      onChange={e => onCarRateFormValueChange({name: "additionalStop", value: e.target.value})}
                      placeholder="2"
                      type="number"
                      InputProps={{
                          onWheel: (e) => e.target.blur()
                      }}
                      step="0.01"
                      min="0"
                      className={styles.textInput}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label className={styles.label}>Discount (%)</label>
                  <Paper className={styles.textInputContainer}>
                    <input
                      id="cardiscount"
                      name="cardiscount"
                      value={deliveryRateState.form.carRate.discount * 100}
                      onChange={e => onCarRateFormValueChange({name: "discount", value: e.target.value / 100})}
                      placeholder="35"
                      type="number"
                      InputProps={{
                          onWheel: (e) => e.target.blur()
                      }}
                      step="1"
                      min="0"
                      max="100"
                      className={styles.textInput}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label className={styles.label}>Door To Door (cent)</label>
                  <Paper className={styles.textInputContainer}>
                    <input
                      id="cardoortodoor"
                      name="cardoortodoor"
                      value={deliveryRateState.form.carRate.doorToDoor}
                      onChange={e => onCarRateFormValueChange({name: "doorToDoor", value: e.target.value})}
                      placeholder="200"
                      type="number"
                      InputProps={{
                          onWheel: (e) => e.target.blur()
                      }}
                      min="0"
                      className={styles.textInput}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <p>Motor Rate</p>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <label className={styles.label}>Base Fare</label>
                  <Paper className={styles.textInputContainer}>
                    <input
                      id="motorbase"
                      name="motorbase"
                      value={deliveryRateState.form.motorRate.base}
                      onChange={e => onMotorRateFormValueChange({name: "base", value: e.target.value})}
                      placeholder="4.2"
                      type="number"
                      InputProps={{
                          onWheel: (e) => e.target.blur()
                      }}
                      step="0.01"
                      min="0"
                      className={styles.textInput}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label className={styles.label}>Min Fare</label>
                  <Paper className={styles.textInputContainer}>
                    <input
                      id="motormin"
                      name="motormin"
                      value={deliveryRateState.form.motorRate.min}
                      onChange={e => onMotorRateFormValueChange({name: "min", value: e.target.value})}
                      placeholder="6.0"
                      type="number"
                      InputProps={{
                          onWheel: (e) => e.target.blur()
                      }}
                      step="0.01"
                      min="0"
                      className={styles.textInput}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label className={styles.label}>Tier 1</label>
                  <Paper className={styles.textInputContainer}>
                    <input
                      id="motortier1"
                      name="motortier1"
                      value={deliveryRateState.form.motorRate.tier1}
                      onChange={e => onMotorRateFormValueChange({name: "tier1", value: e.target.value})}
                      placeholder="1.85"
                      type="number"
                      InputProps={{
                          onWheel: (e) => e.target.blur()
                      }}
                      step="0.01"
                      min="0"
                      className={styles.textInput}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label className={styles.label}>Tier 2</label>
                  <Paper className={styles.textInputContainer}>
                    <input
                      id="motortier1"
                      name="motortier1"
                      value={deliveryRateState.form.motorRate.tier2}
                      onChange={e => onMotorRateFormValueChange({name: "tier2", value: e.target.value})}
                      placeholder="2.05"
                      type="number"
                      InputProps={{
                          onWheel: (e) => e.target.blur()
                      }}
                      step="0.01"
                      min="0"
                      className={styles.textInput}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label className={styles.label}>Subsequent KM</label>
                  <Paper className={styles.textInputContainer}>
                    <input
                      id="motornext"
                      name="motornext"
                      value={deliveryRateState.form.motorRate.next}
                      onChange={e => onMotorRateFormValueChange({name: "next", value: e.target.value})}
                      placeholder="2.45"
                      type="number"
                      InputProps={{
                          onWheel: (e) => e.target.blur()
                      }}
                      step="0.01"
                      min="0"
                      className={styles.textInput}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label className={styles.label}>Additional Stop</label>
                  <Paper className={styles.textInputContainer}>
                    <input
                      id="motorstop"
                      name="motorstop"
                      value={deliveryRateState.form.motorRate.additionalStop}
                      onChange={e => onMotorRateFormValueChange({name: "additionalStop", value: e.target.value})}
                      placeholder="2"
                      type="number"
                      InputProps={{
                          onWheel: (e) => e.target.blur()
                      }}
                      step="0.01"
                      min="0"
                      className={styles.textInput}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label className={styles.label}>Discount</label>
                  <Paper className={styles.textInputContainer}>
                    <input
                      id="motordiscount"
                      name="motordiscount"
                      value={deliveryRateState.form.motorRate.discount * 100}
                      onChange={e => onMotorRateFormValueChange({name: "discount", value: e.target.value / 100})}
                      placeholder="35"
                      type="number"
                      InputProps={{
                          onWheel: (e) => e.target.blur()
                      }}
                      step="1"
                      min="0"
                      max="100"
                      className={styles.textInput}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <label className={styles.label}>Door To Door (cent)</label>
                  <Paper className={styles.textInputContainer}>
                    <input
                      id="motordoortodoor"
                      name="motordoortodoor"
                      value={deliveryRateState.form.motorRate.doorToDoor}
                      onChange={e => onMotorRateFormValueChange({name: "doorToDoor", value: e.target.value})}
                      placeholder="200"
                      type="number"
                      InputProps={{
                          onWheel: (e) => e.target.blur()
                      }}
                      min="0"
                      className={styles.textInput}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className={styles.submitButtonContainer}>
                <button onClick={() => submit(props)} className="bg-gradient-to-r from-[#6AC4C5] to-[#387596] shadow-md text-white h-12 px-8 w-48 rounded">Submit</button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}
