import axiosApiInstance from "../../../app/axios";
import { API_BASE_URL } from "../../../app/utils";
import { getJwtToken } from "../../../app/common";

export const getAllVendors = async (page, size) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/deliverit/v1/vendors?page=${page}&size=${size}`,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const updateVendor = async (data, id) =>
  axiosApiInstance.put(
    `${API_BASE_URL}/deliverit/v1/vendors/${id}`,
    data,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );
