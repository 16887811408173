import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { SubscriptionStatus } from '../../../app/constants';
import { getParkitSubscriptions, manualChargeParkitSubscription } from '../../../api/parkitSubscriptionAPI';

export const subscriptionSlice = createSlice({
  name: 'parkitFailedSubscription',
  initialState: {
    failedSubscriptions: [],
    failedSubscriptionPagination: {},
    failedSubscriptionPage: 0,
    failedSubscriptionRowsPerPage: 10,
    selectedFailedSubscriptionId: null,
    confirmRetryPaymentModalOpen: false,
    successMessage: "",
    errorMessage: ""
  },
  reducers: {
    openConfirmRetryPaymentModal: (state) => {
      state.confirmRetryPaymentModalOpen = true;
    },
    closeConfirmRetryPaymentModal: (state) => {
      state.confirmRetryPaymentModalOpen = false;
    },
    setSelectedFailedSubscriptionId: (state, action) => {
      state.selectedFailedSubscriptionId = action.payload;
    },
    setFailedSubscriptionPage: (state, action) => {
      state.failedSubscriptionPage = action.payload;
    },
    setFailedSubscriptionRowsPerPage: (state, action) => {
      state.failedSubscriptionRowsPerPage = action.payload;
    },
    setFailedSubscriptionPagination: (state, action) => {
      state.failedSubscriptionPagination = action.payload;
    },
    setFailedSubscriptions: (state, action) => {
      state.failedSubscriptions = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  }
});

export const { openConfirmRetryPaymentModal, closeConfirmRetryPaymentModal, setSelectedFailedSubscriptionId, setFailedSubscriptionPage, setFailedSubscriptionRowsPerPage, setFailedSubscriptionPagination, setFailedSubscriptions, setSuccessMessage, setErrorMessage } = subscriptionSlice.actions;

export const selectState = state => state.parkitFailedSubscription;

export const handleGetFailedSubscriptions = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const response = await getParkitSubscriptions(state.parkitFailedSubscription.failedSubscriptionPage, state.parkitFailedSubscription.failedSubscriptionRowsPerPage, `${SubscriptionStatus.FAILED},${SubscriptionStatus.RETRYING}`);
    const { content, ...pagination } = response.data;

    dispatch(setFailedSubscriptionPagination(pagination));
    dispatch(setFailedSubscriptions(content));
  } catch (error) {
    dispatch(setErrorMessage(error.response?.data?.message ?? error.message));
    dispatch(setFailedSubscriptions([]));
  } finally {
    dispatch(hideLoading());
  }
}

export const handleRetryPayment = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();
  try {
    await manualChargeParkitSubscription(state.parkitFailedSubscription.selectedFailedSubscriptionId);
    dispatch(handleGetFailedSubscriptions());
    dispatch(closeConfirmRetryPaymentModal());
    dispatch(setSuccessMessage('Subscription payment retry requested successfully'));
  } catch (error) {
    dispatch(setErrorMessage(error.response?.data?.message ?? error.message));
  } finally {
    dispatch(hideLoading());
  }
}

export default subscriptionSlice.reducer;