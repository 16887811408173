import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Checkbox, Toolbar, Typography, Breadcrumbs, Link } from "@mui/material";
import { EditOrder } from './EditOrder';
import { ConfirmDelete } from './ConfirmDelete';
import { CancelOrder } from './CancelOrder';
import { TableToolbar } from './TableToolbar';
import { openCancelOrderModal, handleGetOrdersByOrderGroup, selectState, setSelectedId } from './orderSlice';
import { selectState as selectDeliveritNav } from '../../../components/DeliveritNav/deliveritNavSlice';
import styles from './Order.module.css';
import Moment from 'moment';
import DeliveritNav from '../../../components/DeliveritNav/DeliveritNav';
import { StickyTableHead } from '../../../helpers/GeneralComponent';

export function Order() {
  const state = useSelector(selectState);
  const deliveritNavState = useSelector(selectDeliveritNav);
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetOrdersByOrderGroup(id));
    }
  });

  const getTotalDeliveryCharges = (orders) => {
    let totalCharges = 0;

    if (orders && orders.length > 0) {
      if (orders.length > 1) {
        totalCharges = orders.map(o => (o.deliveryFee + (o.doorToDoorFee ? o.doorToDoorFee : 0)) / 100).reduce((a, b) => a + b).toFixed(2);
      } else {
        totalCharges = (orders[0].deliveryFee + (orders[0].doorToDoorFee ? orders[0].doorToDoorFee : 0)) / 100;
      }
    }

    return totalCharges;
  }

  const getTotalCodAmount = (orders) => {
    let totalCharges = 0;

    if (orders && orders.length > 0) {
      if (orders.length > 1) {
        totalCharges = orders.map(o => o.codAmount / 100).reduce((a, b) => a + b).toFixed(2);
      } else {
        totalCharges = orders[0].codAmount / 100;
      }
    }

    return isNaN(totalCharges) ? "-" : "RM" + totalCharges;
  }

  const getTotalDriverFees = (orders) => {
    let totalCharges = 0;

    if (orders && orders.length > 0) {
      if (orders.length > 1) {
        totalCharges = orders.map(o => o.driverFee / 100).reduce((a, b) => a + b).toFixed(2);
      } else {
        totalCharges = orders[0].driverFee / 100;
      }
    }
    
    return totalCharges > 0 ? "RM" + totalCharges : "-";
  }

  return (
    <div className={`${deliveritNavState.open ? 'ml-60' : 'ml-12'}`}>
      <DeliveritNav />
      <div className={styles.container}>
        {
          state.orderGroup &&
          <Breadcrumbs separator="›" aria-label="breadcrumb" className={styles.breadcrumb}>
            {
              state.orderGroup.status === "OPEN" ?  (
                <Link color="inherit" href="/deliverit/order-groups">
                  Order Placements
                </Link>
              ) : state.orderGroup.status === "COMPLETED" ?  (
                <Link color="inherit" href="/deliverit/confirmed-order-groups">
                  Completed Orders
                </Link>
              ) : (
                <Link color="inherit" href="/deliverit/confirmed-order-groups">
                  Confirmed Orders
                </Link>
              )
            }
            <Typography color="textPrimary">View Orders</Typography>
          </Breadcrumbs>
        }
        <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
          <Toolbar>
            <Typography variant="h6" id="tableTitle" component="div">
              Order Group
            </Typography>
          </Toolbar>
          <Table size="small">
            <StickyTableHead className="!top-0">
              <TableRow>
                <TableCell align="left" className={styles.tableHeaderNarrow}>Order Group Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Pickup Date & Time</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Unit Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Pickup Address</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Pickup Job ID</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Vendor Email</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Vendor Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Vendor Company</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Vendor Product Category</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Delivery Method</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Total Delivery Charges With Door To Door</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Total COD Amount</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Payment Method</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Status</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Total Driver Fees</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Note To Driver</TableCell>
              </TableRow>
            </StickyTableHead>
            {
              state.orderGroup &&
              <TableBody>
                <TableRow>
                  <TableCell align="left" className={styles.tableCellNarrow}>{state.orderGroup.id}</TableCell>
                  <TableCell align="left">{state.orderGroup.pickUpDateTime}</TableCell>
                  <TableCell align="left">{state.orderGroup.pickUpLocation.unitNumber}</TableCell>
                  <TableCell align="left">{state.orderGroup.pickUpLocation.address}</TableCell>
                  <TableCell align="left">{state.orderGroup.pickUpJobId}</TableCell>
                  <TableCell align="left">{state.orderGroup.vendor?.user?.userName}</TableCell>
                  <TableCell align="left">{state.orderGroup.vendor?.user?.firstName + " " + state.orderGroup.vendor?.user?.lastName}</TableCell>
                  <TableCell align="left">{state.orderGroup.vendor?.user?.company}</TableCell>
                  <TableCell align="left">{state.orderGroup.vendor?.user?.product}</TableCell>
                  <TableCell align="left">{state.orderGroup.deliveryMethod}</TableCell>
                  <TableCell align="left">RM{getTotalDeliveryCharges(state.orderGroup.orders)}</TableCell>
                  <TableCell align="left">{getTotalCodAmount(state.orderGroup.orders)}</TableCell>
                  <TableCell align="left">{state.orderGroup.paymentMethod === "BANK_TRANSFER" ? "Bank Transfer" : "Credit Card"}</TableCell>
                  <TableCell align="left">{state.orderGroup.status}</TableCell>
                  <TableCell align="left">{getTotalDriverFees(state.orderGroup.orders)}</TableCell>
                  <TableCell align="left">{state.orderGroup.noteToDriver}</TableCell>
                </TableRow>
              </TableBody>
            }
          </Table>
        </TableContainer>
        {
          state.orderGroup &&
          <TableContainer component={Paper} className={styles.denseTable}>
            <TableToolbar 
              numSelected={state.selectedId === null ? 0 : 1}
              orderGroup={state.orderGroup}
              cancelOrder={() => dispatch(openCancelOrderModal())}
            />
            <Table size="small">
              <StickyTableHead className="!top-0">
                <TableRow>
                  {
                    state.orderGroup.status === "OPEN" ? (
                      <>
                      <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                      <TableCell align="left" className={styles.tableHeaderNarrow}>Order Number</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Delivery Date & Time</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Recipient Name</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Phone No.</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Email</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Unit Number</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Delivery Address</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Product</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Status</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Door To Door Charges</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Delivery Charges</TableCell>
                      </>
                    ) : (
                      <>
                      <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Order Number</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Delivery Date & Time</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Assigned Driver</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Recipient Name</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Phone No.</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Delivery Job ID</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Unit Number</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Delivery Address</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Tracking Link</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Door To Door Charges</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Delivery Charges</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>COD Amount</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Proof of delivery</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Product</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Status</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Completion Time</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Ratings</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Driver Fees</TableCell>
                      </>
                    )
                  }
                </TableRow>
              </StickyTableHead>
              <TableBody>
                {
                  state.orderGroup.orders.length > 0 ? (
                    state.orderGroup.orders.map((order) => state.orderGroup.status === "OPEN" ? (
                      <TableRow 
                        key={order.id}
                        hover
                        onClick={() => dispatch(setSelectedId(state.selectedId === order.id ? null : order.id))}
                        role="checkbox"
                        aria-checked={state.selectedId === order.id ? true : false}
                        selected={state.selectedId === order.id ? true : false}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox 
                            color="primary"
                            checked={state.selectedId === order.id ? true : false}
                          />
                        </TableCell>
                        <TableCell align="left" className={styles.tableCellNarrow}>{order.id}</TableCell>
                        <TableCell align="left">{order.deliverDateTime}</TableCell>
                        <TableCell align="left">{order.recipientName}</TableCell>
                        <TableCell align="left">{order.recipientPhone}</TableCell>
                        <TableCell align="left">{order.recipientEmail}</TableCell>
                        <TableCell align="left">{order.dropOffLocation.unitNumber}</TableCell>
                        <TableCell align="left">{order.dropOffLocation.address}</TableCell>
                        <TableCell align="left">{order.product}</TableCell>
                        <TableCell align="left">{order.status}</TableCell>
                        <TableCell align="left">RM{order.doorToDoorFee ? order.doorToDoorFee/100 : 0}</TableCell>
                        <TableCell align="left">RM{order.deliveryFee / 100}</TableCell>
                      </TableRow>
                    ) : (
                      <TableRow 
                        key={order.id}
                        hover
                        onClick={() => dispatch(setSelectedId(state.selectedId === order.id ? null : order.id))}
                        role="checkbox"
                        aria-checked={state.selectedId === order.id ? true : false}
                        selected={state.selectedId === order.id ? true : false}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox 
                            color="primary"
                            checked={state.selectedId === order.id ? true : false}
                          />
                        </TableCell>
                        <TableCell align="left" className={styles.tableCellNarrow}>{order.id}</TableCell>
                        <TableCell align="left">{order.deliverDateTime}</TableCell>
                        <TableCell align="left">{order.assignedDriver}</TableCell>
                        <TableCell align="left">{order.recipientName}</TableCell>
                        <TableCell align="left">{order.recipientPhone}</TableCell>
                        <TableCell align="left">{order.deliveryJobId}</TableCell>
                        <TableCell align="left">{order.dropOffLocation.unitNumber}</TableCell>
                        <TableCell align="left" style={{minWidth: 250}}>{order.dropOffLocation.address}</TableCell>
                        <TableCell align="left" onClick={() => window.open(order.trackingLink, '_blank')} style={{cursor:"pointer"}} className={styles.tableCellNarrow}>{order.trackingLink}</TableCell>
                        <TableCell align="left">RM{order.doorToDoorFee ? order.doorToDoorFee/100 : 0}</TableCell>
                        <TableCell align="left">RM{order.deliveryFee / 100}</TableCell>
                        <TableCell align="left">{order.codAmount ? `RM${order.codAmount / 100}` : "-"}</TableCell>
                        <TableCell align="left" onClick={() => window.open(order.proofOfDelivery, '_blank')} style={{cursor:"pointer"}} className={styles.tableCellNarrow}>{order.proofOfDelivery}</TableCell>
                        <TableCell align="left">{order.product}</TableCell>
                        <TableCell align="left">{order.status}</TableCell>
                        <TableCell align="left">{order.completionTime ? Moment(order.completionTime).format('YYYY-MM-DD HH:mm') : ""}</TableCell>
                        <TableCell align="left">{order.ratings}</TableCell>
                        <TableCell align="left">{order.driverFee ? "RM" + (order.driverFee / 100) : "-" }</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        <p>No Orders</p>
                      </TableCell>
                    </TableRow>
                  )
                }
                {
                  state.orderGroup.orders.length > 0 && state.orderGroup.status === "OPEN" &&
                  <TableRow>
                    <TableCell colSpan={7}></TableCell>
                    <TableCell colSpan={2}>Total Charges <p style={{fontSize: 12, margin: 0}}>(including tax)</p></TableCell>
                    <TableCell>RM{state.orderGroup.orders.map(order => order.deliveryFee).reduce((currentValue, value) => currentValue + value, 0) / 100}</TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        }
        {
          state.editOrderModalOpen  &&
          <EditOrder order={state.selectedId ? state.orderGroup.orders.find(order => order.id === state.selectedId) : null} orderGroup={state.orderGroup} />
        }
        {
          state.deleteOrderModalOpen  &&
          <ConfirmDelete order={state.selectedId ? state.orderGroup.orders.find(order => order.id === state.selectedId) : null} orderGroup={state.orderGroup} />
        }
        {
          state.cancelOrderModalOpen  &&
          <CancelOrder order={state.selectedId ? state.orderGroup.orders.find(order => order.id === state.selectedId) : null} orderGroup={state.orderGroup} />
        }
      </div>
    </div>
  );
}
