import axiosApiInstance from "../app/axios";
import { PARKIT_API_BASE_URL } from "../app/utils";
import { getJwtToken } from "../app/common";

export const getAllPayoutReports = async (page, size, sort, sortBy) =>
  axiosApiInstance.get(
    `${PARKIT_API_BASE_URL}/parkit/v2/payout-reports?page=${page}&size=${size}&sort=${sort}&sortBy=${sortBy}`,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const downloadPayoutReports = async (id) =>
  axiosApiInstance.get(
    `${PARKIT_API_BASE_URL}/parkit/v2/payout-reports/${id}/download`,
    {
      headers: { "Authorization": `Bearer ${getJwtToken()}` },
      responseType: 'blob'
    },
  );

export const getAllPayouts = async (page, size, sort, sortBy) =>
  axiosApiInstance.get(
    `${PARKIT_API_BASE_URL}/parkit/v2/payouts?page=${page}&size=${size}&sort=${sort}&sortBy=${sortBy}`,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const downloadPayoutReceipt = async (id) =>
  axiosApiInstance.get(
    `${PARKIT_API_BASE_URL}/parkit/v2/payouts/${id}/download`,
    {
      headers: { "Authorization": `Bearer ${getJwtToken()}` }, 
      responseType: 'blob'
    },
  );

export const uploadPayoutFile = async (formData) =>
  axiosApiInstance.post(`${PARKIT_API_BASE_URL}/parkit/v2/payouts/upload`, formData, {
    headers: { 
      Authorization: `Bearer ${getJwtToken()}`,
      'Content-Type': `multipart/form-data`,
    },
  });