import { Button, Toolbar, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useDispatch } from "react-redux";
import { setActionUrl, setActionMethod, showApproveListingModal, showRejectListingModal } from "./ownerListingsSlice";
import { ActionablesDto, ActionablesTitle } from "../../../app/constants";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: 2,
        paddingRight: 1,
    },
    title: {
        flex: '1 1 100%',
    },
    toolTipContainer: {
        position: "absolute",
        right: 10
    }
}));

export const TableToolbar = (props) => {
    const dispatch = useDispatch();
    const classes = useToolbarStyles();
    const { numSelected, title, actionables = [] } = props;

    const approveAction = actionables.filter((actionable) => actionable.dto === ActionablesDto.ApproveListingRequest && actionable.title === ActionablesTitle.Approve)[0];
    const rejectAction = actionables.filter((actionable) => actionable.dto === ActionablesDto.ApproveListingRequest && actionable.title === ActionablesTitle.Reject)[0];

    return (
        <Toolbar>
            {numSelected > 0 ? (
                <>
                    <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                        {numSelected} selected
                    </Typography>
                    {approveAction &&
                        <Button variant="text" size="small" onClick={() => { dispatch(showApproveListingModal(true)); dispatch(setActionUrl(approveAction.url)); dispatch(setActionMethod(approveAction.type)); }} sx={{ textTransform: "none" }}>
                            Approve
                        </Button>}
                    {rejectAction &&
                        <Button variant="text" size="small" onClick={() => { dispatch(showRejectListingModal(true)); dispatch(setActionUrl(rejectAction.url)); dispatch(setActionMethod(rejectAction.type)); }} sx={{ textTransform: "none" }}>
                            Reject
                        </Button>}
                </>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    {title}
                </Typography>
            )}
        </Toolbar>
    );
};