import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getAllVendors } from './vendorAPI';

export const vendorSlice = createSlice({
  name: 'vendor',
  initialState: {
    vendors: [],
    status: 'idle',
    vendorDeliveryRateModalOpen: false,
    editVendorModalOpen: false,
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null
  },
  reducers: {
    openVendorDeliveryRateModal: (state) => {
      state.vendorDeliveryRateModalOpen = true;
    },
    closeVendorDeliveryRateModal: (state) => {
      state.vendorDeliveryRateModalOpen = false;
    },
    openEditVendorModal: (state) => {
      state.editVendorModalOpen = true;
    },
    closeEditVendorModal: (state) => {
      state.editVendorModalOpen = false;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setVendors: (state, action) => {
      state.vendors = action.payload;
      state.status = 'success'
    },
  },
});

export const { openVendorDeliveryRateModal, closeVendorDeliveryRateModal, openEditVendorModal, closeEditVendorModal, setSelectedId, setPage, setRowsPerPage, setPagination, setVendors } = vendorSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.vendor.value)`
export const selectState = state => state.vendor;

export const handleGetVendors = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const response = await getAllVendors(state.vendor.page, state.vendor.rowsPerPage);
    const { content, ...pagination } = response.data;

    dispatch(setPagination(pagination));
    dispatch(setVendors(content));
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(hideLoading());
  }
};


export default vendorSlice.reducer;
