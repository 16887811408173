import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableRow, TableSortLabel, Paper, Checkbox, TablePagination, TableFooter, Fab, InputBase, IconButton, debounce } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Moment from 'moment';
import styles from './Renting.module.css';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';

import ParkitNav from '../../../components/ParkitNav/ParkitNav';
import AddRentingModal from './AddRentingModal';
import EditRentingModal from './EditRentingModal';
import AllocateRentingModal from './AllocateRentingModal';
import TerminateRentingModal from './TerminateRentingModal';
import CreateAppointmentModal from './CreateAppointmentModal';
import FilterRentingModal from './FilterRentingModal';
import TablePaginationActions from './TablePaginationActions';
import { TableToolbar } from './TableToolbar';
import { showFilterModal, handleGetRentings, selectState, setSearchValue, setSelectedId, setPage, setRowsPerPage, setSort, setSortBy, setErrorMessage, setSuccessMessage, setSelectedRenting, showAddModal } from './rentingSlice';
import { selectState as selectParkitNav } from '../../../components/ParkitNav/parkitNavSlice';
import useToasts from '../../../hooks/useToasts';
import { centToDollar } from '../../../app/utils';
import { StickyTableHead } from '../../../helpers/GeneralComponent';

export function Renting() {
  const state = useSelector(selectState);
  const parkitNavState = useSelector(selectParkitNav);
  const dispatch = useDispatch();
  const { toastError, toastSuccess } = useToasts();

  useEffect(() => {
    dispatch(handleGetRentings());
  }, [state.sort, state.sortBy, state.selectedFilter, dispatch]);

  useEffect(() => {
    if (state.successMessage !== "") {
      toastSuccess(state.successMessage);
      dispatch(setSuccessMessage(""));
    }
  }, [dispatch, toastSuccess, state.successMessage]);

  useEffect(() => {
    if (state.errorMessage !== "") {
      toastError(state.errorMessage);
      dispatch(setErrorMessage(""));
    }
  }, [dispatch, toastError, state.errorMessage]);

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetRentings());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetRentings());
  };

  const handleRequestSort = (property) => {
    const isDescending = state.sortBy === property && state.sort === 'desc';
    dispatch(setSort(isDescending ? 'asc' : 'desc'));
    dispatch(setSortBy(property));
  };

  const onSearchValueChange = (e) => {
    const cleanValue = e.target.value.replace(/\+/g, '');
    dispatch(setPage(0));
    dispatch(setSearchValue(cleanValue));
    dispatch(handleGetRentings());
  }

  const debouncedOnSearchValueChange = debounce(onSearchValueChange, 1000);

  return (
    <div className={`${parkitNavState.open ? 'ml-60' : 'ml-12'}`}>
      <ParkitNav />
      <div className="fixed bottom-6 right-6" onClick={() => dispatch(showAddModal(true))}>
        <Fab color="primary" aria-label="add">
          <AddIcon />
        </Fab>
      </div>
      <div className="p-10 text-left">
        <Paper
          component="form"
          sx={{ p: '2px 4px', mb: '10px', display: 'flex', alignItems: 'center' }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search..."
            inputProps={{ 'aria-label': 'search', 'className': 'focus:shadow-none focus:ring-0' }}
            onChange={debouncedOnSearchValueChange}
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => dispatch(showFilterModal(true))}>
            <FilterListIcon />
          </IconButton>
        </Paper>
        <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
          <div className="h-[64px] sticky left-0 top-0 z-[1] right-0 bg-white">
            <div className="h-[64px] absolute left-0 top-0 right-0">
              <TableToolbar
                numSelected={state.selectedId === null ? 0 : 1}
                title="Renting Requests (formerly known as Driver Enquiries)"
                actionables={state.rentings.filter(r => r.id === state.selectedId)[0]?.actionables}
              />
            </div>
          </div>
          <Table size="small">
            <StickyTableHead>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Driver Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Driver Email</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Driver HP</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Notification</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Requested Parking Area</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Total Available Bay</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Sell Price</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Nearby Type</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Nearby Address</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Start Date</TableCell>
                <TableCell align="left" className={styles.tableHeader}>
                  <TableSortLabel
                    active={state.sortBy === 'status'}
                    direction={state.sortBy === 'status' ? state.sort : 'desc'}
                    onClick={() => handleRequestSort('status')}
                  >
                    Driver Status
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={styles.tableHeader}>Vehicle</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Affiliate Campaign</TableCell>
                <TableCell align="left" className={styles.tableHeader110}>
                  <TableSortLabel
                    active={state.sortBy === 'createdAt'}
                    direction={state.sortBy === 'createdAt' ? state.sort : 'desc'}
                    onClick={() => handleRequestSort('createdAt')}
                  >
                    Created At
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={styles.tableHeader110}>Updated At</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Renting Id</TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {
                state.rentings.length > 0 ? (
                  state.rentings.map((renting) => (
                    <TableRow
                      key={renting.id}
                      hover
                      onClick={() => { dispatch(setSelectedId(state.selectedId === renting.id ? null : renting.id)); dispatch(setSelectedRenting(state.selectedId === renting.id ? null : renting)); }}
                      role="checkbox"
                      aria-checked={state.selectedId === renting.id ? true : false}
                      selected={state.selectedId === renting.id ? true : false}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={state.selectedId === renting.id ? true : false}
                        />
                      </TableCell>
                      <TableCell align="left" className={styles.tableCell}>{`${renting.customer.user.firstName} ${renting.customer.user.lastName}`}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.customer.user.userName}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.customer.user.contactNumber}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.isSuppressNotification ? "Disabled" : "Enabled"}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.requestedParkingArea?.name}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.requestedParkingArea?.additionalInformation?.totalVacant}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{centToDollar(renting.requestedParkingArea?.displayMonthlyPrice?.avg)}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.additionalInformation?.nearbyType}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.additionalInformation?.nearbyAddress}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.startDate}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.status}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.vehicle ? `${renting.vehicle.registrationNumber} (${renting.vehicle.color} ${renting.vehicle.model})` : "N/A"}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.affiliateCampaign?.name}</TableCell>
                      <TableCell align="left" className={styles.tableCell110}>{Moment(renting.createdAt).format('YYYY-MM-DD HH:mm')}</TableCell>
                      <TableCell align="left" className={styles.tableCell110}>{Moment(renting.updatedAt).format('YYYY-MM-DD HH:mm')}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.id}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={15} align="center">
                      <p>No driver enquiries.</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100, 500, 1000]}
                  colSpan={4}
                  count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                  rowsPerPage={state.rowsPerPage}
                  page={state.page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      {state.addModalOpen && <AddRentingModal />}
      {state.editModalOpen && (<EditRentingModal />)}
      {state.allocateModalOpen && (<AllocateRentingModal />)}
      {state.terminateModalOpen && <TerminateRentingModal />}
      {state.createAppointmentModalOpen && <CreateAppointmentModal />}
      {state.filterModalOpen && <FilterRentingModal />}
    </div>
  );
}