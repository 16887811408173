import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Modal,
  Paper,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import { Close } from "@mui/icons-material";
import moment from "moment";

import { handleAddAffiliateCampaign, showAddModal } from "./affiliateCampaignSlice";
import useToasts from "../../../hooks/useToasts";
import * as VoucherAPI from "../../voucher/voucherAPI";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const AddCampaignModal = () => {
  const dispatch = useDispatch();
  const { toastError } = useToasts();

  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [refereeCommission, setRefereeCommission] = useState("");
  const [refereeCommissionOn, setRefereeCommissionOn] = useState("");
  const [refereeCommissionType, setRefereeCommissionType] = useState("");
  const [referralPromoCode, setSelectedReferralPromoCode] = useState("");
  const [referableWindow, setReferableWindow] = useState("");
  const [specificParkingArea, setSpecificParkingArea] = useState(false);

  const [promoCodes, setPromoCodes] = useState([]);

  const validateFields = () => {
    if (!name || !startDate) {
      toastError("Please fill in all required fields.");
      return false;
    }
    return true;
  };

  const submit = () => {
    if (validateFields()) {
        try {
          let formattedStartDate = moment(startDate).format("YYYY-MM-DD");
          let formattedEndDate = moment(endDate).format("YYYY-MM-DD");

          const payload = {
            name,
            startDate: formattedStartDate,
            ...(endDate && { endDate: formattedEndDate }),
            ...(refereeCommission && { refereeCommission }),
            ...(refereeCommissionOn && { refereeCommissionOn }),
            ...(refereeCommissionType && { refereeCommissionType }),
            ...(referralPromoCode && { referralPromoCode }),
            ...(referableWindow && { referableWindow }),
            specificParkingArea,
          };
          dispatch(handleAddAffiliateCampaign(payload));
        } catch (error) {
          console.log(error.message);
        }
    }
  };

  const handleRefereeCommissionOnChange = (event) => {
    setRefereeCommissionOn(event.target.value);
  };

  const handleRefereeCommissionTypeChange = (event) => {
    setRefereeCommissionType(event.target.value);
  };

  const handleReferralPromoCodeeChange = (event) => {
    setSelectedReferralPromoCode(event.target.value);
  };

  useEffect(() => {
    const fetchPromoCodes = async () => {
        try {
          const res = await VoucherAPI.getVouchers(0, 100);
          setPromoCodes(res.content);
        } catch (err) {
          console.log(err);
        }
    };

    fetchPromoCodes();
  }, [])

  return (
    <Modal
        open={true}
        aria-labelledby="add-affiliate-campaign-modal"
        className="flex p-[10px] items-center justify-center"
    >
      <Paper className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showAddModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Add Affiliate Campaign</p>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <TextField
                id="name"
                label="Campaign Name"
                onChange={(e) => setName(e.target.value)}
                value={name}
                margin="normal"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                          label="Start Date"
                          value={startDate}
                          onChange={setStartDate}
                          componentsProps= {{
                              actionBar: {
                                  actions: ['clear', 'accept'],
                              },
                          }}
                          renderInput={(params) => <TextField {...params} required={!startDate} />}
                      />
                  </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                          label="End Date"
                          value={endDate}
                          onChange={setEndDate}
                          componentsProps= {{
                              actionBar: {
                                  actions: ['clear', 'accept'],
                              },
                          }}
                          minDate={startDate}
                          renderInput={(params) => <TextField {...params} />}
                      />
                  </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="refereeCommission"
                label="Commission % (Referee)"
                onChange={(e) => setRefereeCommission(e.target.value)}
                value={refereeCommission}
                margin="normal"
                variant="outlined"
                type="number"
                inputProps={{min: '0', max: '100', onWheel: (e) => e.target.blur()}}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                margin="normal"
                variant="outlined"
                fullWidth
              >
                <InputLabel id="user-group-label">Commission On (Referee)</InputLabel>
                <Select
                  labelId="refereeCommissionOn"
                  id="refereeCommissionOn"
                  value={refereeCommissionOn}
                  onChange={handleRefereeCommissionOnChange}
                >
                  <MenuItem value={'monthlySellPrice'}>Monthly Sell Price</MenuItem>
                  <MenuItem value={'insuranceSellPrice'}>Insurance Sell Price</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                margin="normal"
                variant="outlined"
                fullWidth
              >
                <InputLabel id="user-group-label">Commission Type (Referee)</InputLabel>
                <Select
                  labelId="refereeCommissionType"
                  id="refereeCommissionType"
                  value={refereeCommissionType}
                  onChange={handleRefereeCommissionTypeChange}
                >
                  <MenuItem value={'oneOff'}>One-off</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                margin="normal"
                variant="outlined"
                fullWidth
              >
                <InputLabel id="user-group-label">Promo code (Referral)</InputLabel>
                <Select
                  labelId="referralPromoCode"
                  id="referralPromoCode"
                  value={referralPromoCode}
                  onChange={handleReferralPromoCodeeChange}
                >
                {promoCodes.map((promo) => (
                  <MenuItem key={promo.id} value={promo.code}>
                    {`${promo.code} - ${promo.title}`}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="referableWindow"
                label="Referral Window (days)"
                onChange={(e) => setReferableWindow(e.target.value)}
                value={referableWindow}
                margin="normal"
                variant="outlined"
                type="number"
                inputProps={{min: '0', onWheel: (e) => e.target.blur()}}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                margin="normal"
                variant="outlined"
                fullWidth
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={specificParkingArea}
                      onChange={(e) => setSpecificParkingArea(e.target.checked)}
                      name="specificParkingArea"
                      color="primary"
                    />
                  }
                  label="Validation on Specific Parking Area"
                  sx={{ textAlign: 'center', ml: 1 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <button
                onClick={submit}
                className="bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md"
              >
                Confirm
              </button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default AddCampaignModal;