import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Paper, Grid, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";

import {
  handleUpdateRate,
  selectState,
  showEditModal,
} from "./deliveryRateSlice";

const EditRateModal = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();

  const rate = state.selectedRate;
  const [name, setName] = useState(rate.name);
  const [base, setBase] = useState(rate.base);
  const [min, setMin] = useState(rate.min);
  const [tier1, setTier1] = useState(rate.tier1);
  const [tier2, setTier2] = useState(rate.tier2);
  const [next, setNext] = useState(rate.next);

  const submit = async () => {
    try {
      const payload = {
        name,
        base,
        min,
        tier1,
        tier2,
        next,
      };

      dispatch(handleUpdateRate(state.selectedId, payload));
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Modal
      open={true}
      aria-labelledby="edit-rate-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showEditModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Edit Rate Details</p>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="name"
                label="Name"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setName(e.target.value)}
                value={name}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="base"
                label="Base"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setBase(e.target.value)}
                value={base}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="min"
                label="Min"
                onChange={(e) => setMin(e.target.value)}
                value={min}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="tier1"
                label="Tier 1"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setTier1(e.target.value)}
                value={tier1}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="tier2"
                label="Tier 2"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setTier2(e.target.value)}
                value={tier2}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="next"
                label="Next"
                onChange={(e) => setNext(e.target.value)}
                value={next}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <button
                onClick={submit}
                className="bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md"
              >
                Update
              </button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default EditRateModal;
