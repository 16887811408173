import axiosApiInstance from "../../../app/axios";
import { PARKIT_API_BASE_URL } from "../../../app/utils";
import { getJwtToken } from "../../../app/common";

export const getAffiliateCampaignReferrals = async (page, size, id, yearMonth, payoutStatus) => {
  let url = `${PARKIT_API_BASE_URL}/parkit/v2/affiliate-campaigns/${id}/referral-records?page=${page}&size=${size}&yearMonth=${yearMonth}`
  if (payoutStatus !== 'all') {
    url += `&payoutStatus=${payoutStatus}`;
  }
  return axiosApiInstance.get(url, { headers: { "Authorization": `Bearer ${getJwtToken()}` } });
}

export const updatePayoutStatus = async (affiliateId, referralIds) =>
  axiosApiInstance.put(
    `${PARKIT_API_BASE_URL}/parkit/v2/affiliate-campaigns/${affiliateId}/referral-records`,
    referralIds,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );
