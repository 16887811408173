import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getRentings, updateRenting } from '../../../api/rentingsAPI';
import { updateListing } from '../../../api/listingsAPI';
import { updateVehicle } from '../../../api/vehicleAPI';
import { performActionable } from '../../../api/dynamicAPI.js';
import { RentingStatus, SearchType } from '../../../app/constants';
import { createAppointment } from '../../../api/appointmentAPI';
import { addFilter } from '../../../app/utils.js';

export const trackingSheetSlice = createSlice({
  name: 'trackingSheet',
  initialState: {
    rentings: [],
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    sort: 'desc',
    sortBy: 'additionalInformation.bookingDate',
    selectedId: null,
    selectedRenting: null,
    searchValue: '',
    selectedFilter: {
      parkingAreaName: null,
      status: null,
      operationStatus: null
    },
    actionUrl: "",
    actionMethod: "",
    activateStartDate: null,
    editRentingModalOpen: false,
    editListingModalOpen: false,
    allocateModalOpen: false,
    deallocateModalOpen: false,
    activateModalOpen: false,
    terminateModalOpen: false,
    createAppointmentModalOpen: false,
    filterModalOpen: false,
    swapParkingModalOpen: false,
    successMessage: "",
    errorMessage: ""
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setRentings: (state, action) => {
      state.rentings = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setSelectedRenting: (state, action) => {
      state.selectedRenting = action.payload;
    },
    setActionUrl: (state, action) => {
      state.actionUrl = action.payload;
    },
    setActionMethod: (state, action) => {
      state.actionMethod = action.payload;
    },
    setActivateStartDate: (state, action) => {
      state.activateStartDate = action.payload;
    },
    setSelectedFilter: (state, action) => {
      state.selectedFilter = action.payload;
    },
    showEditRentingModal: (state, action) => {
      state.editRentingModalOpen = action.payload;
    },
    showEditListingModal: (state, action) => {
      state.editListingModalOpen = action.payload;
    },
    showAllocateModal: (state, action) => {
      state.allocateModalOpen = action.payload;
    },
    showDeallocateModal: (state, action) => {
      state.deallocateModalOpen = action.payload;
    },
    showActivateModal: (state, action) => {
      state.activateModalOpen = action.payload;
    },
    showTerminateModal: (state, action) => {
      state.terminateModalOpen = action.payload;
    },
    showCreateAppointmentModal: (state, action) => {
      state.createAppointmentModalOpen = action.payload;
    },
    showFilterModal: (state, action) => {
      state.filterModalOpen = action.payload;
    },
    showSwapParkingModal: (state, action) => {
      state.swapParkingModalOpen = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    }
  }
});

export const { showSwapParkingModal, setSelectedFilter, showFilterModal, setPage, setRowsPerPage, setPagination, setSort, setSortBy, setSearchValue, setRentings, setSelectedId, setSelectedRenting, setActionUrl, setActionMethod, setActivateStartDate, showEditRentingModal, showEditListingModal, showAllocateModal, showDeallocateModal, showActivateModal, showTerminateModal, showCreateAppointmentModal, setSuccessMessage, setErrorMessage } = trackingSheetSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.vendor.value)`
export const selectState = state => state.trackingSheet;

export const handleGetRentings = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    let filterParamsArray = [];
    const { selectedFilter } = state.trackingSheet;

    addFilter(filterParamsArray, 'requestedParkingArea.name', selectedFilter.parkingAreaName);
    addFilter(filterParamsArray, 'status', selectedFilter.status);
    addFilter(filterParamsArray, 'additionalInformation.operationStatus', selectedFilter.operationStatus);
    let filterParams = filterParamsArray.join(',');

    const response = await getRentings(state.trackingSheet.page, state.trackingSheet.rowsPerPage, state.trackingSheet.sort, state.trackingSheet.sortBy, `${RentingStatus.BOOKED},${RentingStatus.PRE_ALLOCATED},${RentingStatus.ALLOCATED},${RentingStatus.PAID},${RentingStatus.PRE_ACTIVE},${RentingStatus.INACTIVE}`, state.trackingSheet.searchValue, SearchType.ENQUIRY, filterParams);
    const { content, ...pagination } = response.data;

    dispatch(setPagination(pagination));
    dispatch(setRentings(content));
  } catch (error) {
    alert(error.message);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleUpdateRenting = (renting) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await updateRenting(state.trackingSheet.selectedId, renting);
    dispatch(showEditRentingModal(false));
    dispatch(setSuccessMessage("Successfully updated renting!"));
    dispatch(handleGetRentings());
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleUpdateVehicle = (id, payload) => async (dispatch) => {
  dispatch(showLoading());

  try {
    await updateVehicle(id, payload);
    dispatch(showEditRentingModal(false));
    dispatch(setSuccessMessage("Successfully updated vehicle!"));
    dispatch(handleGetRentings());
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage("Failed to update vehicle: " + error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleUpdateListing = (listing) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await updateListing(state.trackingSheet.selectedRenting.listing.id, listing);
    dispatch(showEditListingModal(false));
    dispatch(setSuccessMessage("Successfully updated tracking sheet's listing!"));
    dispatch(handleGetRentings());
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleAllocateRenting = (payload) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await performActionable(state.trackingSheet.actionUrl, state.trackingSheet.actionMethod, payload);
    dispatch(showAllocateModal(false));
    dispatch(setSuccessMessage("Successfully allocated renting!"));
    dispatch(handleGetRentings());
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleDeallocateRenting = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await performActionable(state.trackingSheet.actionUrl, state.trackingSheet.actionMethod, null);
    dispatch(showDeallocateModal(false));
    dispatch(setSuccessMessage("Successfully sent deallocate request!"));
    dispatch(handleGetRentings);
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
}

export const handleActivateRenting = (payload) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await performActionable(state.trackingSheet.actionUrl, state.trackingSheet.actionMethod, payload);
    dispatch(showActivateModal(false));
    dispatch(setSuccessMessage("Successfully activated renting!"));
    dispatch(handleGetRentings());
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleTerminateRenting = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await performActionable(state.trackingSheet.actionUrl, state.trackingSheet.actionMethod, null);
    dispatch(showTerminateModal(false));
    dispatch(setSuccessMessage("Successfully sent terminate request!"));
    dispatch(handleGetRentings);
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
}

export const handleCreateAppointment = (appointment) => async (dispatch, getState) => {
  dispatch(showLoading());

  try {
    await createAppointment(appointment);
    dispatch(showCreateAppointmentModal(false));
    dispatch(setSuccessMessage("Successfully create appointment!"));
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleSwapParking = (payload) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await performActionable(state.trackingSheet.actionUrl, state.trackingSheet.actionMethod, payload);
    dispatch(showSwapParkingModal(false));
    dispatch(setSuccessMessage("Successfully Swap Parking Bay!"));
    dispatch(handleGetRentings());
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export default trackingSheetSlice.reducer;