import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Checkbox, TablePagination, TableFooter, InputLabel, Button } from "@mui/material";
import { ConfirmPaymentStatus } from './ConfirmPaymentStatus';
import { TableToolbar } from './TableToolbar';
import { openEditOrderGroupPaymentStatusModal, handleGetOrderGroupsByFilter, handleDownloadOrderGroups, selectState, setSelectedIds, setPage, setRowsPerPage } from './orderGroupSlice';
import { selectState as selectDeliveritNav } from '../../../components/DeliveritNav/deliveritNavSlice';
import TablePaginationActions from './TablePaginationActions';
import styles from './OrderGroup.module.css';
import DeliveritNav from '../../../components/DeliveritNav/DeliveritNav';
import { StickyTableHead } from '../../../helpers/GeneralComponent';

export function OrderGroup(props) {
  const state = useSelector(selectState);
  const deliveritNavState = useSelector(selectDeliveritNav);
  const dispatch = useDispatch();
  
  const statusConstant = [
    'OPEN',
    'CONFIRMED',
    'ASSIGNED',
    'IN_TRANSIT',
    'SUCCESS',
    'PARTIAL_SUCCESS',
    'FAILED',
    'CANCELLED'
  ]

  const [filterState, _setFilterState] = useStateWithCallbackLazy({
    vendorName: undefined,
    vendorCompany: undefined,
    paids: "true,,false",
    status: props.orderGroupType === 'OPEN' ? ['OPEN'] : props.orderGroupType === 'COMPLETED' ? ['SUCCESS', 'PARTIAL_SUCCESS'] : ['CONFIRMED', 'ASSIGNED', 'IN_TRANSIT']
  });

  const filterStateRef = useRef(filterState);

  const setFilterState = data => {
    filterStateRef.current = data;
    _setFilterState(data);
  };
  
  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetOrderGroupsByFilter(filterState.vendorName, filterState.vendorCompany, filterState.paids, filterState.status));
    }
  });

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetOrderGroupsByFilter(filterState.vendorName, filterState.vendorCompany, filterState.paids, filterState.status));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    localStorage.setItem("rowsPerPage", parseInt(event.target.value, 10));
    dispatch(handleGetOrderGroupsByFilter(filterState.vendorName, filterState.vendorCompany, filterState.paids, filterState.status));
  };

  const getOrderUrl = (orderGroupId) => {
    let url = '';
    switch(props.orderGroupType) {
      case 'OPEN':
        url = '/deliverit/order-groups/' + orderGroupId
        break;
      case 'COMPLETED':
        url = '/deliverit/completed-order-groups/' + orderGroupId
        break;
      default:
        url = '/deliverit/confirmed-order-groups/' + orderGroupId
        break;
    }

    return url;
  }

  const getTotalDeliveryCharges = (orders) => {
    let totalCharges = 0;

    if (orders && orders.length > 0) {
      if (orders.length > 1) {
        totalCharges = orders.map(o => (o.deliveryFee + (o.doorToDoorFee ? o.doorToDoorFee : 0)) / 100).reduce((a, b) => a + b).toFixed(2);
      } else {
        totalCharges = (orders[0].deliveryFee +  + (orders[0].doorToDoorFee ? orders[0].doorToDoorFee : 0)) / 100;
      }
    }

    return totalCharges;
  }

  const getTotalCodAmount = (orders) => {
    let totalCharges = 0;

    if (orders && orders.length > 0) {
      if (orders.length > 1) {
        totalCharges = orders.map(o => o.codAmount / 100).reduce((a, b) => a + b).toFixed(2);
      } else {
        totalCharges = orders[0].codAmount / 100;
      }
    }

    return isNaN(totalCharges) ? "-" : "RM" + totalCharges;
  }

  const getTotalDriverFees = (orders) => {
    let totalCharges = 0;

    if (orders && orders.length > 0) {
      if (orders.length > 1) {
        totalCharges = orders.map(o => o.driverFee / 100).reduce((a, b) => a + b).toFixed(2);
      } else {
        totalCharges = orders[0].driverFee / 100;
      }
    }
    
    return totalCharges > 0 ? "RM" + totalCharges : "-";
  }

  const onFilterChange = (name, value) => {
    setFilterState({
      ...filterState, 
      [name]: value
    })
  }

  const onFilterStatusChange = (e) => {
    let name = e.target.name 
    let value =  e.target.checked
    let statusList = filterState.status
    if (value) {
      statusList.push(name)
    } else {
      statusList = statusList.filter(x => x !== name)
    }
    setFilterState({
      ...filterState, 
      status: statusList
    })
  }

  const handleFilter = () => {
    dispatch(handleGetOrderGroupsByFilter(filterState.vendorName, filterState.vendorCompany, filterState.paids, filterState.status));
  }

  return (
    <div className={`${deliveritNavState.open ? 'ml-60' : 'ml-12'}`}>
      <DeliveritNav />
      <div className={styles.container}>
        <InputLabel id="filter-label">Vendor First Name</InputLabel>
        <Paper className={styles.textInputContainer} style={{display: "flex", flexDirection: "row"}}>
          <input
            id="vendorName"
            name="vendorName"
            value={filterState.vendorName}
            onChange={e => onFilterChange("vendorName", e.target.value)}
            className={styles.textInput}
          />
        </Paper>

        <InputLabel id="filter-label">Vendor Company</InputLabel>
        <Paper className={styles.textInputContainer} style={{display: "flex", flexDirection: "row"}}>
          <input
            id="vendorCompany"
            name="vendorCompany"
            value={filterState.vendorCompany}
            onChange={e => onFilterChange("vendorCompany", e.target.value)}
            className={styles.textInput}
          />
        </Paper>

        <InputLabel id="filter-label">Paids</InputLabel>
        <Paper className={styles.textInputContainer} style={{display: "flex", flexDirection: "row"}}>
          <select 
            name="paids" 
            id="paids" 
            className={styles.selectInput} 
            onChange={e => onFilterChange("paids", e.target.value)}
            value={filterState.paids}>
            <option value="true">Paid</option>
            <option value="false,">Not Paid</option>
            <option value="true,,false">All</option>
          </select>
        </Paper>

        <InputLabel id="filter-label">Status</InputLabel>
        <Paper className={styles.textInputContainer} style={{display: "flex", flexDirection: "row"}}>
          {statusConstant.map( statusName =>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginRight: 10}}>
              <Checkbox 
                name={statusName}
                color="primary" 
                checked={filterState.status.includes(statusName) ? true : false} 
                onChange={e => onFilterStatusChange(e)} />
              <InputLabel className={styles.modal}>{statusName}</InputLabel>
            </div>
          )}
        </Paper>

        <Paper className={styles.textInputContainer} style={{display: "flex", flexDirection: "row"}}>
          <Button aria-label="filter" className={styles.submitButtonContainer} onClick={handleFilter}>Filter</Button>
        </Paper>

        <TableContainer component={Paper} className={styles.denseTable}>
          <TableToolbar 
            numSelected={state.selectedIds.length}
            confirmPaymentStatus={() => dispatch(openEditOrderGroupPaymentStatusModal())}
            downloadOrderGroups={() => dispatch(handleDownloadOrderGroups(props.orderGroupType))}
            orderGroupType={props.orderGroupType}
            orderGroups={state.selectedIds.length > 0 ? state.orderGroups.filter(orderGroup => state.selectedIds.includes(orderGroup.id)) : []}
          />
          <Table size="small">
            <StickyTableHead className="!top-0">
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                <TableCell align="left" className={styles.tableHeaderNarrow}>Order Group Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Payment Method</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Paid</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Pickup Date & Time</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Unit Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Pickup Address</TableCell>
                <TableCell align="left" className={styles.tableHeaderNarrow}>Pickup Job ID</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Vendor Email</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Vendor Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Vendor Company</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Vendor Product Category</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Order Count</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Delivery Method</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Total Delivery Charges with Door To Door</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Total COD Amount</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Status</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Total Driver Fees</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Halal</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Note To Driver</TableCell>
                <TableCell align="left" className={styles.tableHeader}></TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {
                state.orderGroups.length > 0 ? (
                  state.orderGroups.map((orderGroup) => (
                    <TableRow 
                      key={orderGroup.id}
                      hover
                      onClick={() => dispatch(setSelectedIds(state.selectedIds.includes(orderGroup.id) ? state.selectedIds.filter(id => id !== orderGroup.id) : [...state.selectedIds, orderGroup.id]))}
                      role="checkbox"
                      aria-checked={state.selectedIds.includes(orderGroup.id)}
                      selected={state.selectedIds.includes(orderGroup.id)}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox 
                          color="primary"
                          checked={state.selectedIds.includes(orderGroup.id)}
                        />
                      </TableCell>
                      <TableCell align="left" className={styles.tableCellNarrow}>{orderGroup.id}</TableCell>
                      <TableCell align="left">{orderGroup.paymentMethod === "BANK_TRANSFER" ? "Bank Transfer" : "Credit Card"}</TableCell>
                      <TableCell align="left">{orderGroup.paid ? "Paid" : "Unpaid"}</TableCell>
                      <TableCell align="left">{orderGroup.pickUpDateTime}</TableCell>
                      <TableCell align="left">{orderGroup.pickUpLocation.unitNumber}</TableCell>
                      <TableCell align="left">{orderGroup.pickUpLocation.address}</TableCell>
                      <TableCell align="left" className={styles.tableCellNarrow}>{orderGroup.pickUpJobId}</TableCell>
                      <TableCell align="left" className={styles.tableCellNarrow}>{orderGroup.vendor?.user?.userName}</TableCell>
                      <TableCell align="left">{orderGroup.vendor?.user?.firstName + " " + orderGroup.vendor?.user?.lastName}</TableCell>
                      <TableCell align="left">{orderGroup.vendor?.user?.company}</TableCell>
                      <TableCell align="left">{orderGroup.vendor?.user?.product}</TableCell>
                      <TableCell align="left">{orderGroup.orders ? orderGroup.orders.length : 0}</TableCell>
                      <TableCell align="left">{orderGroup.deliveryMethod}</TableCell>
                      <TableCell align="left">RM{getTotalDeliveryCharges(orderGroup.orders)}</TableCell>
                      <TableCell align="left">{getTotalCodAmount(orderGroup.orders)}</TableCell>
                      <TableCell align="left">{orderGroup.status}</TableCell>
                      <TableCell align="left">{getTotalDriverFees(orderGroup.orders)}</TableCell>
                      <TableCell align="left">{orderGroup.halal === true ? "Yes" : "No"}</TableCell>
                      <TableCell align="left">{orderGroup.noteToDriver}</TableCell>
                      <TableCell align="left">
                        <span className={styles.tableLink} onClick={() => window.open(getOrderUrl(orderGroup.id), '_blank')}>
                          View Orders
                        </span>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={16} align="center">
                      <p>No Order Groups</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={9}
                  count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                  rowsPerPage={state.rowsPerPage}
                  page={state.page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        {
          state.editOrderGroupPaymentStatusModalOpen  &&
          <ConfirmPaymentStatus orderGroupType={props.orderGroupType} orderGroups={state.selectedIds.length > 0 ? state.orderGroups.filter(orderGroup => state.selectedIds.includes(orderGroup.id)) : []} />
        }
      </div>
    </div>
  );
}
