import axiosApiInstance from "../../../app/axios";
import { API_BASE_URL } from "../../../app/utils";
import { getJwtToken } from "../../../app/common";

export const getOrderGroups = async (page, size, status) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/deliverit/v1/order-groups?page=${page}&size=${size}&status=${status}`,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const getOrderGroupsByUsers = async (page, size, filterQuery) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/deliverit/v1/order-groups?page=${page}&size=${size}${filterQuery}`,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const getUnpaidOrderGroups = async (page, size, status) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/deliverit/v1/order-groups?page=${page}&size=${size}&status=${status}&paid=false`,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const getUserOrderGroupsBetweenDates = async (params) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/deliverit/v1/order-groups?${params}`,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const getReportByOrderGroupStatus = async (status) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/deliverit/v1/reportings?status=${status}`,
    {
      responseType: 'blob',
      headers: {
        "Authorization": `Bearer ${getJwtToken()}`,
        "Content-Type": 'text/csv'
      }
    }
  );

export const updateOrderGroup = async (data, id) =>
  axiosApiInstance.put(
    `${API_BASE_URL}/deliverit/v1/order-groups/${id}`,
    data,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const updateOrderGroupPaymentStatus = async (id, paid, paymentStatus) =>
  axiosApiInstance.post(
    `${API_BASE_URL}/deliverit/v1/order-groups/${id}/update-payment-status?paid=${paid}&paymentStatus=${paymentStatus}`,
    {},
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const deleteOrderGroup = async (id) =>
  axiosApiInstance.delete(
    `${API_BASE_URL}/deliverit/v1/order-groups/${id}`,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const getOrderGroupById = async (id) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/deliverit/v1/order-groups/${id}`,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );