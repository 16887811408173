import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import Moment from 'moment';
import { getMonthlyReport } from './dashboardAPI';
import { getOrderGroups, getUnpaidOrderGroups, getUserOrderGroupsBetweenDates } from '../order_group/orderGroupAPI';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    email: "",
    password: "",
    error: false,
    errorMessage: "",
    totalCompletedOrderGroups: 0,
    totalConfirmedOrderGroups: 0,
    totalUnpaidOrderGroups: 0,
    orderPerformanceType: 'daily',
    orderPerformance: [],
    timeliness: {
      ontime: { percentage: 0, count: 0 },
      delay15: { percentage: 0, count: 0 },
      delay30: { percentage: 0, count: 0 },
      delay30More: { percentage: 0, count: 0 } 
    },
    todayInProgressOrders: [],
    todayCompletedOrders: [],
    status: 'idle',
  },
  reducers: {
    onValueChange: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
    setTotalCompletedOrderGroups: (state, action) => {
      state.totalCompletedOrderGroups = action.payload;
      state.status = 'success'
    },
    setTotalConfirmedOrderGroups: (state, action) => {
      state.totalConfirmedOrderGroups = action.payload;
      state.status = 'success'
    },
    setTotalUnpaidOrderGroups: (state, action) => {
      state.totalUnpaidOrderGroups = action.payload;
      state.status = 'success'
    },
    setOrderPerformanceType: (state, action) => {
      state.orderPerformanceType = action.payload;
      state.status = 'success'
    },
    setOrderPerformance: (state, action) => {
      state.orderPerformance = action.payload;
      state.status = 'success'
    },
    setTimeliness: (state, action) => {
      state.timeliness = action.payload;
      state.status = 'success'
    },
    setTodayInProgressOrders: (state, action) => {
      state.todayInProgressOrders = action.payload;
      state.status = 'success'
    },
    setTodayCompletedOrders: (state, action) => {
      state.todayCompletedOrders = action.payload;
      state.status = 'success'
    },
  },
});

export const { onValueChange, setTotalCompletedOrderGroups, setTotalConfirmedOrderGroups, setTotalUnpaidOrderGroups, setOrderPerformance, setTimeliness, setTodayInProgressOrders, setTodayCompletedOrders, setOrderPerformanceType } = dashboardSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.dashboard.value)`
export const selectState = state => state.deliverItDashboard;

export const handleGetMonthlyReport = (year, month) => async (dispatch, getState) => {
  dispatch(showLoading());

  try {
    const response = await getMonthlyReport(year, month);
    
    let report = await response.data;
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([report]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${month}-${year}-monthly-report.csv`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(hideLoading());
  }
};


export const handleGetOrderGroups = (orderGroupType) => async (dispatch, getState) => {
  dispatch(showLoading());

  let status = orderGroupType === 'COMPLETED' ? 'SUCCESS,PARTIAL_SUCCESS,FAILED,CANCELLED' : 'CONFIRMED,IN_TRANSIT,ASSIGNED'
  try {
    const response = await getOrderGroups(0, 1, status);
    if (orderGroupType === 'COMPLETED') {
      dispatch(setTotalCompletedOrderGroups(response.data.totalElements));
    } else {
      dispatch(setTotalConfirmedOrderGroups(response.data.totalElements));
    }
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleGetUnpaidOrderGroups = () => async (dispatch, getState) => {
  dispatch(showLoading());

  let status = 'SUCCESS,PARTIAL_SUCCESS,CONFIRMED,IN_TRANSIT,ASSIGNED';
  try {
    const response = await getUnpaidOrderGroups(0, 1, status);
   
    dispatch(setTotalUnpaidOrderGroups(response.data.totalElements));
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleGetOrderPerformance = (orderPerformanceType) => async (dispatch, getState) => {
  dispatch(showLoading());

  let numOfDays = 180
  let status = 'SUCCESS,PARTIAL_SUCCESS,FAILED'

  let endDate = Moment().endOf('day').format('YYYY-MM-DD')
  let startDate = Moment().subtract(numOfDays, "days").startOf('day').format('YYYY-MM-DD')
  try {
    let params = `startDate=${startDate}&endDate=${endDate}&status=${status}`
    const response = await getUserOrderGroupsBetweenDates(params);
    let res = [...response.data];
    
    // add empty object at the first of array so we can ignore first object in reduce() and having initial aggregate object 
    res.unshift({statsPerDay: {}, onTimeCount: 0, delay15Count: 0, delay30Count: 0, delay30MoreCount: 0, totalCount: 0})
    // extract order count per day & timeliness count
    let stats = res.reduce((result, x) => {
      x.orders.forEach(element => {
        let orderDate
        if (orderPerformanceType === 'daily') {
          orderDate = Moment(element.deliverDateTime).format('DDMMMYY')
        } else {
          let orderStartDate = Moment(element.deliverDateTime).startOf('week').format('DDMMMYY')
          let orderEndDate = Moment(element.deliverDateTime).endOf('week').format('DDMMMYY')
          orderDate = orderStartDate + "-" + orderEndDate
        }
        
        if (result.statsPerDay[orderDate] === undefined) {
          result.statsPerDay[orderDate] = {successOrders: 0, failOrders: 0}
        }
        if (element.status === 'SUCCESS') {
          result.statsPerDay[orderDate].successOrders++
        } else if (element.status === 'FAILED') {
          result.statsPerDay[orderDate].failOrders++
        }

        let msDiff = new Date(element.completionTime).getTime() - new Date(element.deliverDateTime).getTime()
        if (isNaN(msDiff) || msDiff <= 0) {
          result.onTimeCount++
        } else if (msDiff <= 900000) { // 15 min
          result.delay15Count++
        } else if (msDiff <= 1800000) { // 30 min
          result.delay15Count++
        } else {
          result.delay30MoreCount++
        }
        result.totalCount++
      });
      return result;
    })
    // transform object to array
    let orderPerformance = Object.keys(stats.statsPerDay).map(k => {
      return {orderDate: k, successOrders: stats.statsPerDay[k].successOrders, failOrders: stats.statsPerDay[k].failOrders}
    })
    // build timeliness data
    let timeliness = {
      ontime: {
        percentage: Math.round(stats.onTimeCount/stats.totalCount*100),
        count: stats.onTimeCount
      },
      delay15: {
        percentage: Math.round(stats.delay15Count/stats.totalCount*100),
        count: stats.delay15Count
      },
      delay30: {
        percentage: Math.round(stats.delay30Count/stats.totalCount*100),
        count: stats.delay30Count
      },
      delay30More: {
        percentage: Math.round(stats.delay30MoreCount/stats.totalCount*100),
        count: stats.delay30MoreCount
      }
    }
    dispatch(setOrderPerformanceType(orderPerformanceType))
    dispatch(setOrderPerformance(orderPerformance))
    dispatch(setTimeliness(timeliness))
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(hideLoading());
  }
};

const getSuccessStatusStyle = (status, completionTime, expectedTime) => {
  let statusStyle = {backgroundColor:"green",color:"#fff"}
  if ((status === 'SUCCESS' || status === 'PARTIAL_SUCCESS') && completionTime !== undefined) {
    let msDiff = new Date(completionTime).getTime() - new Date(expectedTime).getTime()
    if (isNaN(msDiff) || msDiff <= 0) {
      statusStyle = {backgroundColor:"green",color:"#fff"}
    } else if (msDiff <= 900000) { // 15 min
      statusStyle = {backgroundColor:"yellow",color:"#000"}
    } else if (msDiff <= 1800000) { // 30 min
      statusStyle = {backgroundColor:"orange",color:"#fff"}
    } else {
      statusStyle = {backgroundColor:"red",color:"#fff"}
    }
  }
  return statusStyle
}

export const handleGetOrderByDate = (selectedDate) => async (dispatch, getState) => {
  dispatch(showLoading());

  var startDate = Moment(selectedDate).startOf('day').format('YYYY-MM-DD') // set to 12:00 am today
  var endDate = Moment(selectedDate).endOf('day').format('YYYY-MM-DD') // set to 23:59 pm today
  try {
    let params = `startDate=${startDate}&endDate=${endDate}`
    const response = await getUserOrderGroupsBetweenDates(params);
    
    let todayInProgressOrders = []
    let todayCompletedOrders = []
    response.data.forEach(ordergroup => {
      ordergroup.orders.forEach(order => {
        let x = {
          pickupId: ordergroup.id,
          pickupRecipient: ordergroup.pickUpPersonName,
          pickupStatus: ordergroup.hadPickedUp ? 'SUCCESS' : ordergroup.status,
          pickupTrackingLink: ordergroup.trackingLink,
          pickupSuccessStatusStyle: getSuccessStatusStyle(ordergroup.status, ordergroup.completionTime, ordergroup.pickUpDateTime),
          dropoffRecipient: order.recipientName,
          dropoffStatus: order.status,
          dropoffTrackingLink: order.trackingLink,
          dropoffSuccessStatusStyle: getSuccessStatusStyle(order.status, order.completionTime, order.deliverDateTime)
        }
        if (['OPEN','PROCESSING','ASSIGNED','IN_TRANSIT'].includes(order.status)) {
          todayInProgressOrders.push(x)
        } else {
          todayCompletedOrders.push(x)
        }
      })
    })
    dispatch(setTodayInProgressOrders(todayInProgressOrders))
    dispatch(setTodayCompletedOrders(todayCompletedOrders))
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};

export default dashboardSlice.reducer;
