import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableRow, TableSortLabel, Button, Paper, Checkbox, TablePagination, TableFooter, InputBase, IconButton, debounce } from "@mui/material";
import moment from 'moment';
import styles from './Termination.module.css';
import { StickyTableHead } from '../../../helpers/GeneralComponent';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';

import ParkitNav from '../../../components/ParkitNav/ParkitNav';
import ApproveListingTerminationModal from './ApproveListingTerminationModal';
import RejectListingTerminationModal from './RejectListingTerminationModal';
import ApproveRentingTerminationModal from './ApproveRentingTerminationModal';
import RejectRentingTerminationModal from './RejectRentingTerminationModal';
import ViewAppointmentModal from './ViewAppointmentModal';
import { ListingTableToolbar } from './ListingTableToolbar';
import { RentingTableToolbar } from './RentingTableToolbar';
import TablePaginationActions from './TablePaginationActions';
import { showFilterListingModal, showFilterRentingModal, setAppointmentId, showViewAppointmentModal, setListingSearchValue, setRentingSearchValue, handleGetListings, handleGetRentings, selectState, setErrorMessage, setListingPage, setListingRowsPerPage, setListingSort, setListingSortBy, setRentingSort, setRentingSortBy, setRentingPage, setRentingRowsPerPage, setSelectedListingId, setSelectedRentingId, setSuccessMessage } from './terminationSlice';
import { selectState as selectParkitNav } from '../../../components/ParkitNav/parkitNavSlice';
import useToasts from '../../../hooks/useToasts';
import { centToDollar } from '../../../app/utils';
import FilterRentingModal from './FilterRentingModal';
import FilterListingModal from './FilterListingModal';

export function Termination() {
  const state = useSelector(selectState);
  const parkitNavState = useSelector(selectParkitNav);
  const dispatch = useDispatch();
  const { toastError, toastSuccess } = useToasts();

  useEffect(() => {
    dispatch(handleGetListings());
  }, [state.listingSort, state.listingSortBy, state.selectedListingFilter, dispatch]);

  useEffect(() => {
    dispatch(handleGetRentings());
  }, [state.rentingSort, state.rentingSortBy, state.selectedRentingFilter, dispatch]);

  useEffect(() => {
    if (state.successMessage !== "") {
      toastSuccess(state.successMessage);
      dispatch(setSuccessMessage(""));
    }
  }, [dispatch, toastSuccess, state.successMessage]);

  useEffect(() => {
    if (state.errorMessage !== "") {
      toastError(state.errorMessage);
      dispatch(setErrorMessage(""));
    }
  }, [dispatch, toastError, state.errorMessage]);

  const handleChangeListingPage = (event, newPage) => {
    dispatch(setListingPage(newPage));
    dispatch(handleGetListings());
  };

  const handleChangeListingRowsPerPage = (event) => {
    dispatch(setListingRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setListingPage(0));
    dispatch(handleGetListings());
  };

  const handleChangeRentingPage = (event, newPage) => {
    dispatch(setRentingPage(newPage));
    dispatch(handleGetRentings());
  };

  const handleChangeRentingRowsPerPage = (event) => {
    dispatch(setRentingRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setRentingPage(0));
    dispatch(handleGetRentings());
  };

  const handleListingRequestSort = (property) => {
    const isDescending = state.listingSortBy === property && state.listingSort === 'desc';
    dispatch(setListingSort(isDescending ? 'asc' : 'desc'));
    dispatch(setListingSortBy(property));
  };

  const handleRentingRequestSort = (property) => {
    const isDescending = state.rentingSortBy === property && state.rentingSort === 'desc';
    dispatch(setRentingSort(isDescending ? 'asc' : 'desc'));
    dispatch(setRentingSortBy(property));
  };

  const onListingSearchValueChange = (e) => {
    const cleanValue = e.target.value.replace(/\+/g, '');
    dispatch(setListingPage(0));
    dispatch(setListingSearchValue(cleanValue));
    dispatch(handleGetListings());
  }

  const debouncedOnListingSearchValueChange = debounce(onListingSearchValueChange, 1000);

  const onRentingSearchValueChange = (e) => {
    const cleanValue = e.target.value.replace(/\+/g, '');
    dispatch(setRentingPage(0));
    dispatch(setRentingSearchValue(cleanValue));
    dispatch(handleGetRentings());
  }

  const debouncedOnRentingSearchValueChange = debounce(onRentingSearchValueChange, 1000);

  return (
    <div className={`${parkitNavState.open ? 'ml-60' : 'ml-12'}`}>
      <ParkitNav />
      <div className="p-10 text-left">
        <Paper
          component="form"
          sx={{ p: '2px 4px', mb: '10px', display: 'flex', alignItems: 'center' }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search..."
            inputProps={{ 'aria-label': 'search', 'className': 'focus:shadow-none focus:ring-0' }}
            onChange={debouncedOnListingSearchValueChange}
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => dispatch(showFilterListingModal(true))}>
            <FilterListIcon />
          </IconButton>
        </Paper>
        <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
          <div className="h-[64px] sticky left-0 top-0 z-[1] right-0 bg-white">
            <div className="h-[64px] absolute left-0 top-0 right-0">
              <ListingTableToolbar
                numSelected={state.selectedListingId === null ? 0 : 1}
                title="Listing Terminations"
                actionables={state.listings.filter(r => r.id === state.selectedListingId)[0]?.actionables}
              />
            </div>
          </div>
          <Table size="small">
            <StickyTableHead>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Owner Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Owner Email</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Owner HP</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Bay Number</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Parking Area</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Driver Status</TableCell>
                <TableCell align="left" className={styles.tableHeader}>
                  <TableSortLabel
                    active={state.listingSortBy === 'termination.customerDueDate'}
                    direction={state.listingSortBy === 'termination.customerDueDate' ? state.listingSort : 'desc'}
                    onClick={() => handleListingRequestSort('termination.customerDueDate')}
                  >
                    Parking Needed Date
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={styles.tableHeader}>Termination Reason</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Feedback</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Comments</TableCell>
                <TableCell align="left" className={styles.tableHeader}>
                  <TableSortLabel
                    active={state.listingSortBy === 'termination.cardCollectionTime'}
                    direction={state.listingSortBy === 'termination.cardCollectionTime' ? state.listingSort : 'desc'}
                    onClick={() => handleListingRequestSort('termination.cardCollectionTime')}
                  >
                    Card Collection Time
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={styles.tableHeader}>Drop Off Status</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Termination Requested At</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Buy Price</TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {
                state.listings.length > 0 ? (
                  state.listings.map((listing) => (
                    <TableRow
                      key={listing.id}
                      hover
                      onClick={() => dispatch(setSelectedListingId(state.selectedListingId === listing.id ? null : listing.id))}
                      role="checkbox"
                      aria-checked={state.selectedListingId === listing.id ? true : false}
                      selected={state.selectedListingId === listing.id ? true : false}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={state.selectedListingId === listing.id ? true : false}
                        />
                      </TableCell>
                      <TableCell align="left" className={styles.tableCell}>{`${listing.customer.user.firstName} ${listing.customer.user.lastName}`}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.customer.user.userName}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.customer.user.contactNumber}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.parkingBay?.bayNumber}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.parkingArea?.name}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.renting?.status}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.termination?.customerDueDate}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.termination?.reason}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.termination?.feedback}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.termination?.comments}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.termination?.cardCollectionTime ? moment(listing.termination?.cardCollectionTime, "HH:mm:ss").format("h:mm A") : ""}</TableCell>
                      <TableCell align="left" className={styles.tableCell}><Button variant="text" size="small" onClick={() => { dispatch(setAppointmentId(listing.termination?.appointmentId)); dispatch(showViewAppointmentModal(true))}} sx={{ textTransform: "none" }} disabled={state.selectedListingId === listing.id}>View</Button></TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.termination?.date}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{centToDollar(listing.buyPrices?.monthly)}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={20} align="center">
                      <p>No listing termination requests.</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={6}
                  count={state.listingPagination?.totalElements ?? 0}
                  rowsPerPage={state.listingRowsPerPage}
                  page={state.listingPage}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangeListingPage}
                  onRowsPerPageChange={handleChangeListingRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      <div className="p-10 text-left">
        <Paper
          component="form"
          sx={{ p: '2px 4px', mb: '10px', display: 'flex', alignItems: 'center' }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search..."
            inputProps={{ 'aria-label': 'search', 'className': 'focus:shadow-none focus:ring-0' }}
            onChange={debouncedOnRentingSearchValueChange}
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => dispatch(showFilterRentingModal(true))}>
            <FilterListIcon />
          </IconButton>
        </Paper>
        <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
          <div className="h-[64px] sticky left-0 top-0 z-[1] right-0 bg-white">
            <div className="h-[64px] absolute left-0 top-0 right-0">
              <RentingTableToolbar
                numSelected={state.selectedRentingId === null ? 0 : 1}
                title="Renting Terminations"
                actionables={state.rentings.filter(r => r.id === state.selectedRentingId)[0]?.actionables}
              />
            </div>
          </div>
          <Table size="small">
            <StickyTableHead>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Driver Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Driver Email</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Driver HP</TableCell>
                <TableCell align="left" className={styles.tableHeader250}>Parking Bay</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Driver Status</TableCell>
                <TableCell align="left" className={styles.tableHeader}>
                  <TableSortLabel
                    active={state.rentingSortBy === 'termination.customerDueDate'}
                    direction={state.rentingSortBy === 'termination.customerDueDate' ? state.rentingSort : 'desc'}
                    onClick={() => handleRentingRequestSort('termination.customerDueDate')}
                  >
                    Driver Termination Date
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={styles.tableHeader}>Termination Reason</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Feedback</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Card Collection Option</TableCell>
                <TableCell align="left" className={styles.tableHeader}>
                  <TableSortLabel
                    active={state.rentingSortBy === 'termination.cardCollectionDate'}
                    direction={state.rentingSortBy === 'termination.cardCollectionDate' ? state.rentingSort : 'desc'}
                    onClick={() => handleRentingRequestSort('termination.cardCollectionDate')}
                  >
                    Card Collection Date
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={styles.tableHeader}>
                  <TableSortLabel
                    active={state.rentingSortBy === 'termination.cardCollectionTime'}
                    direction={state.rentingSortBy === 'termination.cardCollectionTime' ? state.rentingSort : 'desc'}
                    onClick={() => handleRentingRequestSort('termination.cardCollectionTime')}
                  >
                    Card Collection Time
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={styles.tableHeader}>Drop Off Status</TableCell>
                <TableCell align="left" className={styles.tableHeader}>
                  <TableSortLabel
                    active={state.rentingSortBy === 'termination.date'}
                    direction={state.rentingSortBy === 'termination.date' ? state.rentingSort : 'desc'}
                    onClick={() => handleRentingRequestSort('termination.date')}
                  >
                    Termination Requested At
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={styles.tableHeader}>Owner Status</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Parking Type</TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {
                state.rentings.length > 0 ? (
                  state.rentings.map((renting) => (
                    <TableRow
                      key={renting.id}
                      hover
                      onClick={() => dispatch(setSelectedRentingId(state.selectedRentingId === renting.id ? null : renting.id))}
                      role="checkbox"
                      aria-checked={state.selectedRentingId === renting.id ? true : false}
                      selected={state.selectedRentingId === renting.id ? true : false}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={state.selectedRentingId === renting.id ? true : false}
                        />
                      </TableCell>
                      <TableCell align="left" className={styles.tableCell}>{`${renting.customer.user.firstName} ${renting.customer.user.lastName}`}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.customer.user.userName}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.customer.user.contactNumber}</TableCell>
                      <TableCell align="left" className={styles.tableCell250}>{renting.listing ? `${renting.listing?.parkingBay.bayNumber} - ${renting.listing?.parkingBay.address}` : renting.listingId}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.status}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.termination?.customerDueDate}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.termination?.reason}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.termination?.feedback}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.termination?.comments}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.termination?.cardCollectionDate}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.termination?.cardCollectionTime ? moment(renting.termination?.cardCollectionTime, "HH:mm:ss").format("h:mm A") : ""}</TableCell>
                      <TableCell align="left" className={styles.tableCell}><Button variant="text" size="small" onClick={() => { dispatch(setAppointmentId(renting.termination?.appointmentId)); dispatch(showViewAppointmentModal(true))}} sx={{ textTransform: "none" }} disabled={state.selectedRentingId === renting.id}>View</Button></TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.termination?.date}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.listing?.status}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.listing?.parkingBay?.type}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={15} align="center">
                      <p>No renting termination requests.</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={6}
                  count={state.rentingPagination?.totalElements ?? 0}
                  rowsPerPage={state.rentingRowsPerPage}
                  page={state.rentingPage}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangeRentingPage}
                  onRowsPerPageChange={handleChangeRentingRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      {state.approveListingTerminationModalOpen && <ApproveListingTerminationModal />}
      {state.rejectListingTerminationModalOpen && <RejectListingTerminationModal />}
      {state.approveRentingTerminationModalOpen && <ApproveRentingTerminationModal />}
      {state.rejectRentingTerminationModalOpen && <RejectRentingTerminationModal />}
      {state.viewAppointmentModalOpen && <ViewAppointmentModal />}
      {state.filterRentingModalOpen && <FilterRentingModal />}
      {state.filterListingModalOpen && <FilterListingModal />}
    </div>
  );
}