import axiosApiInstance from "../../../app/axios";
import { API_BASE_URL } from "../../../app/utils";
import { getJwtToken } from "../../../app/common";

export const getOrdersByOrderGroup = async (id) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/deliverit/v1/order-groups/${id}`,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const updateOrder = async (orderGroupId, data, id) =>
  axiosApiInstance.put(
    `${API_BASE_URL}/deliverit/v1/order-groups/${orderGroupId}/orders/${id}`,
    data,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const deleteOrder = async (orderGroupId, id) =>
  axiosApiInstance.delete(
    `${API_BASE_URL}/deliverit/v1/order-groups/${orderGroupId}/orders/${id}`,
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );

export const cancelOrder = async (orderGroupId, id, adjustmentFees) =>
  axiosApiInstance.post(
    `${API_BASE_URL}/deliverit/v1/order-groups/${orderGroupId}/orders/${id}/cancel?adjustmentFees=${adjustmentFees}`,
    {},
    { headers: { "Authorization": `Bearer ${await getJwtToken()}` } }
  );