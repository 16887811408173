import axiosApiInstance from "../app/axios";
import { PARKIT_API_BASE_URL } from "../app/utils";
import { getJwtToken } from "../app/common";

export const performActionable = async (url, method, data) => {
  const config = {
    headers: { "Authorization": `Bearer ${getJwtToken()}` }
  };
  const endpoint = `${PARKIT_API_BASE_URL}/parkit/v2${url}`;
  const axiosMethod = axiosApiInstance[method === 'TASK' ? 'put' : method.toLowerCase()].bind(axiosApiInstance);
  return data ? axiosMethod(endpoint, data, config) : axiosMethod(endpoint, {}, config);
}