import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Paper, Grid, TextField, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Switch, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

import { handleUpdateRenting, handleUpdateVehicle, selectState, showEditRentingModal } from './masterlistSlice';
import { NearbyType } from '../../../app/constants';
import moment from 'moment';
import { centToDollar, dollarToCent } from '../../../app/utils';

const EditRentingModal = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();

  const renting = state.selectedRenting;
  const vehicleId = renting.vehicle?.id;
  const [startDate, setStartDate] = useState(renting.startDate);
  const [subscriptionStartDate, setSubscriptionStartDate] = useState(renting.subscriptionStartDate);
  const [subscriptionInterval, setSubscriptionInterval] = useState(renting.subscriptionInterval);
  const [monthlyPrice, setMonthlyPrice] = useState(centToDollar(renting.sellPrices?.monthly));
  const [vehicleRegistrationNumber, setVehicleRegistrationNumber] = useState(renting.vehicle?.registrationNumber);
  const [vehicleModel, setVehicleModel] = useState(renting.vehicle?.model);
  const [vehicleColor, setVehicleColor] = useState(renting.vehicle?.color);
  const [depositPaid, setDepositPaid] = useState(renting.depositPaid);
  const [adminFeePaid, setAdminFeePaid] = useState(renting.adminFeePaid);
  const [nearbyType, setNearbyType] = useState(renting.additionalInformation?.nearbyType ?? NearbyType.NONE);
  const [remark, setRemark] = useState(renting.additionalInformation?.remark);
  const [sendNotification, setSendNotification] = useState(!renting.isSuppressNotification);
  const [requestedFinanceFollowup, setRequestedFinanceFollowup] = useState(renting.additionalInformation?.requestedFinanceFollowupAt ? true : false);

  const submit = async () => {
    try {
      var initRequestedFinanceFollowup = !!renting.requestedFinanceFollowupAt;
      const payload = {
        sellPrices: {
          ...renting.sellPrices,
          monthly: dollarToCent(monthlyPrice)
        },
        startDate,
        subscriptionStartDate,
        subscriptionInterval,
        depositPaid,
        adminFeePaid,
        additionalInformation: {
          ...renting.additionalInformation,
          nearbyType,
          remark,
          ...((initRequestedFinanceFollowup !== requestedFinanceFollowup) && { requestedFinanceFollowupAt: requestedFinanceFollowup ? moment().toDate() : null }),
        },
        isSuppressNotification: !sendNotification,
      }
      dispatch(handleUpdateRenting(payload));
      if (vehicleId) {
        const vehiclePayload = {
          registrationNumber: vehicleRegistrationNumber,
          model: vehicleModel,
          color: vehicleColor
        }
        dispatch(handleUpdateVehicle(vehicleId, vehiclePayload));
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="edit-masterlist-data-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close className="float-right cursor-pointer" onClick={() => dispatch(showEditRentingModal(false))} />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Edit Masterlist Data</p>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="startDate"
                label="Start Date"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
                margin="normal"
                variant="outlined"
                type="date"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="subscriptionStartDate"
                label="Subscription Start Date"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setSubscriptionStartDate(e.target.value)}
                value={subscriptionStartDate}
                margin="normal"
                variant="outlined"
                type="date"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl margin="normal" variant="outlined" fullWidth>
                <InputLabel htmlFor="subscriptionInterval">Subscription Interval</InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setSubscriptionInterval(e.target.value)}
                  value={subscriptionInterval}
                  input={<OutlinedInput name="subscriptionInterval" id="subscriptionInterval" label="Subscription Interval" />}
                >
                  <MenuItem key="WEEKLY" value="WEEKLY">Weekly</MenuItem>
                  <MenuItem key="BI_WEEKLY" value="BI_WEEKLY">Bi-weekly</MenuItem>
                  <MenuItem key="MONTHLY" value="MONTHLY">Monthly</MenuItem>
                  <MenuItem key="QUARTERLY" value="QUARTERLY">Quarterly</MenuItem>
                  <MenuItem key="HALF_YEARLY" value="HALF_YEARLY">Half-yearly</MenuItem>
                  <MenuItem key="ANNUALLY" value="ANNUALLY">Annually</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="sellPrice"
                label="Sell Price Per Month"
                onChange={(e) => setMonthlyPrice(e.target.value)}
                value={monthlyPrice}
                margin="normal"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl margin="normal" variant="outlined" fullWidth>
                <InputLabel htmlFor="nearbyType">Nearby Type</InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setNearbyType(e.target.value)}
                  value={nearbyType}
                  input={<OutlinedInput name="nearbyType" id="nearbyType" label="Nearby Type" />}
                >
                  {Object.values(NearbyType).map((type) => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                  id="vehicleRegistrationNumber"
                  label="Vehicle Registration Number"
                  onChange={(e) => setVehicleRegistrationNumber(e.target.value)}
                  value={vehicleId ? vehicleRegistrationNumber : "N/A - No Vehicle Register"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  disabled={!vehicleId}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                  id="vehicleModel"
                  label="Vehicle Model"
                  onChange={(e) => setVehicleModel(e.target.value)}
                  value={vehicleId ? vehicleModel : "N/A - No Vehicle Register"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  disabled={!vehicleId}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                  id="vehicleColor"
                  label="Vehicle Color"
                  onChange={(e) => setVehicleColor(e.target.value)}
                  value={vehicleId ? vehicleColor : "N/A - No Vehicle Register"}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  disabled={!vehicleId}
                />
            </Grid>
            <Grid item xs={12} sm={3} mt={1}>
              <p className="mt-[5px] text-[#929292] text-[14px]">Deposit Paid</p>
              <Grid component="label" container alignItems="center">
                <Grid item>No</Grid>
                <Grid item>
                  <Switch
                    color="primary"
                    checked={depositPaid}
                    onChange={(e) => setDepositPaid(e.target.checked)}
                  />
                </Grid>
                <Grid item>Yes</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} mt={1}>
              <p className="mt-[5px] text-[#929292] text-[14px]">Admin Fee Paid</p>
              <Grid component="label" container alignItems="center">
                <Grid item>No</Grid>
                <Grid item>
                  <Switch
                    color="primary"
                    checked={adminFeePaid}
                    onChange={(e) => setAdminFeePaid(e.target.checked)}
                  />
                </Grid>
                <Grid item>Yes</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} mt={1}>
              <p className="mt-[5px] text-[#929292] text-[14px]">Finance Check Required</p>
              <Grid component="label" container alignItems="center">
                <Grid item>No</Grid>
                <Grid item>
                  <Switch
                    color="primary"
                    checked={requestedFinanceFollowup}
                    onChange={(e) => setRequestedFinanceFollowup(e.target.checked)}
                  />
                </Grid>
                <Grid item>Yes</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} mt={1}>
              <p className="mt-[5px] text-[#929292] text-[14px]">Send Notification</p>
              <Grid component="label" container alignItems="center">
                <Grid item>No</Grid>
                <Grid item>
                  <Switch
                    color="primary"
                    checked={sendNotification}
                    onChange={(e) => setSendNotification(e.target.checked)}
                  />
                </Grid>
                <Grid item>Yes</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} mt={1}>
              <Typography variant="body2" color="error">
                Enabling Send Notification will auto-send notifications for future actions.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="remark"
                label="Remarks"
                onChange={(e) => setRemark(e.target.value)}
                value={remark}
                margin="normal"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <button onClick={submit} className="bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md">Update</button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}

export default EditRentingModal;