import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Checkbox, TablePagination, TableFooter, InputBase, IconButton, debounce } from "@mui/material";
import { TableToolbar } from './TableToolbar';
import { handleGetUsers, selectState, setSelectedId, setPage, setRowsPerPage, openAdminTopUpModal, closeWalletTransactionModal, openWalletTransactionModal, setSelectedWalletId, setSearchValue } from './userSlice';
import TablePaginationActions from './TablePaginationActions';
import SearchIcon from '@mui/icons-material/Search';
import styles from './User.module.css';
import { WalletTransactions } from '../wallet/WalletTransactions';
import { AdminTopUp } from './AdminTopUp';
import { centToDollar } from '../../app/utils';
import { StickyTableHead } from '../../helpers/GeneralComponent';

export function User(props) {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetUsers());
    }
  });

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetUsers());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetUsers());
  };

  const onSearchValueChange = (e) => {
    dispatch(setPage(0));
    dispatch(setSearchValue(e.target.value));
    dispatch(handleGetUsers());
  }

  const debouncedOnSearchValueChange = debounce(onSearchValueChange, 1000);

  return (
    <div className={styles.container}>
      <Paper
        component="form"
        sx={{ p: '2px 4px', mb: '10px', display: 'flex', alignItems: 'center' }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search..."
          inputProps={{ 'aria-label': 'search', 'className': 'focus:shadow-none focus:ring-0' }}
          onChange={debouncedOnSearchValueChange}
        />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
      <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
        <div className="h-[64px] sticky left-0 top-0 z-[1] right-0 bg-white">
          <div className="h-[64px] absolute left-0 top-0 right-0">
            <TableToolbar 
              selectedId={state.selectedId}
              numSelected={state.selectedId === null ? 0 : 1}
              adminTopUp={() => dispatch(openAdminTopUpModal())}
            />
          </div>
        </div>
        <Table size="small">
          <StickyTableHead>
            <TableRow>
              <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
              <TableCell align="left" className={styles.tableHeaderNarrow}>ID</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Email</TableCell>
              <TableCell align="left" className={styles.tableHeader}>First Name</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Last Name</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Contact Number</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Wallet Balance (RM)</TableCell>
              <TableCell align="left" className={styles.tableHeader}>IC Number</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Passport Number</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Company</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Occupation</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Birthday</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Address</TableCell>
            </TableRow>
          </StickyTableHead>
          <TableBody>
            {
              state.users.length > 0 ? (
                state.users.map((user) => (
                  <TableRow 
                    key={user.id}
                    hover
                    onClick={() => dispatch(setSelectedId(state.selectedId === user.id ? null : user.id))}
                    role="checkbox"
                    aria-checked={state.selectedId === user.id ? true : false}
                    selected={state.selectedId === user.id ? true : false}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox 
                        color="primary"
                        checked={state.selectedId === user.id ? true : false}
                      />
                    </TableCell>
                    <TableCell align="left" className={styles.tableCellNarrow}>{user.id}</TableCell>
                    <TableCell align="left">{user.userName}</TableCell>
                    <TableCell align="left">{user.firstName}</TableCell>
                    <TableCell align="left">{user.lastName}</TableCell>
                    <TableCell align="left">{user.contactNumber}</TableCell>
                    <TableCell align="left"
                      onClick={() => {
                        dispatch(setSelectedWalletId(user.wallet.id))
                        dispatch(openWalletTransactionModal())
                      }}
                      style={{cursor: "pointer"}}
                    >{centToDollar(user.wallet?.balanceAmount || 0)}</TableCell>
                    <TableCell align="left">{user.identityCardNumber}</TableCell>
                    <TableCell align="left">{user.passportNumber}</TableCell>
                    <TableCell align="left">{user.company}</TableCell>
                    <TableCell align="left">{user.occupation}</TableCell>
                    <TableCell align="left">{user.birthDay}</TableCell>
                    <TableCell align="left">{user.address}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={11} align="center">
                    <p>No Users</p>
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                colSpan={11}
                count={state.pagination ? state.pagination.total_results ? state.pagination.total_results : 0 : 0}
                rowsPerPage={state.rowsPerPage}
                page={state.page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {
        state.adminTopUpModalOpen &&
        <AdminTopUp selectedUserId={state.selectedId} />
      }
      {
        state.walletTransactionModalOpen &&
        <WalletTransactions walletId={state.selectedWalletId} closeModal={() => dispatch(closeWalletTransactionModal())} user={state.selectedId ? state.users.find(user => user.id === state.selectedId) : null} />
      }
    </div>
  );
}
