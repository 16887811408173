import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal, Paper, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Checkbox, TableFooter, TablePagination } from "@mui/material";
import { Close } from "@mui/icons-material";
import styles from './AffiliateCampaign.module.css';
import {
  showGenerateAffiliateLinkModal,
  downloadAffiliateLinks,
} from "./affiliateCampaignSlice";
import { StickyTableHead } from "../../../helpers/GeneralComponent";

import { ParkingAreaTableToolbar as TableToolbar } from './ParkingAreaTableToolbar';
import TablePaginationActions from './TablePaginationActions';
import { selectState as selectParkingAreaState, handleGetParkingAreas, setPage, setRowsPerPage } from './../parkingArea/parkingAreaSlice';
const GenerateAffiliateLinkModal = () => {
  const parkingAreaState = useSelector(selectParkingAreaState)
  const dispatch = useDispatch();
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    dispatch(handleGetParkingAreas());
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetParkingAreas());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetParkingAreas());
  };

  const handleRowClick =  (parkingArea) => {
    const selectedIndex = selectedIds.indexOf(parkingArea.id);
    let newSelectedIds = [...selectedIds];

    if (selectedIndex === -1) {
      newSelectedIds.push(parkingArea.id);
    } else {
      newSelectedIds.splice(selectedIndex, 1);
    }

    setSelectedIds(newSelectedIds);
  };

  const isSelected = (id) => selectedIds.indexOf(id) !== -1;

  const submit = () => {
    try {
      dispatch(downloadAffiliateLinks(selectedIds));
      dispatch(showGenerateAffiliateLinkModal(false));
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Modal
      open={true}
      aria-labelledby="generate-link-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[50%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showGenerateAffiliateLinkModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
                <div className="h-[64px] sticky left-0 top-0 z-[1] right-0 bg-white">
                  <div className="h-[64px] absolute left-0 top-0 right-0">
                    <TableToolbar
                      numSelected={selectedIds.length}
                    />
                  </div>
                </div>
                <Table size="small">
                  <StickyTableHead>
                    <TableRow>
                      <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                      <TableCell align="left" className={styles.tableHeader}>Name</TableCell>
                      <TableCell align="left" className={styles.tableHeader250}>Address</TableCell>
                    </TableRow>
                  </StickyTableHead>
                  <TableBody>
                    {
                      parkingAreaState.parkingAreas.length > 0 ? (
                        parkingAreaState.parkingAreas.map((parkingArea) => (
                          <TableRow
                            key={parkingArea.id}
                            hover
                            onClick={() => handleRowClick(parkingArea)}
                            role="checkbox"
                            aria-checked={isSelected(parkingArea.id)}
                            selected={isSelected(parkingArea.id)}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isSelected(parkingArea.id)}
                              />
                            </TableCell>
                            <TableCell align="left" className={styles.tableCell}>{parkingArea.name}</TableCell>
                            <TableCell align="left" className={styles.tableCell250}>{parkingArea.address}</TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={27} align="center">
                            <p>No Parking Areas</p>
                          </TableCell>
                        </TableRow>
                      )
                    }
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        colSpan={4}
                        count={parkingAreaState.pagination ? parkingAreaState.pagination.totalElements ? parkingAreaState.pagination.totalElements : 0 : 0}
                        rowsPerPage={parkingAreaState.rowsPerPage}
                        page={parkingAreaState.page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'rows per page' },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <button
                onClick={submit}
                className="bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md"
              >
                Generate
              </button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default GenerateAffiliateLinkModal;
