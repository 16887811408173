import React from 'react';
import { useSelector } from 'react-redux';
import styles from './Dashboard.module.css';
import ParkitNav from '../../../components/ParkitNav/ParkitNav';
import { selectState as selectParkitNav } from '../../../components/ParkitNav/parkitNavSlice';

export function Dashboard() {
  const { open } = useSelector(selectParkitNav);
  return (
    <div>
      <ParkitNav />
      <div className={`text-left mb-10 p-10 ${open ? 'ml-60' : 'ml-12'}`}>
        <h3 className={styles.subtitle}>Dashboard</h3>
      </div>
    </div>
  );
}