import React from 'react';
import { useDispatch } from 'react-redux';
import { closeEditOrderGroupPaymentStatusModal, handleGetOrderGroups } from './orderGroupSlice';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import { updateOrderGroupPaymentStatus } from './orderGroupAPI';

export function ConfirmPaymentStatus(props) {
    const { orderGroups } = props;
    const dispatch = useDispatch();

    const submit = (props) => {
        orderGroups.forEach(orderGroup => {
            let paidFlag = !orderGroup.paid;
            let paymentStatus = orderGroup.paid ? "NOT_PAID" : "PAID_BY_BANK_TRANSFER";
            try {
                updateOrderGroupPaymentStatus(orderGroup.id, paidFlag, paymentStatus);
            } catch (error) {
                alert(`Order Group ID (${orderGroup.id}) hit error: ${error.message}`);
            }
        });

        dispatch(closeEditOrderGroupPaymentStatusModal());
        dispatch(handleGetOrderGroups(props.orderGroupType));
    }

    return (
        <div>
        <Dialog open={true} onClose={() => dispatch(closeEditOrderGroupPaymentStatusModal())} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Update Order Groups Payment Status</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to mark all selected order groups as {orderGroups[0].paid ? "unpaid" : "paid"}?
                </DialogContentText>  
            </DialogContent>
            <DialogActions>
                <Button onClick={() =>  dispatch(closeEditOrderGroupPaymentStatusModal())} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => submit(props)} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}