import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getListings } from '../../../api/listingsAPI';
import { getRentings } from '../../../api/rentingsAPI';
import { performActionable } from '../../../api/dynamicAPI.js';
import { ListingStatus, RentingStatus, SearchType } from '../../../app/constants';
import { addFilter } from '../../../app/utils.js';

export const terminationSlice = createSlice({
  name: 'termination',
  initialState: {
    listings: [],
    rentings: [],
    listingPagination: {},
    listingPage: 0,
    listingRowsPerPage: 10,
    listingSort: 'desc',
    listingSortBy: 'createdAt',
    listingSearchValue: '',
    selectedListingFilter: {
      parkingAreaName: null,
      customerDueDate: null,
      comments: null,
      cardCollectionTime: null
    },
    rentingPagination: {},
    rentingPage: 0,
    rentingRowsPerPage: 10,
    rentingSort: 'desc',
    rentingSortBy: 'createdAt',
    rentingSearchValue: '',
    selectedRentingFilter: {
      status: null,
      listingStatus: null
    },
    selectedListingId: null,
    selectedRentingId: null,
    selectedAppointmentId: null,
    listingActionUrl: "",
    listingActionMethod: "",
    rentingActionUrl: "",
    rentingActionMethod: "",
    approveListingTerminationModalOpen: false,
    rejectListingTerminationModalOpen: false,
    approveRentingTerminationModalOpen: false,
    rejectRentingTerminationModalOpen: false,
    viewAppointmentModalOpen: false,
    filterRentingModalOpen: false,
    filterListingModalOpen: false,
    successMessage: "",
    errorMessage: ""
  },
  reducers: {
    setListingPage: (state, action) => {
      state.listingPage = action.payload;
    },
    setListingRowsPerPage: (state, action) => {
      state.listingRowsPerPage = action.payload;
    },
    setListingPagination: (state, action) => {
      state.listingPagination = action.payload;
    },
    setListingSort: (state, action) => {
      state.listingSort = action.payload;
    },
    setListingSortBy: (state, action) => {
      state.listingSortBy = action.payload;
    },
    setListingSearchValue: (state, action) => {
      state.listingSearchValue = action.payload;
    },
    setRentingPage: (state, action) => {
      state.rentingPage = action.payload;
    },
    setRentingRowsPerPage: (state, action) => {
      state.rentingRowsPerPage = action.payload;
    },
    setRentingPagination: (state, action) => {
      state.rentingPagination = action.payload;
    },
    setRentingSort: (state, action) => {
      state.rentingSort = action.payload;
    },
    setRentingSortBy: (state, action) => {
      state.rentingSortBy = action.payload;
    },
    setRentingSearchValue: (state, action) => {
      state.rentingSearchValue = action.payload;
    },
    setSelectedRentingFilter: (state, action) => {
      state.selectedRentingFilter = action.payload;
    },
    setSelectedListingFilter: (state, action) => {
      state.selectedListingFilter = action.payload;
    },
    setListings: (state, action) => {
      state.listings = action.payload;
      state.status = 'success';
    },
    setRentings: (state, action) => {
      state.rentings = action.payload;
      state.status = 'success';
    },
    setSelectedListingId: (state, action) => {
      state.selectedListingId = action.payload;
    },
    setSelectedRentingId: (state, action) => {
      state.selectedRentingId = action.payload;
    },
    setListingActionUrl: (state, action) => {
      state.listingActionUrl = action.payload;
    },
    setListingActionMethod: (state, action) => {
      state.listingActionMethod = action.payload;
    },
    setRentingActionUrl: (state, action) => {
      state.rentingActionUrl = action.payload;
    },
    setRentingActionMethod: (state, action) => {
      state.rentingActionMethod = action.payload;
    },
    setAppointmentId: (state, action) => {
      state.selectedAppointmentId = action.payload;
    },
    showApproveListingTerminationModal: (state, action) => {
      state.approveListingTerminationModalOpen = action.payload;
    },
    showRejectListingTerminationModal: (state, action) => {
      state.rejectListingTerminationModalOpen = action.payload;
    },
    showApproveRentingTerminationModal: (state, action) => {
      state.approveRentingTerminationModalOpen = action.payload;
    },
    showRejectRentingTerminationModal: (state, action) => {
      state.rejectRentingTerminationModalOpen = action.payload;
    },
    showViewAppointmentModal: (state, action) => {
      state.viewAppointmentModalOpen = action.payload;
    },
    showFilterRentingModal: (state, action) => {
      state.filterRentingModalOpen = action.payload;
    },
    showFilterListingModal: (state, action) => {
      state.filterListingModalOpen = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    }
  },
});

export const { setSelectedListingFilter, showFilterListingModal, setSelectedRentingFilter, showFilterRentingModal, setListingSearchValue, setRentingSearchValue, setAppointmentId, setListingPage, setListingRowsPerPage, setListingPagination, setListingSort, setListingSortBy, setRentingPage, setRentingRowsPerPage, setRentingPagination, setRentingSort, setRentingSortBy, setListings, setRentings, setSelectedListingId, setSelectedRentingId, setListingActionUrl, setListingActionMethod, setRentingActionUrl, setRentingActionMethod, showApproveListingTerminationModal, showRejectListingTerminationModal, showApproveRentingTerminationModal, showRejectRentingTerminationModal, showViewAppointmentModal, setSuccessMessage, setErrorMessage } = terminationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.vendor.value)`
export const selectState = state => state.termination;

export const handleGetListings = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    let filterParamsArray = [];
    const { selectedListingFilter } = state.termination;

    addFilter(filterParamsArray, 'parkingArea.name', selectedListingFilter.parkingAreaName);
    addFilter(filterParamsArray, 'termination.customerDueDate', selectedListingFilter.customerDueDate);
    addFilter(filterParamsArray, 'termination.comments', selectedListingFilter.comments);
    addFilter(filterParamsArray, 'termination.cardCollectionTime', selectedListingFilter.cardCollectionTime ? `"${selectedListingFilter.cardCollectionTime}"` : selectedListingFilter.cardCollectionTime);
    let filterParams = filterParamsArray.join(',');

    const response = await getListings(state.termination.listingPage, state.termination.listingRowsPerPage, state.termination.listingSort, state.termination.listingSortBy, ListingStatus.TERMINATING, state.termination.listingSearchValue, SearchType.TERMINATION, filterParams);
    const { content, ...pagination } = response.data;

    dispatch(setListingPagination(pagination));
    dispatch(setListings(content));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleGetRentings = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    let filterParamsArray = [];
    const { selectedRentingFilter } = state.termination;

    addFilter(filterParamsArray, 'status', selectedRentingFilter.status);
    addFilter(filterParamsArray, 'listing.status', selectedRentingFilter.listingStatus);
    let filterParams = filterParamsArray.join(',');

    const response = await getRentings(state.termination.rentingPage, state.termination.rentingRowsPerPage, state.termination.rentingSort, state.termination.rentingSortBy, RentingStatus.TERMINATING, state.termination.rentingSearchValue, SearchType.TERMINATION, filterParams);
    const { content, ...pagination } = response.data;

    dispatch(setRentingPagination(pagination));
    dispatch(setRentings(content));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleApproveListingTermination = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await performActionable(state.termination.listingActionUrl, state.termination.listingActionMethod, null);
    dispatch(showApproveListingTerminationModal(false));
    dispatch(setSuccessMessage("Successfully approved listing termination!"));
    dispatch(handleGetListings());
    dispatch(setSelectedListingId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleRejectListingTermination = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await performActionable(state.termination.listingActionUrl, state.termination.listingActionMethod, null);
    dispatch(showRejectListingTerminationModal(false));
    dispatch(setSuccessMessage("Successfully rejected listing termination!"));
    dispatch(handleGetListings());
    dispatch(setSelectedListingId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleApproveRentingTermination = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await performActionable(state.termination.rentingActionUrl, state.termination.rentingActionMethod, null);
    dispatch(showApproveRentingTerminationModal(false));
    dispatch(setSuccessMessage("Successfully approved renting termination!"));
    dispatch(handleGetRentings());
    dispatch(setSelectedRentingId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleRejectRentingTermination = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await performActionable(state.termination.rentingActionUrl, state.termination.rentingActionMethod, null);
    dispatch(showRejectRentingTerminationModal(false));
    dispatch(setSuccessMessage("Successfully rejected renting termination!"));
    dispatch(handleGetRentings());
    dispatch(setSelectedRentingId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export default terminationSlice.reducer;