import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Checkbox, TablePagination, TableFooter, Button } from "@mui/material";
import { CreateDeliveryRate } from './CreateDeliveryRate';
import { EditDeliveryRate } from './EditDeliveryRate';
import { ConfirmDelete } from './ConfirmDelete';
import { TableToolbar } from './TableToolbar';
import { openCreateDeliveryRateModal, openEditDeliveryRateModal, openDeleteDeliveryRateModal, handleGetDeliveryRateProfiles, selectState, setSelectedId, setPage, setRowsPerPage } from './deliveryRateSlice';
import { selectState as selectDeliveritNav } from '../../../components/DeliveritNav/deliveritNavSlice';
import TablePaginationActions from './TablePaginationActions';
import styles from './DeliveryRate.module.css';
import DeliveritNav from '../../../components/DeliveritNav/DeliveritNav';
import { StickyTableHead } from '../../../helpers/GeneralComponent';

export function DeliveryRate(props) {
  const state = useSelector(selectState);
  const deliveritNavState = useSelector(selectDeliveritNav);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetDeliveryRateProfiles());
    }
  });

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetDeliveryRateProfiles());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetDeliveryRateProfiles());
  };

  return (
    <div className={`${deliveritNavState.open ? 'ml-60' : 'ml-12'}`}>
      <DeliveritNav />
      <div className={styles.container}>
        <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
          <Table size="small">
            <StickyTableHead className="!top-0">
              <TableRow>
                <TableCell colSpan={20} sx={{ padding: 0, borderBottom: 'none' }}>
                  <TableToolbar 
                    numSelected={state.selectedId === null ? 0 : 1}
                    createDeliveryRateProfile={() => dispatch(openCreateDeliveryRateModal())}
                    editDeliveryRate={() => dispatch(openEditDeliveryRateModal())}
                    deleteDeliveryRateProfile={() => dispatch(openDeleteDeliveryRateModal())}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                <TableCell align="left" className={styles.tableHeaderNarrow}>ID</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Name</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Car Base Fare</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Car Min Fare</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Car Tier 1</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Car Tier 2</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Car Subsequent KM</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Car Discount</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Car Additional Stop</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Car Door To Door</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Motor Base Fare</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Motor Min Fare</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Motor Tier 1</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Motor Tier 2</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Motor Subsequent KM</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Motor Discount</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Motor Additional Stop</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Motor Door To Door</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Last Updated</TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {
                state.deliveryRates.length > 0 ? (
                  state.deliveryRates.map((deliveryRate) => (
                    <TableRow 
                      key={deliveryRate.id}
                      hover
                      onClick={() => dispatch(setSelectedId(state.selectedId === deliveryRate.id ? null : deliveryRate.id))}
                      role="checkbox"
                      aria-checked={state.selectedId === deliveryRate.id ? true : false}
                      selected={state.selectedId === deliveryRate.id ? true : false}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox 
                          color="primary"
                          checked={state.selectedId === deliveryRate.id ? true : false}
                        />
                      </TableCell>
                      <TableCell align="left" className={styles.tableCellNarrow}>{deliveryRate.id}</TableCell>
                      <TableCell align="left">{deliveryRate.name}</TableCell>
                      <TableCell align="left">{deliveryRate.carRate.base}</TableCell>
                      <TableCell align="left">{deliveryRate.carRate.min}</TableCell>
                      <TableCell align="left">{deliveryRate.carRate.tier1}</TableCell>
                      <TableCell align="left">{deliveryRate.carRate.tier2}</TableCell>
                      <TableCell align="left">{deliveryRate.carRate.next}</TableCell>
                      <TableCell align="left">{(deliveryRate.carRate.discount * 100).toFixed(2)}%</TableCell>
                      <TableCell align="left">{deliveryRate.carRate.additionalStop}</TableCell>
                      <TableCell align="left">{"RM " + (deliveryRate.carRate.doorToDoor !== undefined ? (deliveryRate.carRate.doorToDoor / 100).toFixed(2) : "2.00")}</TableCell>
                      <TableCell align="left">{deliveryRate.motorRate.base}</TableCell>
                      <TableCell align="left">{deliveryRate.motorRate.min}</TableCell>
                      <TableCell align="left">{deliveryRate.motorRate.tier1}</TableCell>
                      <TableCell align="left">{deliveryRate.motorRate.tier2}</TableCell>
                      <TableCell align="left">{deliveryRate.motorRate.next}</TableCell>
                      <TableCell align="left">{(deliveryRate.motorRate.discount * 100).toFixed(2)}%</TableCell>
                      <TableCell align="left">{deliveryRate.motorRate.additionalStop}</TableCell>
                      <TableCell align="left">{"RM " + (deliveryRate.motorRate.doorToDoor !== undefined ? (deliveryRate.motorRate.doorToDoor / 100).toFixed(2) : "2.00")}</TableCell>
                      <TableCell align="left">{deliveryRate.updatedAt}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={17} align="center">
                      <p>No Delivery Rates</p>
                      <Button onClick={() => dispatch(openCreateDeliveryRateModal())} className={styles.gradientButton} style={{marginBottom: 20}}>Create order</Button>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={17}
                  count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                  rowsPerPage={state.rowsPerPage}
                  page={state.page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        {
          state.createDeliveryRateModalOpen  &&
          <CreateDeliveryRate createDeliveryRateModalOpen={state.createDeliveryRateModalOpen} />
        }
        {
          state.editDeliveryRateModalOpen  &&
          <EditDeliveryRate deliveryRate={state.selectedId ? state.deliveryRates.find(deliveryRate => deliveryRate.id === state.selectedId) : null} />
        }
        {
          state.deleteDeliveryRateModalOpen  &&
          <ConfirmDelete deliveryRate={state.selectedId ? state.deliveryRates.find(deliveryRate => deliveryRate.id === state.selectedId) : null} />
        }
      </div>
    </div>
  );
}
