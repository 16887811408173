import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeCancelOrderModal, handleGetOrdersByOrderGroup } from './orderSlice';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Paper } from '@mui/material';
import styles from './Order.module.css';
import { cancelOrder } from './orderAPI';

export function CancelOrder(props) {
    const { order } = props;
    const dispatch = useDispatch();
    const [adjustmentFees, setAdjustmentFees] = useState(0);

    const submit = async () => {
        if (adjustmentFees > 0) {
            let adjustmentFeesInCent = adjustmentFees * 100;
            try {
                const res = await cancelOrder(props.orderGroup.id, order.id, adjustmentFeesInCent);
                if(res.error) throw res.error;
        
                dispatch(closeCancelOrderModal());
                dispatch(handleGetOrdersByOrderGroup(props.orderGroup.id));
            } catch (err) {
                alert(err);
            }
        } else {
            alert("Adjustment fees is required!");
        }
    }

    return (
        <div>
        <Dialog open={true} onClose={() => dispatch(closeCancelOrderModal())} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Cancel Order</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to cancel this order?
                </DialogContentText>  
                <br/>
                <p>Adjustment Fees (RM)</p>
                <Paper className={styles.textInputContainer}>
                    <input
                    id="adjustmentFees"
                    name="adjustmentFees"
                    value={adjustmentFees}
                    onChange={e => setAdjustmentFees(e.target.value)}
                    placeholder="Adjustment Fees (RM)"
                    className={styles.textInput}
                    />
                </Paper>
                <br/>
            </DialogContent>
            <DialogActions>
                <Button onClick={() =>  dispatch(closeCancelOrderModal())} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => submit()} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}