import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as Api from "../../api/walletAPI";

export const walletSlice = createSlice({
  name: 'wallet',
  initialState: {
    wallets: [],
    status: 'idle',
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null
  },
  reducers: {
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    getWallets: (state, action) => {
      state.wallets = action.payload;
      state.status = 'success'
    },
  },
});

export const { setSelectedId, setPage, setRowsPerPage, setPagination, getWallets } = walletSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.wallet.value)`
export const selectState = state => state.wallet;

export const handleGetWallets = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const res = await Api.getWallets(state.wallet.page, state.wallet.rowsPerPage);
    if (res.error) {
      throw res.error;
    };
    
    const { content, ...pagination } = res;
    let wallets = content;

    dispatch(setPagination(pagination));
    dispatch(getWallets(wallets));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};


export default walletSlice.reducer;
