import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useParams } from 'react-router-dom'
import { handleGetAffiliateCampaignReferrals, selectState, showFilterModal, setSelectedPayoutStatus } from './affiliateCampaignReferralSlice';

const FilterModal = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  const { id } = useParams();

  const submit = () => {
    try {
      dispatch(handleGetAffiliateCampaignReferrals(id));
    } catch (error) {
      console.log(error.message);
    }
  };

  const handlePayoutStatusChange = (event) => {
    dispatch(setSelectedPayoutStatus(event.target.value));
  };

  return (
    <Modal
      open={true}
      aria-labelledby="filter-referral-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[20%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showFilterModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Filter Affiliate Campaign Referral Records</p>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <FormControl
                margin="normal"
                variant="outlined"
                fullWidth
                required
              >
                <InputLabel id="user-group-label">Payout Status</InputLabel>
                <Select
                  labelId="payoutStatus"
                  id="payoutStatus"
                  value={state.selectedPayoutStatus}
                  onChange={handlePayoutStatusChange}
                >
                  <MenuItem value={'all'}>ALL</MenuItem>
                  <MenuItem value={'paid'}>Paid</MenuItem>
                  <MenuItem value={'payable'}>Payable</MenuItem>
                  <MenuItem value={'nonPayable'}>Non-Payable</MenuItem>
                  <MenuItem value={'expired'}>Non-Payable, Expired</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <button
                onClick={submit}
                className="bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md"
              >
                Filter
              </button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default FilterModal;