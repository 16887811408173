import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { addRenting, getRentings, updateRenting } from '../../../api/rentingsAPI';
import { performActionable } from '../../../api/dynamicAPI';
import { RentingStatus, SearchType } from '../../../app/constants';
import { createAppointment } from '../../../api/appointmentAPI';
import { updateVehicle } from '../../../api/vehicleAPI';
import { addFilter } from '../../../app/utils';

export const rentingSlice = createSlice({
  name: 'renting',
  initialState: {
    rentings: [],
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    sort: 'desc',
    sortBy: 'createdAt',
    selectedId: null,
    selectedRenting: null,
    searchValue: '',
    selectedFilter: {
      parkingAreaName: null,
    },
    actionUrl: "",
    actionMethod: "",
    addModalOpen: false,
    editModalOpen: false,
    allocateModalOpen: false,
    terminateModalOpen: false,
    createAppointmentModalOpen: false,
    filterModalOpen: false,
    successMessage: "",
    errorMessage: ""
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setRentings: (state, action) => {
      state.rentings = action.payload;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setSelectedRenting: (state, action) => {
      state.selectedRenting = action.payload;
    },
    setSelectedFilter: (state, action) => {
      state.selectedFilter = action.payload;
    },
    setActionUrl: (state, action) => {
      state.actionUrl = action.payload;
    },
    setActionMethod: (state, action) => {
      state.actionMethod = action.payload;
    },
    showAddModal: (state, action) => {
      state.addModalOpen = action.payload;
    },
    showEditModal: (state, action) => {
      state.editModalOpen = action.payload;
    },
    showAllocateModal: (state, action) => {
      state.allocateModalOpen = action.payload;
    },
    showTerminateModal: (state, action) => {
      state.terminateModalOpen = action.payload;
    },
    showCreateAppointmentModal: (state, action) => {
      state.createAppointmentModalOpen = action.payload;
    },
    showFilterModal: (state, action) => {
      state.filterModalOpen = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    }
  }
});

export const { setSelectedFilter, showFilterModal, setSearchValue, setPage, setRowsPerPage, setPagination, setSort, setSortBy, setRentings, setSelectedId, setSelectedRenting, setActionUrl, setActionMethod, showAddModal, showEditModal, showAllocateModal, showTerminateModal, showCreateAppointmentModal, setSuccessMessage, setErrorMessage } = rentingSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.vendor.value)`
export const selectState = state => state.renting;

export const handleGetRentings = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    let filterParamsArray = [];
    const { selectedFilter } = state.renting;

    addFilter(filterParamsArray, 'requestedParkingArea.name', selectedFilter.parkingAreaName);
    let filterParams = filterParamsArray.join(',');

    const response = await getRentings(state.renting.page, state.renting.rowsPerPage, state.renting.sort, state.renting.sortBy, `${RentingStatus.PROCESSING},${RentingStatus.PAYING}`, state.renting.searchValue, SearchType.ENQUIRY, filterParams);
    const { content, ...pagination } = response.data;

    dispatch(setPagination(pagination));
    dispatch(setRentings(content));
  } catch (error) {
    alert(error.message);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleAddRenting = (payload) => async (dispatch) => {
  dispatch(showLoading());

  try {
    await addRenting(payload);
    dispatch(showAddModal(false));
    dispatch(setSuccessMessage("Successfully added driver enquiry!"));
    dispatch(handleGetRentings());
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleUpdateRenting = (renting) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await updateRenting(state.renting.selectedId, renting);
    dispatch(showEditModal(false));
    dispatch(setSuccessMessage("Successfully updated renting!"));
    dispatch(handleGetRentings());
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleUpdateVehicle = (id, payload) => async (dispatch) => {
  dispatch(showLoading());

  try {
    await updateVehicle(id, payload);
    dispatch(showEditModal(false));
    dispatch(setSuccessMessage("Successfully updated vehicle!"));
    dispatch(handleGetRentings());
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage("Failed to update vehicle: " + error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleAllocateRenting = (payload) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await performActionable(state.renting.actionUrl, state.renting.actionMethod, payload);
    dispatch(showAllocateModal(false));
    dispatch(setSuccessMessage("Successfully allocated renting!"));
    dispatch(handleGetRentings());
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleTerminateRenting = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await performActionable(state.renting.actionUrl, state.renting.actionMethod, null);
    dispatch(showTerminateModal(false));
    dispatch(setSuccessMessage("Successfully sent terminate request!"));
    dispatch(handleGetRentings);
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
}

export const handleCreateAppointment = (appointment) => async (dispatch, getState) => {
  dispatch(showLoading());

  try {
    await createAppointment(appointment);
    dispatch(showCreateAppointmentModal(false));
    dispatch(setSuccessMessage("Successfully create appointment!"));
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export default rentingSlice.reducer;