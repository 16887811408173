export const ActionablesDto = {
  AllocateRentingRequest: "AllocateRentingRequest",
  ActivateRentingRequest: "ActivateRentingRequest",
  TerminateRentingRequest: "TerminateRentingRequest",
  ApproveListingRequest: "ApproveListingRequest",
  TerminateListingRequest: "TerminateListingRequest",
  MakePaymentRequest: "MakePaymentRequest",
  SwapParkingBayRequest: "SwapParkingBayRequest",
}

export const ActionablesTitle = {
  Approve: "Approve",
  Reject: "Reject",
  Allocate: "Allocate",
  Deallocate: "Deallocate",
  Activate: "Activate",
  Freeze: "Freeze",
  Unfreeze: "Unfreeze",
  Swap: "Swap",
  Terminate: "Terminate",
  Cancel: "Cancel"
}

export const ListingStatus = {
  INITIAL: "INITIAL",
  PROCESSING: "PROCESSING",
  VACANT: "VACANT",
  OCCUPIED: "OCCUPIED",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  TERMINATING: "TERMINATING",
  TERMINATED: "TERMINATED",
  REJECTED: "REJECTED",
  ALLOCATED: "ALLOCATED"
}

export const RentingStatus = {
  INITIAL: "INITIAL",
  PROCESSING: "PROCESSING",
  BOOKED: "BOOKED",
  PRE_ALLOCATED: "PRE_ALLOCATED",
  ALLOCATED: "ALLOCATED",
  PAYING: "PAYING",
  PAID: "PAID",
  PRE_ACTIVE: "PRE_ACTIVE",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  OWNER_TERMINATING: "OWNER_TERMINATING",
  TERMINATING: "TERMINATING",
  TERMINATED: "TERMINATED",
  CANCELLED: "CANCELLED"
}

export const BusinessType = {
  BUSINESS_TO_BUSINESS: 'BUSINESS_TO_BUSINESS',
  BUSINESS_TO_CONSUMER: 'BUSINESS_TO_CONSUMER',
}

export const NearbyType = {
  NONE: 'NONE',
  WORK_HERE: 'WORK_HERE',
  STAY_HERE: 'STAY_HERE',
  STAY_OR_WORK_NEARBY: 'STAY_OR_WORK_NEARBY',
}

export const Currency = {
  MY: 'MYR',
  HK: 'HKD',
  SG: 'SGD'
}

export const InsuranceStatus = {
  SUBSCRIBED: 'SUBSCRIBED',
  TERMINATING: 'TERMINATING',
  UNSUBSCRIBED: 'UNSUBSCRIBED'
}

export const OperationStatus = {
  SOURCING: 'SOURCING',
  CLOSING: 'CLOSING',
  PENDING_CARD_COLLECTION: 'PENDING_CARD_COLLECTION',
  DONE: 'DONE'
}

export const SubscriptionStatus = {
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  RETRYING: 'RETRYING',
  FAILED: 'FAILED',
  TERMINATING: 'TERMINATING',
  TERMINATED: 'TERMINATED',
}

export const SearchType = {
  MASTERLIST: 'MASTERLIST',
  ENQUIRY: 'ENQUIRY',
  TERMINATION: 'TERMINATION',
  INVENTORY: 'INVENTORY',
  DEFAULT: 'DEFAULT'
}