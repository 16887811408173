import { Toolbar, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useDispatch } from "react-redux";
import { setActionUrl, setActionMethod, showEditModal, showFreezeModal, showUnfreezeModal, showCreateAppointmentModal } from "./listingSlice";
import { ActionablesTitle } from "../../../app/constants";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: 2,
        paddingRight: 1,
    },
    title: {
        flex: '1 1 100%',
    },
    toolTipContainer: {
        position: "absolute",
        right: 10
    }
}));

export const TableToolbar = (props) => {
    const dispatch = useDispatch();
    const classes = useToolbarStyles();
    const { numSelected, title, actionables = [] } = props;

    const freezeAction = actionables.filter((actionable) => actionable.title === ActionablesTitle.Freeze)[0];
    const unfreezeAction = actionables.filter((actionable) => actionable.title === ActionablesTitle.Unfreeze)[0];

    return (
        <Toolbar>
            {numSelected > 0 ? (
                <div className="flex w-full flex-row justify-between items-center">
                    <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                        {numSelected} selected
                    </Typography>
                    <div className="flex flex-row items-center gap-2">
                        <button className="text-primaryblue text-sm font-medium hover:bg-slate-100 rounded px-4 py-2" onClick={() => dispatch(showEditModal(true))}>Edit</button>
                        {freezeAction && (
                            <button
                                className="text-primaryblue text-sm font-medium hover:bg-slate-100 rounded px-4 py-2" 
                                onClick={() => { 
                                    dispatch(showFreezeModal(true));
                                    dispatch(setActionUrl(freezeAction.url));
                                    dispatch(setActionMethod(freezeAction.type));
                                }}
                            >
                                Freeze
                            </button>
                        )}
                        {unfreezeAction && (
                            <button
                                className="text-primaryblue text-sm font-medium hover:bg-slate-100 rounded px-4 py-2" 
                                onClick={() => { 
                                    dispatch(showUnfreezeModal(true));
                                    dispatch(setActionUrl(unfreezeAction.url));
                                    dispatch(setActionMethod(unfreezeAction.type));
                                }}
                            >
                                Unfreeze
                            </button>
                        )}
                        <button className="whitespace-nowrap text-primaryblue text-sm font-medium hover:bg-slate-100 rounded px-4 py-2" onClick={() => dispatch(showCreateAppointmentModal(true))}>Appointment</button>
                    </div>
                </div>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    {title}
                </Typography>
            )}
        </Toolbar>
    );
};