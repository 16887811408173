import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getAffiliateCampaignReferrals, updatePayoutStatus } from './affiliateCampaignReferralAPI'
import moment from 'moment/moment';
import { saveAs } from 'file-saver';

export const affiliateCampaignReferralSlice = createSlice({
  name: 'affiliateCampaignReferral',
  initialState: {
    affiliateCampaignReferrals: [],
    status: 'idle',
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedIds: [],
    selectedReferrals: [],
    selectedYearMonth: moment().format('YYYY-MM'),
    selectedPayoutStatus: 'all',
    filterModalOpen: false,
    monthSelectionModalOpen: false,
    payoutModalOpen: false,
    successMessage: "",
    errorMessage: ""
  },
  reducers: {
    setSelectedIds: (state, action) => {
      state.selectedIds = action.payload;
    },
    setSelectedReferrals: (state, action) => {
      state.selectedReferrals = action.payload;
    },
    setSelectedYearMonth: (state, action) => {
      state.selectedYearMonth = action.payload;
    },
    setSelectedPayoutStatus: (state, action) => {
      state.selectedPayoutStatus = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setAffiliateCampaignReferrals: (state, action) => {
      state.affiliateCampaignReferrals = action.payload;
      state.status = 'success';
    },
    showFilterModal: (state, action) => {
      state.filterModalOpen = action.payload;
    },
    showMonthSelectionModal: (state, action) => {
      state.monthSelectionModalOpen = action.payload;
    },
    showPayoutModal: (state, action) => {
      state.payoutModalOpen = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  }
});

export const { setSelectedIds, setSelectedReferrals, setSelectedYearMonth, setSelectedPayoutStatus, setPage, setRowsPerPage, setPagination, setAffiliateCampaignReferrals, showFilterModal, showMonthSelectionModal, showPayoutModal, setSuccessMessage, setErrorMessage } = affiliateCampaignReferralSlice.actions;

export const selectState = state => state.affiliateCampaignReferral;

export const handleGetAffiliateCampaignReferrals = (affiliateId) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const response = await getAffiliateCampaignReferrals(state.affiliateCampaignReferral.page, state.affiliateCampaignReferral.rowsPerPage, affiliateId, state.affiliateCampaignReferral.selectedYearMonth, state.affiliateCampaignReferral.selectedPayoutStatus);
    const { content, ...pagination } = response.data;

    dispatch(showFilterModal(false));
    dispatch(showMonthSelectionModal(false));
    dispatch(setPagination(pagination));
    dispatch(setAffiliateCampaignReferrals(content));
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(hideLoading());
  }
}

export const downloadReferralRecords = (affiliateId) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const headers = {
      'referralName': 'Name',
      'referralEmail': 'Email',
      'referralContact': 'Contact Number',
      'rentingId': 'Renting ID',
      'enquiredDate': 'Enquired Date',
      'adminFeePaymentDate': 'Admin Fee Payment Date',
      'depositPaymentDate': 'Deposit Payment Date',
      'firstSubPaymentDate': 'First Subscription Payment Date',
      'location': 'Location',
      'monthlySellPrice': 'Monthly Sell Price',
      'referrerPayableAmount': 'Referrer Payment Amount',
      'referrerPayoutStatus': 'Referrer Payout Status'
    };

    const csvContent = [
      Object.values(headers).join(','), // Header row with mapped headers
      ...state.affiliateCampaignReferral.affiliateCampaignReferrals.map(obj => {
        return Object.keys(headers).map(key => {
          if (obj.hasOwnProperty(key) && typeof obj[key] === 'string' && obj[key].includes(',')) {
            // Enclose value in double quotes and escape existing double quotes
            return `"${obj[key].replace(/"/g, '""')}"`;
          } else if (obj.hasOwnProperty(key)) {
            return obj[key];
          } else {
            return '';
          }
        }).join(',');
      })
    ].join('\n');

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" })
    saveAs(blob, `${affiliateId}-${state.affiliateCampaignReferral.selectedYearMonth}.csv`);
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(hideLoading());
  }
}

export const handleUpdatePayoutStatus = (affiliateId) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const hasNonPayableStatus = state.affiliateCampaignReferral.selectedReferrals.some(referral => referral.referrerPayoutStatus !== "payable");
    if (!hasNonPayableStatus) {
      await updatePayoutStatus(affiliateId, state.affiliateCampaignReferral.selectedIds);
      dispatch(showPayoutModal(false));
      dispatch(setSuccessMessage("Successfully update referrer payout status!"));
      dispatch(handleGetAffiliateCampaignReferrals(affiliateId))
      dispatch(setSelectedIds([]));
    } else {
        dispatch(setErrorMessage("Selected Referrer's Payout Status not Eligible to update."));
        dispatch(showPayoutModal(false));
    }
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
}

export default affiliateCampaignReferralSlice.reducer;