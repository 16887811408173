import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getRentings } from '../../../api/rentingsAPI';
import { RentingStatus, SearchType } from '../../../app/constants';

export const archivedRentingsSlice = createSlice({
  name: 'archivedRentings',
  initialState: {
    rentings: [],
    searchValue: '',
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    sort: 'desc',
    sortBy: 'createdAt',
    successMessage: "",
    errorMessage: ""
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setRentings: (state, action) => {
      state.rentings = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    }
  }
});

export const { setSearchValue, setPage, setRowsPerPage, setPagination, setSort, setSortBy, setRentings, setSuccessMessage, setErrorMessage } = archivedRentingsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.vendor.value)`
export const selectState = state => state.archivedRentings;

export const handleGetRentings = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const response = await getRentings(state.archivedRentings.page, state.archivedRentings.rowsPerPage, state.archivedRentings.sort, state.archivedRentings.sortBy, `${RentingStatus.TERMINATED},${RentingStatus.CANCELLED}`, state.archivedRentings.searchValue, SearchType.DEFAULT);
    const { content, ...pagination } = response.data;

    dispatch(setPagination(pagination));
    dispatch(setRentings(content));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export default archivedRentingsSlice.reducer;