import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Paper } from "@mui/material";
import { closeViewRedeemedVoucherModal } from './voucherSlice';
import * as Api from "./voucherAPI";
import * as UserApi from "../user/userAPI";
import { Close } from "@mui/icons-material";
import styles from './Voucher.module.css';

export function ViewRedeemedVoucher(props) {
  const { code } = props
  const dispatch = useDispatch();
  const [init, setInit] = useState(false);
  const [redeemedVouchers, setRedeemedVouchers] = useState([]);

  useEffect(() => {
    const handleGetVouchers = async () => {
      try {
        const res = await Api.getRedeemedVouchers(code);
        if (res.error) {
          throw res.error;
        };

        const vouchersWithUserObject = await Promise.all(
          res.map(async (voucher) => {
            const user = await handleGetUser(voucher.userId);
            if (user) {
              return { ...voucher, user: user };
            }

            return { ...voucher };
          })
        );

        console.log(vouchersWithUserObject);
        setRedeemedVouchers(vouchersWithUserObject);
      } catch (err) {
        console.log(err);
      }
    };

    if (!init && code) {
      handleGetVouchers();
      setInit(true);
    }
  }, [init, code]);

  const handleGetUser = async (id) => {
    try {
      const res = await UserApi.getUser(id);
      if (res.error) {
        throw res.error;
      };

      return res;
    } catch (err) {
      return null
    }
  };

  return (
    <Modal
      open={true}
      aria-labelledby="add-user-modal"
      className={styles.modal}
    >
      <Paper className={`${styles.modalContainer} scrollbar-hidden`}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeViewRedeemedVoucherModal())} />
          <div>
            <h3>Redeemed voucher users</h3>
            {redeemedVouchers.length === 0 && <p>No redeemed user.</p>}
            {redeemedVouchers.map((voucher) =>
              <div style={{ display: 'flex', flexDirection: 'column', border: 'solid 1px #cdcdcd', padding: '10px' }}>
                <p style={{ margin: 5 }}>Email: {voucher.user.userName}</p>
                <p style={{ margin: 5 }}>Name: {`${voucher.user.firstName} ${voucher.user.lastName}`}</p>
              </div>
            )}
          </div>
        </div>
      </Paper>
    </Modal>
  );
}
