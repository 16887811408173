import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Paper, } from "@mui/material";
import { Close } from "@mui/icons-material";
import styles from './Wallet.module.css';
import { getAllWalletPayments, getAllWalletTransactions } from '../../api/walletAPI';
import FlatList from 'flatlist-react/lib';
import moment from 'moment';
import { centToDollar } from '../../app/utils';

export function WalletTransactions(props) {
  const { walletId, closeModal, user } = props;
  const [init, setInit] = useState(false);
  const [hasMoreTransactions, setHasMoreTransactions] = useState(true);
  const [hasMorePendingPayments, setHasMorePendingPayments] = useState(true);
  const [transactionPage, setTransactionPage] = useState(0);
  const [pendingPaymentPage, setPendingPaymentPage] = useState(0);
  const [size] = useState(20);
  const [transactions, setTransactions] = useState([]);
  const [pendingPayments, setPendingPayments] = useState([]);

  const handleGetWalletTransactions = useCallback(async () => {
    const {content} = await getAllWalletTransactions(walletId, transactionPage, size);
    setTransactions(content);
  }, [size, transactionPage, walletId])

  const loadMoreWalletTransactions = async () => {
    const newPage = transactionPage + 1;
    setTransactionPage(newPage);
    const {content} = await getAllWalletTransactions(walletId, newPage, size);
    if (content.length > 0) {
      setTransactions([...transactions, ...content]);
    } else {
      setHasMoreTransactions(false);
    }
  }

  const handleGetPendingPayments = useCallback(async () => {
    const { content } = await getAllWalletPayments(walletId, pendingPaymentPage, size, "PENDING");
    setPendingPayments(content);
  }, [walletId, pendingPaymentPage, size]);

  const loadMoreWalletPendingPayments = async () => {
    const newPage = pendingPaymentPage + 1;
    setPendingPaymentPage(newPage);
    const {content} = await getAllWalletPayments(walletId, newPage, size, "PENDING");
    if (content.length > 0) {
      setPendingPayments([...pendingPayments, ...content]);
    } else {
      setHasMorePendingPayments(false);
    }
  }

  const renderTransaction = (transaction, idx) => {
    return (
        <div key={idx} className={styles.flatListRow}>
          <div className={styles.divNarrow}>{transaction.type}</div>
          <div>{moment(transaction.createdAt).format("MM/DD/YY, hh:mm a")}</div>
          <div>
            <span className={transaction.type === "TOPUP" ? styles.greenFont : null}>
              {transaction.type === "TOPUP" ? "+" + centToDollar(transaction.topUp.topUpAmount) : "-" + centToDollar(transaction.payment.finalPaymentAmount)}
            </span>
          </div>
          <div>
            {transaction.type === "TOPUP" ? transaction.topUp.method : "WALLET"}
          </div>
          <div>{transaction.type === "PAYMENT" ? transaction.payment.status : "SUCCESS" }</div>
        </div>
    );
  }  
  
  const renderPendingPayment = (pendingPayment, idx) => {
    return (
        <div key={idx} className={styles.flatListRow}>
          <div className={styles.divNarrow}>PAYMENT</div>
          <div>{moment(pendingPayment.createdAt).format("MM/DD/YY, hh:mm a")}</div>
          <div>
            {"-" + centToDollar(pendingPayment.initialRequestedAmount)}
          </div>
        </div>
    );
  }  

  useEffect(() => {
    if (!init) {
      handleGetWalletTransactions();
      handleGetPendingPayments();
      setInit(true);
    }
  }, [init, handleGetPendingPayments, handleGetWalletTransactions]);

  return (
    <Modal
      open
      onClose={closeModal}
      aria-labelledby="wallet-transaction-modal"
      className={styles.modal}
    >
      <Paper className={`${styles.modalContainer} scrollbar-hidden`}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={closeModal}/>
          <h3 className={styles.subtitle}>Wallet Transactions</h3>
          {
            user &&
            <div>
              <p>Email: {user.userName}</p>
            </div>
          }
          <FlatList
            list={transactions}
            renderItem={renderTransaction}
            renderWhenEmpty={() => <div>No Transaction</div>}
            hasMoreItems={hasMoreTransactions}
            loadMoreItems={loadMoreWalletTransactions}
          />
          <h3 className={styles.subtitle} style={{marginTop: 40}}>Wallet Pending Payments</h3>
          <FlatList
            list={pendingPayments}
            renderItem={renderPendingPayment}
            renderWhenEmpty={() => <div>No Pending Payments</div>}
            hasMoreItems={hasMorePendingPayments}
            loadMoreItems={loadMoreWalletPendingPayments}
          />
        </div>
      </Paper>
    </Modal>
  );
}
