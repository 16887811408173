import { Toolbar, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useDispatch } from "react-redux";
import { setActionUrl, setActionMethod, showAllocateModal, showEditModal, showCreateAppointmentModal, showTerminateModal } from "./rentingSlice";
import { ActionablesDto, ActionablesTitle } from "../../../app/constants";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: 2,
        paddingRight: 1,
    },
    title: {
        flex: '1 1 100%',
    },
    toolTipContainer: {
        position: "absolute",
        right: 10
    }
}));

export const TableToolbar = (props) => {
    const dispatch = useDispatch();
    const classes = useToolbarStyles();
    const { numSelected, title, actionables = [] } = props;

    const allocateAction = actionables.filter((actionable) => actionable.dto === ActionablesDto.AllocateRentingRequest && actionable.title === ActionablesTitle.Allocate)[0];
    const terminateAction = actionables.filter((actionable) => actionable.title === ActionablesTitle.Cancel)[0];

    return (
        <Toolbar>
            {numSelected > 0 ? (
                <div className="flex w-full flex-row justify-between items-center">
                    <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                        {numSelected} selected
                    </Typography>
                    <div className="flex flex-row items-center gap-2">
                        <button className="text-primaryblue text-sm font-medium hover:bg-slate-100 rounded px-4 py-2" onClick={() => dispatch(showEditModal(true))}>Edit</button>
                        {allocateAction &&(
                            <button
                                className="text-primaryblue text-sm font-medium hover:bg-slate-100 rounded px-4 py-2"
                                onClick={() => {
                                    dispatch(showAllocateModal(true));
                                    dispatch(setActionUrl(allocateAction.url));
                                    dispatch(setActionMethod(allocateAction.type));
                                }}
                            >
                                Allocate
                            </button>
                        )}
                        {terminateAction && (
                            <button
                                className="text-primaryblue text-sm font-medium hover:bg-slate-100 rounded px-4 py-2"
                                onClick={() => {
                                    dispatch(showTerminateModal(true));
                                    dispatch(setActionUrl(terminateAction.url));
                                    dispatch(setActionMethod(terminateAction.type));
                                }}
                            >
                                Terminate
                            </button>
                        )}
                        <button className="text-primaryblue text-sm font-medium hover:bg-slate-100 rounded px-4 py-2" onClick={() => dispatch(showCreateAppointmentModal(true))}>Appointment</button>
                    </div>
                </div>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    {title}
                </Typography>
            )}
        </Toolbar>
    );
};