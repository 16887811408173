import axiosApiInstance from "../../../app/axios";
import { PARKIT_API_BASE_URL } from "../../../app/utils";
import { getJwtToken } from "../../../app/common";

export const getAllAffiliateCampaigns = async (page, size) =>
  axiosApiInstance.get(
    `${PARKIT_API_BASE_URL}/parkit/v2/affiliate-campaigns?&page=${page}&size=${size}`,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const createAffiliateCampaign = async (data) =>
  axiosApiInstance.post(
    `${PARKIT_API_BASE_URL}/parkit/v2/affiliate-campaigns`,
    data,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const updateAffiliateCampaign = async (id, data) =>
  axiosApiInstance.put(
    `${PARKIT_API_BASE_URL}/parkit/v2/affiliate-campaigns/${id}`,
    data,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const generateAffiliateCampaignLinks = async (id, parkingAreaIds) =>
  axiosApiInstance.post(
    `${PARKIT_API_BASE_URL}/parkit/v2/affiliate-campaigns/generateLinks/${id}`,
    parkingAreaIds,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );
