import { useDispatch, useSelector } from "react-redux";
import { Modal, Paper, Grid } from "@mui/material";
import { Close } from "@mui/icons-material";

import {
  handleActivateRenting,
  selectState,
  showActivateModal,
} from "./trackingSheetSlice";

const ActivateRentingModal = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();

  const submit = async () => {
    try {
      const payload = {
        startDate: state.activateStartDate,
      };
      dispatch(handleActivateRenting(payload));
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Modal
      open={true}
      aria-labelledby="activate-renting-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showActivateModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Activate Renting</p>
          <p className="mb-[30px] text-[16px]">
            Are you sure you want to activate this renting?
          </p>
          <Grid item xs={12} className="flex justify-center">
            <button
              onClick={submit}
              className="bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md"
            >
              Activate
            </button>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default ActivateRentingModal;
