import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade, Button } from "@mui/material";
import { closeVendorDeliveryRateModal, handleGetVendors } from './vendorSlice';
import { Close } from "@mui/icons-material";
import styles from './Vendor.module.css';
import "react-datepicker/dist/react-datepicker.css";
import { assignDeliveryRateProfile } from '../delivery_rate/deliveryRateAPI';

export function VendorDeliveryRate(props) {
  const { vendor, deliveryRates } = props;
  const dispatch = useDispatch();
  const [vendorDeliveryRateState, _setVendorDeliveryRateState] = useStateWithCallbackLazy({
    vendor: {
      ...vendor
    },
    deliveryRates: deliveryRates,
    selectedDeliveryRate: vendor.deliveryRateProfile ? vendor.deliveryRateProfile.id : "",
    error: false,
    init: false,
    errorMessage: ""
  });

  const vendorDeliveryRateStateRef = useRef(vendorDeliveryRateState);

  const setVendorDeliveryRateState = data => {
    vendorDeliveryRateStateRef.current = data;
    _setVendorDeliveryRateState(data);
  };

  useEffect(() => {
    if (vendor && deliveryRates && !vendorDeliveryRateState.init) {
      setVendorDeliveryRateState({
        ...vendorDeliveryRateState,
        init: true,
        vendor: {
          ...vendor
        },
        deliveryRates: deliveryRates,
        selectedDeliveryRate: vendor.deliveryRateProfile ? vendor.deliveryRateProfile.id : "",
      });
    }
  });

  const onDeliveryRateChange = (data) => {
    setVendorDeliveryRateState({
      ...vendorDeliveryRateState,
      selectedDeliveryRate: data,
    })
  }

  const submit = async (props) => {
    let error = false;
    if (vendorDeliveryRateState.selectedDeliveryRate === "") {
      setVendorDeliveryRateState({...vendorDeliveryRateState, error: true, errorMessage: "Please select the delivery rate"});
      return;
    }
    
    if (!error) {
      try {
        await assignDeliveryRateProfile(vendorDeliveryRateState.selectedDeliveryRate, vendorDeliveryRateState.vendor.id);
        dispatch(closeVendorDeliveryRateModal());
        dispatch(handleGetVendors());
      } catch (error) {
        setVendorDeliveryRateState({...vendorDeliveryRateState, error: true, errorMessage: error.message});
      }
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="add-order-group-modal"
      className={styles.modal}
    >
      <Paper className={`${styles.modalContainer} scrollbar-hidden`}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeVendorDeliveryRateModal())}/>
          <h3 className={styles.subtitle}>Vendor's Delivery Rate <em style={{fontSize: 12}}></em></h3>
          <Fade in={vendorDeliveryRateState.error}>
            <p className={styles.errorMessage}>{vendorDeliveryRateState.errorMessage}</p>
          </Fade>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <p className={styles.textInputLabel}>Delivery Rate</p>
              <Paper className={styles.textInputContainer}>
                <select name="deliveryRate" id="deliveryRate" className={styles.selectInput} onChange={e => onDeliveryRateChange(e.target.value)}>
                  {
                    vendorDeliveryRateState.deliveryRates && vendorDeliveryRateState.deliveryRates.length > 0 && vendorDeliveryRateState.deliveryRates.map(rate =>
                      <option value={rate.id} selected={vendorDeliveryRateState.selectedDeliveryRate === rate.id ? true : false}>{rate.name}</option>
                    )
                  }
                </select>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={() => submit(props)} className={styles.gradientButton}>Set Delivery Rate</Button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}
