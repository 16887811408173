import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getOrdersByOrderGroup } from './orderAPI';

export const orderSlice = createSlice({
  name: 'order',
  initialState: {
    orderGroup: null,
    status: 'idle',
    editOrderModalOpen: false,
    deleteOrderModalOpen: false,
    cancelOrderModalOpen: false,
    selectedId: null
  },
  reducers: {
    openEditOrderModal: (state) => {
      state.editOrderModalOpen = true;
    },
    closeEditOrderModal: (state) => {
      state.editOrderModalOpen = false;
    },
    openDeleteOrderModal: (state) => {
      state.deleteOrderModalOpen = true;
    },
    closeDeleteOrderModal: (state) => {
      state.deleteOrderModalOpen = false;
      state.selectedId = null;
    },
    openCancelOrderModal: (state) => {
      state.cancelOrderModalOpen = true;
    },
    closeCancelOrderModal: (state) => {
      state.cancelOrderModalOpen = false;
      state.selectedId = null;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    getOrders: (state, action) => {
      state.orderGroup = {
        ...action.payload,
        pickUpDateTime: (action.payload.pickUpDateTime ? new Date(action.payload.pickUpDateTime).toString() : action.payload.pickUpDateTime),
        orders: [
          ...action.payload.orders.map(o => o.deliverDateTime ? {...o, deliverDateTime: new Date(o.deliverDateTime).toString()} : o)
        ]
      };
      state.status = 'success';
    },
  },
});

export const { openEditOrderModal, closeEditOrderModal, openDeleteOrderModal, closeDeleteOrderModal, openCancelOrderModal, closeCancelOrderModal, setSelectedId, getOrders } = orderSlice.actions;

export const handleGetOrdersByOrderGroup = (id) => async (dispatch, getState) => {
  dispatch(showLoading());
  try {
    const response = await getOrdersByOrderGroup(id);
    dispatch(getOrders(response.data));
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(hideLoading());
  }
};

export const selectState = state => state.order;

export default orderSlice.reducer;
