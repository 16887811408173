import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Paper } from "@mui/material";
import { Close } from "@mui/icons-material";

import { handleDeleteParkingArea, showDeleteModal } from './parkingAreaSlice';

const DeleteParkingAreaModal = () => {
  const dispatch = useDispatch();

  const submit = async () => {
    try {
      dispatch(handleDeleteParkingArea());
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="delete-parking-area-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[500px] max-h-[80%] overflow-scroll p-[20px]">
        <Close className="float-right cursor-pointer" onClick={() => dispatch(showDeleteModal(false))} />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <div className="text-black text-[22px] text-center font-medium">Delete Parking Area</div>
          <div className="mt-[5px] text-black text-[16px] text-center">Are you sure you want to delete this parking area?</div>

          <div className="flex flex-row flex-wrap justify-center items-center mt-[20px]">
            <button className="flex bg-[#3085D6] w-fit justify-center mx-[5px] px-[20px] py-[8px] text-white text-[14px] font-medium rounded-[5px]" onClick={submit}>Yes</button>
            <button className="flex bg-[#E86C6D] w-fit justify-center mx-[5px] px-[20px] py-[8px] text-white text-[14px] font-medium rounded-[5px]" onClick={() => dispatch(showDeleteModal(false))}>No</button>
          </div>
        </div>
      </Paper>
    </Modal>
  );
}

export default DeleteParkingAreaModal;