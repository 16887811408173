import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getUser } from '../user/userAPI';
import * as Api from "./voucherAPI";

export const voucherSlice = createSlice({
  name: 'voucher',
  initialState: {
    vouchers: [],
    status: 'idle',
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null,
    createVoucherModalOpen: false,
    viewRedeemedVoucherModalOpen: false,
    deactivateVoucherModalOpen: false,
    activateVoucherModalOpen: false,
  },
  reducers: {
    openCreateVoucherModal: (state) => {
      state.createVoucherModalOpen = true;
    },
    closeCreateVoucherModal: (state) => {
      state.createVoucherModalOpen = false;
    },
    openViewRedeemedVoucherModal: (state) => {
      state.viewRedeemedVoucherModalOpen = true;
    },
    closeViewRedeemedVoucherModal: (state) => {
      state.viewRedeemedVoucherModalOpen = false;
    },
    openDeactivateVoucherModal: (state) => {
      state.deactivateVoucherModalOpen = true;
    },
    closeDeactivateVoucherModal: (state) => {
      state.deactivateVoucherModalOpen = false;
    },
    openActivateVoucherModal: (state) => {
      state.activateVoucherModalOpen = true;
    },
    closeActivateVoucherModal: (state) => {
      state.activateVoucherModalOpen = false;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    getVouchers: (state, action) => {
      state.vouchers = action.payload;
      state.status = 'success'
    },
  },
});

export const { openCreateVoucherModal, closeCreateVoucherModal, openViewRedeemedVoucherModal, closeViewRedeemedVoucherModal, openDeactivateVoucherModal, closeDeactivateVoucherModal, openActivateVoucherModal, closeActivateVoucherModal, setSelectedId, setPage, setRowsPerPage, setPagination, getVouchers } = voucherSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.voucher.value)`
export const selectState = state => state.voucher;

const handleAddWhiteListUsersToVouchers = async (vouchers) => {
  return await Promise.all(
    vouchers.map(async (voucher) => {
      if (voucher.whiteList && voucher.whiteList.length > 0) {
        const whiteListUsers = await Promise.all(
          voucher.whiteList.map(async (userId) => {
            try {
              const user = await getUser(userId);
              return { ...user };
            } catch {
              return { id: userId, userName: '' };
            }
          })
        );
        return { ...voucher, whiteList: whiteListUsers };
      }
      return { ...voucher }
    })
  );
}

export const handleGetVouchers = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const res = await Api.getVouchers(state.voucher.page, state.voucher.rowsPerPage);
    if (res.error) {
      throw res.error;
    };
    
    const { content, ...pagination } = res;
    let vouchers = content;

    dispatch(setPagination(pagination));
    const vouchersWithWhiteListUsers = await handleAddWhiteListUsersToVouchers(vouchers);
    dispatch(getVouchers(vouchersWithWhiteListUsers));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};


export default voucherSlice.reducer;
