import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Paper, Grid, TextField, Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import useToasts from "../../../hooks/useToasts";
import moment from 'moment';

import {
  showUploadPayoutModal,
  handleUploadPayoutFile
} from "./payoutSlice";

const UploadPayoutModal = () => {
  const dispatch = useDispatch();
  const { toastError } = useToasts();

  const [passCode, setPassCode] = useState("");
  const [yearMonth, setYearMonth] = useState(moment().format('YYYY-MM'));
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const validateFields = () => {
    if (!passCode || !yearMonth || !selectedFile) {
      toastError("Please fill in all required fields.");
      return false;
    }
    return true;
  };

  const submit = async () => {
    if (validateFields()) {
      try {
        dispatch(handleUploadPayoutFile(passCode, yearMonth, selectedFile));
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  return (
    <Modal
      open={true}
      aria-labelledby="upload-payout-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showUploadPayoutModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Upload Payout File</p>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="passCode"
                label="Passcode"
                InputLabelProps={{ shrink: true }}
                onChange={e => setPassCode(e.target.value)}
                value={passCode}
                margin="normal"
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="yearMonth"
                label="Month"
                InputLabelProps={{ shrink: true }}
                onChange={e => setYearMonth(e.target.value)}
                value={yearMonth}
                margin="normal"
                variant="outlined"
                type="month"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <input
                accept=".csv"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                id="raised-button-file"
                type="file"
              />
              <label htmlFor="raised-button-file">
                  <Button variant="contained" color="inherit" component="span">
                      Choose A File
                  </Button>
                  <span style={{fontSize: 10, marginLeft: 20}}>{selectedFile ? selectedFile.name : "No file selected"}</span>
              </label>
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <button
                onClick={submit}
                className="bg-[#DE4D65] mt-[60px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md"
              >
                Upload
              </button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default UploadPayoutModal;
