import { Toolbar, Typography, Tooltip, IconButton } from "@mui/material";
import { makeStyles } from '@mui/styles';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EditIcon from '@mui/icons-material/Edit';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: 2,
      paddingRight: 1,
    },
    title: {
      flex: '1 1 100%',
    },
    toolTipContainer: {
        position: "absolute",
        right: 10
    }
}));

export const TableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, vendorDeliveryRate, EditVendor } = props;
    return (
        <Toolbar>
            { numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Vendors
                </Typography>
            )}

            { numSelected > 0 && (
                <div className={classes.toolTipContainer}>
                    <Tooltip title="User Delivery Rate" onClick={vendorDeliveryRate}>
                        <IconButton aria-label="user delivery rate">
                            <AttachMoneyIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Update Vendor" onClick={EditVendor}>
                        <IconButton aria-label="update vendor">
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )}
        </Toolbar>
    );
};