import { API_BASE_URL } from "../app/utils";
import { getJwtToken } from "../app/common";

export const getWallets = (page, size) =>
  fetch(`${API_BASE_URL}/wallet/v1/wallets?page=${page}&size=${size}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const getUserWallet = (userId) =>
  fetch(`${API_BASE_URL}/wallet/v1/wallets?userId=${userId}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const topUpWallet = (walletId, data) =>
  fetch(`${API_BASE_URL}/wallet/v1/wallets/${walletId}/top-up`, {
    crossDomain: true,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    },
    body: JSON.stringify(data)
  }).then(res => res.json());

export const getAllWalletTransactions = (walletId, page, size) =>
  fetch(`${API_BASE_URL}/wallet/v1/wallets/${walletId}/transactions?page=${page}&size=${size}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    },
  }).then(res => res.json());

export const getAllWalletPayments = async (walletId, page, size, status) =>
  fetch(`${API_BASE_URL}/wallet/v1/wallets/${walletId}/payments?page=${page}&size=${size}&status=${status}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    },
  }).then(res => res.json());

export const updateWallet = async (walletId, data) =>
  fetch(`${API_BASE_URL}/wallet/v1/wallets/${walletId}`, {
    crossDomain: true,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    },
    body: JSON.stringify(data)
  }).then(res => res.json());