import { useKeycloak } from "keycloak-react-web";

const PrivateRoute = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();

  if (initialized) {
    const isLoggedIn = keycloak.authenticated;
    return isLoggedIn ? children : null;
  }
};

export default PrivateRoute;