import axiosApiInstance from "../app/axios";
import { PARKIT_API_BASE_URL } from "../app/utils";
import { getJwtToken } from "../app/common";

export const getAllAppointments = async (page, size, sort, sortBy) =>
  axiosApiInstance.get(
    `${PARKIT_API_BASE_URL}/parkit/v2/appointments?&page=${page}&size=${size}&sort=${sort}&sortBy=${sortBy}`,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const getAppointmentById = async (id) =>
  axiosApiInstance.get(
    `${PARKIT_API_BASE_URL}/parkit/v2/appointments/${id}`,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const createAppointment = async (data) =>
  axiosApiInstance.post(
    `${PARKIT_API_BASE_URL}/parkit/v2/appointments`,
    data,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const deleteAppointment = async (id) =>
  axiosApiInstance.delete(
    `${PARKIT_API_BASE_URL}/parkit/v2/appointments/${id}`,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );