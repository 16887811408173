import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { createAffiliateCampaign, updateAffiliateCampaign, getAllAffiliateCampaigns, generateAffiliateCampaignLinks } from './affiliateCampaignAPI';
import { saveAs } from 'file-saver';

export const affiliateCampaignSlice = createSlice({
  name: 'affiliateCampaign',
  initialState: {
    affiliateCampaigns: [],
    status: 'idle',
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null,
    selectedCampaign: null,
    addModalOpen: false,
    editModalOpen: false,
    viewPromoCodeModalOpen: false,
    generateAffiliateLinkModalOpen: false,
    successMessage: "",
    errorMessage: ""
  },
  reducers: {
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setSelectedCampaign: (state, action) => {
      state.selectedCampaign = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setAffiliateCampaigns: (state, action) => {
      state.affiliateCampaigns = action.payload;
      state.status = 'success';
    },
    showAddModal: (state, action) => {
      state.addModalOpen = action.payload;
    },
    showEditModal: (state, action) => {
      state.editModalOpen = action.payload;
    },
    showViewPromoCodeModal: (state, action) => {
      state.viewPromoCodeModalOpen = action.payload;
    },
    showGenerateAffiliateLinkModal: (state, action) => {
      state.generateAffiliateLinkModalOpen = action.payload;
    },
    viewReferrals: (state, action) => {
      window.location.href = 'affiliate-campaigns/' + state.selectedId;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    }
  }
});

export const { setSelectedId, setSelectedCampaign, setPage, setRowsPerPage, setPagination, setAffiliateCampaigns, showAddModal, showEditModal, showViewPromoCodeModal, showGenerateAffiliateLinkModal, viewReferrals, setSuccessMessage, setErrorMessage } = affiliateCampaignSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.vendor.value)`
export const selectState = state => state.affiliateCampaign;

export const handleGetAffiliateCampaigns = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const response = await getAllAffiliateCampaigns(state.affiliateCampaign.page, state.affiliateCampaign.rowsPerPage);
    const { content, ...pagination } = response.data;

    dispatch(setPagination(pagination));
    dispatch(setAffiliateCampaigns(content));
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleAddAffiliateCampaign = (affiliateCampaign) => async (dispatch, getState) => {
  dispatch(showLoading());

  try {
    await createAffiliateCampaign(affiliateCampaign);
    dispatch(showAddModal(false));
    dispatch(setSuccessMessage("Successfully added affiliate campaign!"));
    dispatch(handleGetAffiliateCampaigns());
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleUpdateAffiliateCampaign = (affiliateCampaign) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await updateAffiliateCampaign(state.affiliateCampaign.selectedId, affiliateCampaign);
    dispatch(showEditModal(false));
    dispatch(setSuccessMessage("Successfully updated affiliate campaign!"));
    dispatch(handleGetAffiliateCampaigns());
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleGenerateLinks = () => async (dispatch, getState) => {
  const state = getState();

  if (state.affiliateCampaign.selectedCampaign.specificParkingArea) {
    dispatch(showGenerateAffiliateLinkModal(true));
  } else {
    dispatch(downloadAffiliateLinks([]));
  }
};

export const downloadAffiliateLinks = (parkingAreaIds) => async (dispatch, getState) => {
  const state = getState();
  dispatch(showLoading());
  try {
    const response = await generateAffiliateCampaignLinks(state.affiliateCampaign.selectedId, parkingAreaIds);
    const blob = new Blob([response.data], { type: "text/csv;charset=utf-8" })
    saveAs(blob, `${state.affiliateCampaign.selectedId}-affiliate-links.csv`);
    dispatch(setSuccessMessage("Affiliate Campaign Link Generated!"));
  } catch (error) {
     dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
}

export default affiliateCampaignSlice.reducer;