import { createSlice } from '@reduxjs/toolkit';

export const homeSlice = createSlice({
  name: 'home',
  initialState: {
    status: 'idle',
  },
  reducers: {
    home: state => {
      console.log(state);
    },
  },
});

export const { home } = homeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.home.value)`
export const selectState = state => state.home;

export default homeSlice.reducer;
