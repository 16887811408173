import axiosApiInstance from "../../../app/axios";
import { API_BASE_URL, } from "../../../app/utils";
import { getJwtToken } from "../../../app/common";

export const getDeliveryRateProfiles = async (page, size) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/deliverit/v1/delivery-rate-profiles?page=${page}&size=${size}`,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const createDeliveryRateProfile = async (data) =>
  axiosApiInstance.post(
    `${API_BASE_URL}/deliverit/v1/delivery-rate-profiles`,
    data,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const updateDeliveryRateProfile = async (data, id) =>
  axiosApiInstance.put(
    `${API_BASE_URL}/deliverit/v1/delivery-rate-profiles/${id}`,
    data,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const deleteDeliveryRateProfile = async (id) =>
  axiosApiInstance.delete(
    `${API_BASE_URL}/deliverit/v1/delivery-rate-profiles/${id}`,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const assignDeliveryRateProfile = async (deliveryRateId, vendorId) =>
  axiosApiInstance.post(
    `${API_BASE_URL}/deliverit/v1/delivery-rate-profiles/${deliveryRateId}/vendors/${vendorId}`,
    {},
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );