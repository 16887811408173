import { createSlice } from "@reduxjs/toolkit";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import {
  deleteBulkListingEnquiry,
  deleteBulkRentingEnquiry,
  getBulkListingEnquiries,
  getBulkRentingEnquiries,
} from "../../../api/enquiriesAPI";

export const bulkEnquiriesSlice = createSlice({
  name: "bulkEnquiries",
  initialState: {
    listings: [],
    rentings: [],
    listingPagination: {},
    listingPage: 0,
    listingRowsPerPage: 10,
    listingSort: 'desc',
    listingSortBy: 'createdAt',
    rentingPagination: {},
    rentingPage: 0,
    rentingRowsPerPage: 10,
    rentingSort: 'desc',
    rentingSortBy: 'createdAt',
    selectedListingId: null,
    selectedRentingId: null,
    deleteListingEnquiryModalOpen: false,
    deleteRentingEnquiryModalOpen: false,
    successMessage: "",
    errorMessage: "",
  },
  reducers: {
    setListingPage: (state, action) => {
      state.listingPage = action.payload;
    },
    setListingRowsPerPage: (state, action) => {
      state.listingRowsPerPage = action.payload;
    },
    setListingPagination: (state, action) => {
      state.listingPagination = action.payload;
    },
    setListingSort: (state, action) => {
      state.listingSort = action.payload;
    },
    setListingSortBy: (state, action) => {
      state.listingSortBy = action.payload;
    },
    setRentingPage: (state, action) => {
      state.rentingPage = action.payload;
    },
    setRentingRowsPerPage: (state, action) => {
      state.rentingRowsPerPage = action.payload;
    },
    setRentingPagination: (state, action) => {
      state.rentingPagination = action.payload;
    },
    setRentingSort: (state, action) => {
      state.rentingSort = action.payload;
    },
    setRentingSortBy: (state, action) => {
      state.rentingSortBy = action.payload;
    },
    setListings: (state, action) => {
      state.listings = action.payload;
      state.status = "success";
    },
    setRentings: (state, action) => {
      state.rentings = action.payload;
      state.status = "success";
    },
    setSelectedListingId: (state, action) => {
      state.selectedListingId = action.payload;
    },
    setSelectedRentingId: (state, action) => {
      state.selectedRentingId = action.payload;
    },
    showDeleteListingEnquiryModal: (state, action) => {
      state.deleteListingEnquiryModalOpen = action.payload;
    },
    showDeleteRentingEnquiryModal: (state, action) => {
      state.deleteRentingEnquiryModalOpen = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  },
});

export const {
  setListingPage,
  setListingRowsPerPage,
  setListingPagination,
  setListingSort,
  setListingSortBy,
  setRentingPage,
  setRentingRowsPerPage,
  setRentingPagination,
  setRentingSort,
  setRentingSortBy,
  setListings,
  setRentings,
  setSelectedListingId,
  setSelectedRentingId,
  showDeleteListingEnquiryModal,
  showDeleteRentingEnquiryModal,
  setSuccessMessage,
  setErrorMessage,
} = bulkEnquiriesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.vendor.value)`
export const selectState = (state) => state.bulkEnquiries;

export const handleGetBulkListingEnquiries =
  () => async (dispatch, getState) => {
    dispatch(showLoading());
    const state = getState();

    try {
      const response = await getBulkListingEnquiries(
        state.bulkEnquiries.listingPage,
        state.bulkEnquiries.listingRowsPerPage,
        state.bulkEnquiries.listingSort,
        state.bulkEnquiries.listingSortBy
      );
      const { content, ...pagination } = response.data;
      dispatch(setListingPagination(pagination));
      dispatch(setListings(content));
    } catch (error) {
      dispatch(setErrorMessage(error.message));
    } finally {
      dispatch(hideLoading());
    }
  };

export const handleGetBulkRentingEnquiries =
  () => async (dispatch, getState) => {
    dispatch(showLoading());
    const state = getState();

    try {
      const response = await getBulkRentingEnquiries(
        state.bulkEnquiries.rentingPage,
        state.bulkEnquiries.rentingRowsPerPage,
        state.bulkEnquiries.rentingSort,
        state.bulkEnquiries.rentingSortBy
      );
      const { content, ...pagination } = response.data;
      dispatch(setRentingPagination(pagination));
      dispatch(setRentings(content));
    } catch (error) {
      dispatch(setErrorMessage(error.message));
    } finally {
      dispatch(hideLoading());
    }
  };

export const handleDeleteListingEnquiry = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await deleteBulkListingEnquiry(state.bulkEnquiries.selectedListingId);
    dispatch(showDeleteListingEnquiryModal(false));
    dispatch(setSuccessMessage("Successfully deleted bulk listing enquiry!"));
    dispatch(handleGetBulkListingEnquiries());
    dispatch(setSelectedListingId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleDeleteRentingEnquiry = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await deleteBulkRentingEnquiry(state.bulkEnquiries.selectedRentingId);
    dispatch(showDeleteRentingEnquiryModal(false));
    dispatch(setSuccessMessage("Successfully deleted bulk renting enquiry!"));
    dispatch(handleGetBulkRentingEnquiries());
    dispatch(setSelectedRentingId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};
export default bulkEnquiriesSlice.reducer;
