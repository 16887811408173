import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Checkbox, TablePagination, TableFooter } from "@mui/material";
import { TableToolbar } from './TableToolbar';
import { handleGetVouchers, selectState, setSelectedId, setPage, setRowsPerPage, openCreateVoucherModal, openDeactivateVoucherModal, openActivateVoucherModal, openViewRedeemedVoucherModal } from './voucherSlice';
import TablePaginationActions from './TablePaginationActions';
import styles from './Voucher.module.css';
import { centToDollar } from '../../app/utils';
import { CreateVoucher } from './CreateVoucher';
import { DeactivateVoucher } from './DeactivateVoucher';
import { ActivateVoucher } from './ActivateVoucher';
import { ViewRedeemedVoucher } from './ViewRedeemedVoucher';
import { StickyTableHead } from '../../helpers/GeneralComponent';

export function Voucher(props) {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetVouchers());
    }
  });

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetVouchers());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetVouchers());
  };

  return (
    <div className={styles.container}>
      <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
        <div className="h-[64px] sticky left-0 top-0 z-[1] right-0 bg-white">
          <div className="h-[64px] absolute left-0 top-0 right-0">
            <TableToolbar 
              numSelected={state.selectedId === null ? 0 : 1}
              selectedVoucher={state.selectedId ? state.vouchers.find(voucher => voucher.id === state.selectedId) : null}
              createVoucher={() => dispatch(openCreateVoucherModal())}
              viewRedeemedVoucher={() => dispatch(openViewRedeemedVoucherModal())}
              deactivateVoucher={() => dispatch(openDeactivateVoucherModal())}
              activateVoucher={() => dispatch(openActivateVoucherModal())}
            />
          </div>
        </div>
        <Table size="small">
          <StickyTableHead>
            <TableRow>
              <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
              <TableCell align="left" className={styles.tableHeaderNarrow}>ID</TableCell>
              <TableCell align="left" className={styles.tableHeaderNarrow}>Code</TableCell>
              <TableCell align="left" className={styles.tableHeaderNarrow}>Title</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Discount Value</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Redeem Limit</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Expiry Date</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Redeem Limit Per User</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Redeemed Count</TableCell>
              <TableCell align="left" className={styles.tableHeader}>Status</TableCell>
              <TableCell align="left" className={styles.tableHeader}>White List</TableCell>
            </TableRow>
          </StickyTableHead>
          <TableBody>
            {
              state.vouchers.length > 0 ? (
                state.vouchers.map((voucher) => (
                  <TableRow 
                    key={voucher.id}
                    hover
                    onClick={() => dispatch(setSelectedId(state.selectedId === voucher.id ? null : voucher.id))}
                    role="checkbox"
                    aria-checked={state.selectedId === voucher.id ? true : false}
                    selected={state.selectedId === voucher.id ? true : false}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox 
                        color="primary"
                        checked={state.selectedId === voucher.id ? true : false}
                      />
                    </TableCell>
                    <TableCell align="left" className={styles.tableCellNarrow}>{voucher.id}</TableCell>
                    <TableCell align="left">{voucher.code}</TableCell>
                    <TableCell align="left">{voucher.title}</TableCell>
                    <TableCell align="left">{voucher.discountAmount ? `RM ${centToDollar(voucher.discountAmount)}` : `${voucher.discountPercentage ?? "N/A"}%`}</TableCell>
                    <TableCell align="left">{voucher.redeemLimit ?? "No limit"}</TableCell>
                    <TableCell align="left">{voucher.expiryDate ?? "No expiry"}</TableCell>
                    <TableCell align="left">{voucher.perUserRedeemLimit ?? "No limit per user"}</TableCell>
                    <TableCell align="left">{voucher.redeemCount ?? 0}</TableCell>
                    <TableCell align="left">{voucher.active ? "Active" : "Inactive"}</TableCell>
                    <TableCell align="left">{voucher.whiteList && voucher.whiteList?.map(u => u.userName).join(', ')}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={11} align="center">
                    <p>No Vouchers</p>
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                colSpan={11}
                count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                rowsPerPage={state.rowsPerPage}
                page={state.page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {
        state.createVoucherModalOpen &&
        <CreateVoucher />
      }
      {
        state.viewRedeemedVoucherModalOpen &&
        <ViewRedeemedVoucher code={state.selectedId ? state.vouchers.find(voucher => voucher.id === state.selectedId).code : null} />
      }
      {
        state.deactivateVoucherModalOpen &&
        <DeactivateVoucher voucher={state.selectedId ? state.vouchers.find(voucher => voucher.id === state.selectedId) : null} />
      }
      {
        state.activateVoucherModalOpen &&
        <ActivateVoucher voucher={state.selectedId ? state.vouchers.find(voucher => voucher.id === state.selectedId) : null} />
      }
    </div>
  );
}
