import React from 'react';
import styles from './Home.module.css';

export function Home() {
  return (
    <div className={styles.container}>
      <h3 className={styles.subtitle}>Coming Soon</h3>
    </div>
  );
}
