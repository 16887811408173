import axiosApiInstance from "../app/axios";
import { PARKIT_API_BASE_URL } from "../app/utils";
import { getJwtToken } from "../app/common";

export const getRentings = async (page, size, sort, sortBy, status, search, searchType, filter) =>
  axiosApiInstance.get(
    `${PARKIT_API_BASE_URL}/parkit/v2/rentings?page=${page}&size=${size}&sort=${sort}&sortBy=${sortBy}&status=${status}${search ? '&search=' + search + '&searchType=' + searchType : ''}${filter ? '&filter=' + filter : ''}`,
    { headers: { Authorization: `Bearer ${getJwtToken()}` } }
  );

export const getRentingById = async (id) =>
  axiosApiInstance.get(
    `${PARKIT_API_BASE_URL}/parkit/v2/rentings/${id}`,
    { headers: { Authorization: `Bearer ${getJwtToken()}` } }
  );

export const addRenting = async (data) =>
  axiosApiInstance.post(`${PARKIT_API_BASE_URL}/parkit/v2/rentings`, data, {
    headers: { Authorization: `Bearer ${getJwtToken()}` },
  });

export const updateRenting = async (id, data) =>
  axiosApiInstance.post(`${PARKIT_API_BASE_URL}/parkit/v2/rentings/${id}/patch`, data, {
    headers: { Authorization: `Bearer ${getJwtToken()}` },
  });

export const getEarliestTerminationDate = async (id) =>
  axiosApiInstance.get(
    `${PARKIT_API_BASE_URL}/parkit/v2/rentings/${id}/get-termination-date`,
    { headers: { Authorization: `Bearer ${getJwtToken()}` } }
  );