import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Paper,
  Grid,
  TextField,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useParams } from 'react-router-dom'
import { handleGetAffiliateCampaignReferrals, selectState, showMonthSelectionModal, setSelectedYearMonth } from './affiliateCampaignReferralSlice';

const MonthSelectionModal = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  const { id } = useParams();

  const submit = () => {
    try {
      dispatch(handleGetAffiliateCampaignReferrals(id));
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleYearMonthChange = (event) => {
    dispatch(setSelectedYearMonth(event.target.value));
  };

  return (
    <Modal
      open={true}
      aria-labelledby="month-selection-referral-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[20%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showMonthSelectionModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Affiliate Campaign Referral Records Month Selection</p>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <TextField
                id="yearMonth"
                label="Month"
                InputLabelProps={{ shrink: true }}
                onChange={handleYearMonthChange}
                value={state.selectedYearMonth}
                margin="normal"
                variant="outlined"
                type="month"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <button
                onClick={submit}
                className="bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md"
              >
                Filter
              </button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default MonthSelectionModal;