import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeAdminTopUpModal, handleGetUsers } from './userSlice';
import { Modal, Paper, Grid, Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import styles from './User.module.css';
import { getUserWallet, topUpWallet } from '../../api/walletAPI';

export function AdminTopUp(props) {
  const { selectedUserId } = props;
  const dispatch = useDispatch();
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [wallet, setWallet] = useState(null);
  const [topUpAmount, setTopUpAmount] = useState(0);

  useEffect(() => {
    const handleGetWalletByUserId = async () => {
      try {
        const walletData = await getUserWallet(selectedUserId);
        setWallet(walletData);
      } catch (error) {
        alert(error?.response?.data?.message || error.message);
      }
    }

    if (!init && selectedUserId) {
      handleGetWalletByUserId();
      setInit(true);
    }
  }, [init, selectedUserId]);

  const submit = async () => {
    setLoading(true);
    const topUpAmountInCent = Number((topUpAmount * 100).toFixed(0));
    try {
      let walletPayload = {
        method: "BANK_IN",
        topUpAmount: topUpAmountInCent,
        detail: `Admin Top Up RM${topUpAmount}`
      }

      await topUpWallet(wallet.id, walletPayload);
      dispatch(handleGetUsers());
      dispatch(closeAdminTopUpModal());
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="admin-topup-modal"
      className={styles.modal}
    >
      <Paper className={`${styles.modalContainer} scrollbar-hidden`}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeAdminTopUpModal())} />
          <h3 className={styles.subtitle}>Manual Top Up To User</h3>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <p>Top Up Amount</p>
              <Paper className={styles.textInputContainer}>
                <input
                  id="topUpAmount"
                  name="topUpAmount"
                  value={topUpAmount}
                  onChange={e => setTopUpAmount(e.target.value)}
                  placeholder="Amount"
                  type="number"
                   InputProps={{
                       onWheel: (e) => e.target.blur()
                   }}
                  className={styles.textInput}
                />
              </Paper>
              <div className={styles.submitButtonContainer}>
                <Button disabled={loading} onClick={() => submit()} className={styles.gradientButton} style={{ opacity: loading ? 0.5 : 1 }}>Submit</Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}