import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { selectState, setSelectedFilter, showFilterModal } from './ownerListingsSlice';

const FilterModal = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  
  const selectedFilter = state.selectedFilter;
  const [dedicatedSpace, setDedicatedSpace] = useState(selectedFilter.dedicatedSpace);
  const [buildingType, setBuildingType] = useState(selectedFilter.buildingType);
  const [tandem, setTandem] = useState(selectedFilter.tandem);
  const [leasePeriod, setLeasePeriod] = useState(selectedFilter.leasePeriod);
  const [currency, setCurrency] = useState(selectedFilter.currency);

  const submit = () => {
    try {
      dispatch(setSelectedFilter({
        dedicatedSpace,
        buildingType,
        tandem,
        leasePeriod,
        currency
      }));
      dispatch(showFilterModal(false));
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Modal
      open={true}
      aria-labelledby="filter-listing-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] h-[40%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showFilterModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Filter Listings</p>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormControl margin="normal" variant="outlined" fullWidth>
                <InputLabel htmlFor="building-type">Building Type</InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setBuildingType(e.target.value)}
                  value={buildingType}
                  input={<OutlinedInput name="buildingType" id="building-type" label="Building Type" />}
                >
                  <MenuItem key={"clearFilter"} value={null}>*** Clear Filter ***</MenuItem>
                  <MenuItem key="LANDED" value="LANDED">Landed</MenuItem>
                  <MenuItem key="APARTMENT" value="APARTMENT">Apartment</MenuItem>
                  <MenuItem key="COMMERCIAL" value="COMMERCIAL">Commercial</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl margin="normal" variant="outlined" fullWidth>
                <InputLabel htmlFor="dedicated-space">Dedicated Space</InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setDedicatedSpace(e.target.value)}
                  value={dedicatedSpace}
                  input={<OutlinedInput name="dedicatedSpace" id="dedicated-space" label="Dedicated Space" />}
                >
                  <MenuItem key={"clearFilter"} value={null}>*** Clear Filter ***</MenuItem>
                  <MenuItem key="RESERVED" value="true">RESERVED</MenuItem>
                  <MenuItem key="FLOATING" value="false">FLOATING</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl margin="normal" variant="outlined" fullWidth>
                <InputLabel htmlFor="tandem">Tandem space</InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setTandem(e.target.value)}
                  value={tandem}
                  input={<OutlinedInput name="tandem" id="tandem" label="Tandem Space" />}
                >
                  <MenuItem key={"clearFilter"} value={null}>*** Clear Filter ***</MenuItem>
                  <MenuItem key="YES" value="true">YES</MenuItem>
                  <MenuItem key="NO" value="false">NO</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="normal" variant="outlined" fullWidth>
                <InputLabel htmlFor="leasePeriod">Lease Period</InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setLeasePeriod(e.target.value)}
                  value={leasePeriod}
                  input={<OutlinedInput name="leasePeriod" id="leasePeriod" label="Lease Period" />}
                >
                  <MenuItem key={"clearFilter"} value={null}>*** Clear Filter ***</MenuItem>
                  <MenuItem key="MONTHLY" value="P1M">MONTHLY</MenuItem>
                  <MenuItem key="QUARTERLY" value="P3M">QUARTERLY</MenuItem>
                  <MenuItem key="HALF_YEARLY" value="P6M">HALF_YEARLY</MenuItem>
                  <MenuItem key="ANNUALLY" value="P12M">ANNUALLY</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="normal" variant="outlined" fullWidth>
                <InputLabel htmlFor="currency">Currency</InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setCurrency(e.target.value)}
                  value={currency}
                  input={<OutlinedInput name="currency" id="currency" label="Currency" />}
                >
                  <MenuItem key={"clearFilter"} value={null}>*** Clear Filter ***</MenuItem>
                  <MenuItem key="MYR" value="MYR">MYR</MenuItem>
                  <MenuItem key="SGD" value="SGD">SGD</MenuItem>
                  <MenuItem key="HKD" value="HKD">HKD</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} className="flex flex-grow justify-center">
              <button
                onClick={submit}
                className="bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md"
              >
                Filter
              </button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default FilterModal;