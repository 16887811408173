import { Button, IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import BlockIcon from '@mui/icons-material/Block';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Group from '@mui/icons-material/Group';

export const TableToolbar = (props) => {
  const { numSelected, createVoucher, viewRedeemedVoucher, deactivateVoucher, activateVoucher, selectedVoucher } = props;
  return (
    <Toolbar>
      {numSelected > 0 ? (
        <Typography color="inherit" variant="subtitle1" component="div" style={{ flex: 1 }}>
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <Typography variant="h6" id="tableTitle" component="div" style={{ flex: '1 1 100%' }}>
            Voucher List
          </Typography>
          <Button style={{ minWidth: 200 }} onClick={createVoucher}>Create Voucher</Button>
        </>
      )}

      {
        numSelected > 0 && selectedVoucher &&
        <>
          {
            selectedVoucher.active ? (
              <Tooltip title="Deactivate Voucher" onClick={deactivateVoucher}>
                <IconButton aria-label="deactivate">
                  <BlockIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Activate Voucher" onClick={activateVoucher}>
                <IconButton aria-label="activate">
                  <CheckCircle />
                </IconButton>
              </Tooltip>
            )
          }
          <Tooltip title="View Redeemed Users" onClick={viewRedeemedVoucher}>
            <IconButton aria-label="view redeemed users">
              <Group />
            </IconButton>
          </Tooltip>
        </>
      }
    </Toolbar>
  );
};