import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Checkbox, TablePagination, TableFooter } from "@mui/material";
import { VendorDeliveryRate } from './VendorDeliveryRate';
import { EditVendor } from './EditVendor';
import { TableToolbar } from './TableToolbar';
import { openVendorDeliveryRateModal, openEditVendorModal, handleGetVendors, selectState, setSelectedId, setPage, setRowsPerPage } from './vendorSlice';
import { selectState as selectDeliveritNav } from '../../../components/DeliveritNav/deliveritNavSlice';
import { setRowsPerPage as setDeliveryRateRowsPerPage, handleGetDeliveryRateProfiles, selectState as selectDeliveryState } from '../delivery_rate/deliveryRateSlice'
import TablePaginationActions from './TablePaginationActions';
import styles from './Vendor.module.css';
import DeliveritNav from '../../../components/DeliveritNav/DeliveritNav';
import { StickyTableHead } from '../../../helpers/GeneralComponent';

export function Vendor(props) {
  const state = useSelector(selectState);
  const deliveritNavState = useSelector(selectDeliveritNav);
  const deliveryRateState = useSelector(selectDeliveryState);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (state.status === 'idle') {
      dispatch(handleGetVendors());
      dispatch(setDeliveryRateRowsPerPage(1000));
      dispatch(handleGetDeliveryRateProfiles());
    }
  });

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    dispatch(handleGetVendors());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
    dispatch(handleGetVendors());
  };

  return (
    <div className={`${deliveritNavState.open ? 'ml-60' : 'ml-12'}`}>
      <DeliveritNav />
      <div className={styles.container}>
      <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
          <Table size="small">
            <StickyTableHead className="!top-0">
              <TableRow>
                <TableCell colSpan={5} sx={{ padding: 0, borderBottom: 'none' }}>
                  <TableToolbar 
                    numSelected={state.selectedId === null ? 0 : 1}
                    vendorDeliveryRate={() => dispatch(openVendorDeliveryRateModal())}
                    EditVendor={() => dispatch(openEditVendorModal())}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                <TableCell align="left" className={styles.tableHeaderNarrow}>ID</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Email</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Delivery Rate</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Pickup Notification</TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {
                state.vendors.length > 0 ? (
                  state.vendors.map((vendor) => (
                    <TableRow 
                      key={vendor.id}
                      hover
                      onClick={() => dispatch(setSelectedId(state.selectedId === vendor.id ? null : vendor.id))}
                      role="checkbox"
                      aria-checked={state.selectedId === vendor.id ? true : false}
                      selected={state.selectedId === vendor.id ? true : false}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox 
                          color="primary"
                          checked={state.selectedId === vendor.id ? true : false}
                        />
                      </TableCell>
                      <TableCell align="left" className={styles.tableCellNarrow}>{vendor.id}</TableCell>
                      <TableCell align="left">{vendor.userName}</TableCell>
                      <TableCell align="left">{vendor.deliveryRateProfile ? vendor.deliveryRateProfile.name : "Default"}</TableCell>
                      <TableCell align="left">{vendor.pickupNotification ? "enabled" : ""}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      <p>No Vendors</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={4}
                  count={state.pagination ? state.pagination.totalElements ? state.pagination.totalElements : 0 : 0}
                  rowsPerPage={state.rowsPerPage}
                  page={state.page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        {
          state.vendorDeliveryRateModalOpen  &&
          <VendorDeliveryRate deliveryRates={deliveryRateState.deliveryRates} vendor={state.selectedId ? state.vendors.find(vendor => vendor.id === state.selectedId) : null} />
        }
        {
          state.editVendorModalOpen  &&
          <EditVendor deliveryRates={deliveryRateState.deliveryRates} vendor={state.selectedId ? state.vendors.find(vendor => vendor.id === state.selectedId) : null} />
        }
      </div>
    </div>
  );
}
