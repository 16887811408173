import keycloak from "../Keycloak";

export const getAuthedUser = () => {
    return JSON.parse(localStorage.auth);
}
  
export const logout = () => {
    keycloak.logout();
    localStorage.removeItem("auth");
}

export function getJwtToken() {
    return keycloak.token;
}