import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getListings, updateListing } from '../../../api/listingsAPI';
import { ListingStatus, SearchType } from '../../../app/constants';
import { createAppointment } from '../../../api/appointmentAPI';
import { performActionable } from '../../../api/dynamicAPI';
import { addFilter } from '../../../app/utils';

export const listingSlice = createSlice({
  name: 'listing',
  initialState: {
    listings: [],
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    sort: 'desc',
    sortBy: 'createdAt',
    selectedId: null,
    selectedListing: null,
    searchValue: '',
    selectedFilter: {
      status: null,
      rentingStatus: null,
      dedicatedSpace: null,
      buildingType: null,
      tandem: null,
      paymentRemark: null
    },
    actionUrl: "",
    actionMethod: "",
    viewRentingModalOpen: false,
    editModalOpen: false,
    freezeModalOpen: false,
    unfreezeModalOpen: false,
    createAppointmentModalOpen: false,
    filterModalOpen: false,
    successMessage: "",
    errorMessage: ""
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setSelectedFilter: (state, action) => {
      state.selectedFilter = action.payload;
    },
    setListings: (state, action) => {
      state.listings = action.payload;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setSelectedListing: (state, action) => {
      state.selectedListing = action.payload;
    },
    setActionUrl: (state, action) => {
      state.actionUrl = action.payload;
    },
    setActionMethod: (state, action) => {
      state.actionMethod = action.payload;
    },
    showRentingModal: (state, action) => {
      state.viewRentingModalOpen = action.payload;
    },
    showEditModal: (state, action) => {
      state.editModalOpen = action.payload;
    },
    showFreezeModal: (state, action) => {
      state.freezeModalOpen = action.payload;
    },
    showUnfreezeModal: (state, action) => {
      state.unfreezeModalOpen = action.payload;
    },
    showCreateAppointmentModal: (state, action) => {
      state.createAppointmentModalOpen = action.payload;
    },
    showFilterModal: (state, action) => {
      state.filterModalOpen = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    }
  },
});

export const { showFilterModal, setSearchValue, setSelectedFilter, setPage, setRowsPerPage, setPagination, setSort, setSortBy, setListings, setSelectedId, setSelectedListing, setActionUrl, setActionMethod, showRentingModal, showEditModal, showFreezeModal, showUnfreezeModal, showCreateAppointmentModal, setSuccessMessage, setErrorMessage } = listingSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.vendor.value)`
export const selectState = state => state.listing;

export const handleGetListings = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    let filterParamsArray = [];
    const { selectedFilter } = state.listing;

    addFilter(filterParamsArray, 'status', selectedFilter.status);
    addFilter(filterParamsArray, 'renting.status', selectedFilter.rentingStatus);
    addFilter(filterParamsArray, 'parkingBay.dedicatedSpace', selectedFilter.dedicatedSpace);
    addFilter(filterParamsArray, 'parkingBay.type', selectedFilter.buildingType);
    addFilter(filterParamsArray, 'parkingBay.tandem', selectedFilter.tandem);
    addFilter(filterParamsArray, 'paymentRemark', selectedFilter.paymentRemark);
    let filterParams = filterParamsArray.join(',');
    
    const response = await getListings(state.listing.page, state.listing.rowsPerPage, state.listing.sort, state.listing.sortBy, `${ListingStatus.VACANT},${ListingStatus.OCCUPIED},${ListingStatus.ACTIVE},${ListingStatus.INACTIVE},${ListingStatus.TERMINATING},${ListingStatus.ALLOCATED}`, state.listing.searchValue, SearchType.INVENTORY, filterParams);
    const { content, ...pagination } = response.data;

    dispatch(setPagination(pagination));
    dispatch(setListings(content));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleUpdateListing = (listing) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await updateListing(state.listing.selectedId, listing);
    dispatch(showEditModal(false));
    dispatch(setSuccessMessage("Successfully updated parking bay!"));
    dispatch(handleGetListings());
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleFreezeListing = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await performActionable(state.listing.actionUrl, state.listing.actionMethod, null);
    dispatch(showFreezeModal(false));
    dispatch(setSuccessMessage("Successfully freezed listing!"));
    dispatch(handleGetListings());
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleUnfreezeListing = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await performActionable(state.listing.actionUrl, state.listing.actionMethod, null);
    dispatch(showUnfreezeModal(false));
    dispatch(setSuccessMessage("Successfully unfreezed listing!"));
    dispatch(handleGetListings());
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleCreateAppointment = (appointment) => async (dispatch, getState) => {
  dispatch(showLoading());

  try {
    await createAppointment(appointment);
    dispatch(showCreateAppointmentModal(false));
    dispatch(setSuccessMessage("Successfully create appointment!"));
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export default listingSlice.reducer;