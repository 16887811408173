/*global google*/
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Paper,
  Grid,
  TextField,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Select,
  Checkbox,
  MenuItem,
  OutlinedInput,
  FormControl
} from "@mui/material";
import { Close } from "@mui/icons-material";
import Moment from 'moment';
import { MuiChipsInput } from 'mui-chips-input'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import useToasts from "../../../hooks/useToasts";
import * as ValidationFunction from "../../../helpers/ValidationFunction";

import {
  selectState,
  showCreateAppointmentModal,
  handleCreateAppointment,
} from "./trackingSheetSlice";

const CreateAppointmentModal = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  const { toastError } = useToasts();

  const renting = state.selectedRenting;
  const [type, setType] = useState("");
  const [meetupAddress, setMeetupAddress] = useState("");
  const [meetupLocation, setMeetupLocation] = useState({
    latitude: undefined,
    longitude: undefined
  });
  const [eventDateTime, setEventDateTime] = useState("");
  const [durationMinutes, setDurationMinutes] = useState("");
  const [invitees, setInvitees] = useState([]);
  const [eventSummary, setEventSummary] = useState("");
  const [eventRemark, setEventRemark] = useState("");
  const [rentingId, setRentingId] = useState("");
  const [officePickup, setOfficePickup] = useState(false);
  const [officeType, setOfficeType] = useState("DAMANSARA");
  const [summaryOption1, setSummaryOption1] = useState("");
  const [summaryOptionCustom, setSummaryOptionCustom] = useState("");
  const [selectedDateTime, setSelectedDateTime] = useState(undefined);
  const [validAddress, setValidAddress] = useState(true);
  const [autoActivate, setAutoActivate] = useState(false);

  const getFormattedDateTime = (datetime) => {
      return Moment(datetime).format('YYYY-MM-DDTHH:mm:ss')
  }

  useEffect(() => {
    const initializeVariables = () => {
        var user = renting.customer.user;
        var summaryLocation = "(" + (renting.listing ? renting.listing?.parkingBay.address + ", " + renting.listing?.parkingBay.bayNumber : "RequestedParkingArea: " + renting.requestedParkingAreaId) + ")"
        var summaryUser = user.firstName + " " + user.lastName + " " + user.contactNumber
        var datetimeNow = new Date()
        datetimeNow.setHours(datetimeNow.getHours() + 1);
        datetimeNow.setMinutes(0, 0);

        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: renting.listing?.parkingBay.address }, (results, status) => {
            var meetUpLocation = {latitude: undefined,longitude: undefined}
            if (status === "OK" && results[0]) {
                meetUpLocation = {
                    latitude: results[0].geometry.location.lat(),
                    longitude: results[0].geometry.location.lng(),
                }
            }
            setType("COLLECT_ACCESS_DEVICE");
            setRentingId(renting.id);
            setEventSummary("Pass access device " + summaryLocation + " to " + summaryUser);
            setMeetupAddress(renting.listing?.parkingBay.address);
            setMeetupLocation(meetUpLocation);
            setInvitees([user.userName, "logistics@parkitmy.com"]);
            setDurationMinutes(15);
            setSummaryOption1("Pass access device " + summaryLocation + " to " + summaryUser);
            setSummaryOptionCustom("");
            setEventDateTime(getFormattedDateTime(datetimeNow));
            setSelectedDateTime(datetimeNow);
        });
    };

    initializeVariables();
  }, [renting]);

  useEffect(() => {
    var defaultBounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(3.1385035, 101.6167771),
        new window.google.maps.LatLng(3.2020728, 100.7790663)
    );
    let autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById("meetupAddress"),
        {
            types: ["geocode", "establishment"],
            bounds: defaultBounds,
            componentRestrictions: { country: ["my", "sg", "hk"] }
        }
    );

    google.maps.event.addListener(autocomplete, "place_changed", function() {
        var place = autocomplete.getPlace();
        const lat = place.geometry ? place.geometry.location.lat() : undefined
        const lng = place.geometry ? place.geometry.location.lng() : undefined
        setMeetupAddress(`${place.name}${place.formatted_address ? ", " + place.formatted_address : ""}`);
        setValidAddress(place.geometry ? true : false);
        setMeetupLocation({
            latitude: lat,
            longitude: lng
        });
    });
  })

  const handleSummaryOptionCustomChange = (event) => {
      let value = event.target.value;
      setSummaryOptionCustom(value);
      setEventSummary(value);
      setType("COLLECT_ACCESS_DEVICE");
  };

  const handleMeetUpAddress = (event) => {
      let value = event.target.value;
      setMeetupAddress(value);
      setMeetupLocation({
        latitude: meetupLocation.latitude,
        longitude: meetupLocation.longitude,
      });
      setValidAddress(false);
  };

  const handleEventDateTimeChange = datetime => {
      setSelectedDateTime(datetime);
      setEventDateTime(getFormattedDateTime(datetime));
  };

  const validateFields = () => {
    if (!renting.id || !eventSummary || !meetupAddress || eventDateTime === "Invalid date" || !durationMinutes || !invitees.length
    || !validAddress || meetupLocation.latitude === undefined || !meetupLocation.longitude === undefined || !ValidationFunction.isAllEmailsValid(invitees)) {
      toastError("Requirement not met.");
      return false;
    }
    return true;
  };

  const submit = () => {
      if (validateFields()) {
        try {
          const payload = {
              rentingId,
              eventSummary: eventSummary,
              eventRemark: eventRemark,
              deliveryDateTime: eventDateTime,
              durationInMin: durationMinutes,
              meetupAddress,
              deliveryLocation: meetupLocation,
              invitees,
              ...(officePickup ? { deliveryType: 'PICKUP_FROM_OFFICE' } : { deliveryType: 'PICKUP_FROM_CUSTOMER' }),
              customerId: renting.customer.id,
              ...(officePickup ? { officeType } : {}),
              type,
              autoActivate
          };
          dispatch(handleCreateAppointment(payload));
        } catch (error) {
          console.log(error.message);
        }
      }
  };

  return (
    <Modal
      open={true}
      aria-labelledby="create-appointment-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[60%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showCreateAppointmentModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[20px] text-[20px] font-medium">Create Appointment</p>
        </div>
        <Grid container direction={'row'} spacing={6}>
            <Grid item xs={12}>
                <FormLabel component="legend">I would like to set an appointment below:</FormLabel>
            </Grid>
            <Grid item xs={12}>
                <FormLabel component="legend">Summary Title</FormLabel>
                <RadioGroup aria-label="eventSummary" name="eventSummary" value={eventSummary} onChange={(e) => setEventSummary(e.target.value)}>
                    <FormControlLabel value={summaryOption1} control={<Radio />} label={summaryOption1} sx={{ marginBottom: '8px' }} />
                    <FormControlLabel value={summaryOptionCustom} control={<Radio />} label={
                        <TextField
                        id="summaryOptionCustom"
                        label="Custom Event Summary"
                        defaultValue={summaryOptionCustom}
                        onChange={handleSummaryOptionCustomChange}
                        margin="normal"
                        variant="outlined"
                        style={{width: "500px"}}
                    />}  />
                </RadioGroup>
            </Grid>
            <Grid item xs={6}>
                <FormControlLabel
                    control={<Checkbox  checked={officePickup} onChange={(e) => setOfficePickup(e.target.checked)} name="officePickup"/>}
                    label={"Pick Up From"}/>
            </Grid>
            <Grid item xs={6}>
                <Select
                    fullWidth
                    value={officeType}
                    onChange={(e) => setOfficeType(e.target.value)}
                    input={<OutlinedInput labelwidth={92} name="officeType" id="officeType" />}>
                    <MenuItem value="SUNWAY_GEO">SUNWAY_GEO</MenuItem>
                    <MenuItem value="CHERAS_HEIGHT">CHERAS_HEIGHT</MenuItem>
                    <MenuItem value="DAMANSARA">DAMANSARA</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <TextField
                        id="meetupAddress"
                        label="Meet Up Address"
                        defaultValue={meetupAddress}
                        value={meetupAddress}
                        onChange={handleMeetUpAddress}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        required
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <TextField
                        id="meetupAddressLat"
                        label="Latitude"
                        type="text"
                        value={meetupLocation.latitude}
                        disabled={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <TextField
                        id="meetupAddressLng"
                        label="Longtitude"
                        type="text"
                        value={meetupLocation.longitude}
                        disabled={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            label="Event Date Time"
                            value={selectedDateTime}
                            onChange={handleEventDateTimeChange}
                            minutesStep={5}
                            componentsProps= {{
                                actionBar: {
                                    actions: ['clear', 'accept'],
                                },
                            }}
                            minDateTime={new Date(Date.now() + 10 * 60000)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <TextField
                        id="durationMinutes"
                        label="Duration (min)"
                        type="number"
                        value={durationMinutes}
                        onChange={(e) => setDurationMinutes(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            onWheel: (e) => e.target.blur()
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <TextField
                        id="remarks"
                        label="Remarks (Multiline)"
                        defaultValue={eventRemark}
                        onChange={(e) => setEventRemark(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        multiline
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={<Checkbox  checked={autoActivate} onChange={(e) => setAutoActivate(e.target.checked)} name="autoActivate"/>}
                    label={"Auto Activate"}/>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <MuiChipsInput
                        id="invitees"
                        label="Invitees"
                        value={invitees}
                        onChange={(chips) => setInvitees(chips)}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        required
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <button
                onClick={submit}
                className="bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md"
              >
                Confirm
              </button>
            </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default CreateAppointmentModal;