/* global google */
import React, { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import "./GoogleAutocomplete.css";

const GoogleAutocomplete = ({
  className,
  id,
  name,
  placeholder,
  value,
  onPlaceChanged,
}) => {
  const [init, setInit] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    let autocomplete;

    if (!init) {
      let defaultBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(3.1385035, 101.6167771),
        new google.maps.LatLng(3.2020728, 100.7790663)
      );
      const searchInput = inputRef.current;

      autocomplete = new google.maps.places.Autocomplete(searchInput, {
        types: ["geocode", "establishment"],
        bounds: defaultBounds,
      });

      let pressEnter = false;
      searchInput.addEventListener("keydown", (e) => {
        if (e.keyCode === 40) {
          pressEnter = true;
        }
      });

      google.maps.event.addDomListener(searchInput, "keydown", (e) => {
        e.cancelBubble = true;
        if (e.keyCode === 13 || e.keyCode === 9) {
          if (!pressEnter && !e.hasRanOnce) {
            google.maps.event.trigger(e.target, "keydown", {
              keyCode: 40,
              hasRanOnce: true,
            });
          }
        }
      });

      google.maps.event.addListener(autocomplete, "place_changed", function () {
        let place = autocomplete.getPlace();
        if (typeof place.address_components !== "undefined") {
          pressEnter = false;
        }

        onPlaceChanged(place);
      });

      setInit(true);
    }
  }, [init, onPlaceChanged]);

  return (
    <input
      id={id}
      name={name}
      ref={inputRef}
      type="text"
      placeholder={placeholder}
      defaultValue={value}
      className={twMerge(`
        text-ellipses h-[32px]
        ${className ?? ""}
      `)}
    />
  );
};

export default GoogleAutocomplete;
