import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getListings } from '../../../api/listingsAPI';
import { ListingStatus, SearchType } from '../../../app/constants';
import { performActionable } from '../../../api/dynamicAPI';
import { addFilter } from '../../../app/utils';

export const ownerListingsSlice = createSlice({
  name: 'ownerListings',
  initialState: {
    listings: [],
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    sort: 'desc',
    sortBy: 'createdAt',
    selectedId: null,
    actionUrl: "",
    actionMethod: "",
    searchValue: '',
    selectedFilter: {
      leasePeriod: null,
      currency: null,
      dedicatedSpace: null,
      buildingType: null,
      tandem: null
    },
    approveListingModalOpen: false,
    rejectListingModalOpen: false,
    filterModalOpen: false,
    successMessage: "",
    errorMessage: ""
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setListings: (state, action) => {
      state.listings = action.payload;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setSelectedFilter: (state, action) => {
      state.selectedFilter = action.payload;
    },
    setActionUrl: (state, action) => {
      state.actionUrl = action.payload;
    },
    setActionMethod: (state, action) => {
      state.actionMethod = action.payload;
    },
    showApproveListingModal: (state, action) => {
      state.approveListingModalOpen = action.payload;
    },
    showRejectListingModal: (state, action) => {
      state.rejectListingModalOpen = action.payload;
    },
    showFilterModal: (state, action) => {
      state.filterModalOpen = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    }
  },
});

export const { showFilterModal, setSearchValue, setSelectedFilter, setPage, setRowsPerPage, setPagination, setSort, setSortBy, setListings, setSelectedId, setActionUrl, setActionMethod, showApproveListingModal, showRejectListingModal, setSuccessMessage, setErrorMessage } = ownerListingsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.vendor.value)`
export const selectState = state => state.ownerListings;

export const handleGetListings = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    let filterParamsArray = [];
    const { selectedFilter } = state.ownerListings;

    addFilter(filterParamsArray, 'additionalInformation.leasePeriod', selectedFilter.leasePeriod);
    addFilter(filterParamsArray, 'requestedMonthlyBuyPriceCurrency', selectedFilter.currency);
    addFilter(filterParamsArray, 'parkingBay.dedicatedSpace', selectedFilter.dedicatedSpace);
    addFilter(filterParamsArray, 'parkingBay.type', selectedFilter.buildingType);
    addFilter(filterParamsArray, 'parkingBay.tandem', selectedFilter.tandem);
    let filterParams = filterParamsArray.join(',');

    const response = await getListings(state.ownerListings.page, state.ownerListings.rowsPerPage, state.ownerListings.sort, state.ownerListings.sortBy, ListingStatus.PROCESSING, state.ownerListings.searchValue, SearchType.INVENTORY, filterParams);
    const { content, ...pagination } = response.data;

    dispatch(setPagination(pagination));
    dispatch(setListings(content));
  } catch (error) {
    alert(error.message);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleApproveListing = (payload) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await performActionable(state.ownerListings.actionUrl, state.ownerListings.actionMethod, payload);
    dispatch(showApproveListingModal(false));
    dispatch(setSuccessMessage("Successfully approved listing!"));
    dispatch(handleGetListings());
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleRejectListing = (payload) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await performActionable(state.ownerListings.actionUrl, state.ownerListings.actionMethod, payload);
    dispatch(showRejectListingModal(false));
    dispatch(setSuccessMessage("Successfully rejected listing!"));
    dispatch(handleGetListings());
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export default ownerListingsSlice.reducer;