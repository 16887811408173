import axiosApiInstance from "../app/axios";
import { PARKIT_API_BASE_URL } from "../app/utils";
import { getJwtToken } from "../app/common";

export const getAllSubscribers = async (page, size, sort, sortBy) =>
    axiosApiInstance.get(
        `${PARKIT_API_BASE_URL}/parkit/v2/customers?page=${page}&size=${size}&sort=${sort}&sortBy=${sortBy}`,
        { headers: { Authorization: `Bearer ${getJwtToken()}` } }
    );

export const getSubscriberWithInsurance = async (page, size, sort, sortBy, insuranceStatus) =>
    axiosApiInstance.get(
        `${PARKIT_API_BASE_URL}/parkit/v2/customers?page=${page}&size=${size}&sort=${sort}&sortBy=${sortBy}&insuranceStatus=${insuranceStatus}`,
        { headers: { Authorization: `Bearer ${getJwtToken()}` } }
    );

export const updateSubscriber = async (data) =>
    axiosApiInstance.put(
        `${PARKIT_API_BASE_URL}/parkit/v2/customers/update`, data,
        { headers: { Authorization: `Bearer ${getJwtToken()}` } }
    );