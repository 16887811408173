import React from 'react';
import { useDispatch } from 'react-redux';
import { closeDeleteOrderModal, handleGetOrdersByOrderGroup } from './orderSlice';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import { deleteOrder } from './orderAPI';

export function ConfirmDelete(props) {
    const { order } = props;
    const dispatch = useDispatch();

    const submit = async () => {
        try {
            const res = await deleteOrder(props.orderGroup.id, order.id);
            if(res.error) throw res.error;
    
            dispatch(closeDeleteOrderModal());
            dispatch(handleGetOrdersByOrderGroup(props.orderGroup.id));
        } catch (err) {
            alert(err);
        }
    }

    return (
        <div>
        <Dialog open={true} onClose={() => dispatch(closeDeleteOrderModal())} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Delete Order</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this order?
                </DialogContentText>  
            </DialogContent>
            <DialogActions>
                <Button onClick={() =>  dispatch(closeDeleteOrderModal())} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => submit()} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}