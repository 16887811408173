import { Toolbar, Typography, Tooltip, IconButton } from "@mui/material";
import { makeStyles } from '@mui/styles';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: 2,
      paddingRight: 1,
    },
    title: {
      flex: '1 1 100%',
    },
    toolTipContainer: {
        position: "absolute",
        right: 10
    }
}));

export const TableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, confirmPaymentStatus, downloadOrderGroups, orderGroups } = props;
    return (
        <Toolbar>
            { numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Order Group List
                </Typography>
            )}
            {
                (numSelected > 0 && orderGroups.length > 0) ? (
                    <>
                        {
                            (orderGroups.filter(o => o.paymentMethod === "BANK_TRANSFER").length === orderGroups.length) ? (
                                (orderGroups.filter(o => o.paid).length === orderGroups.length) ? (
                                    <Tooltip title="Mark as Unpaid" onClick={confirmPaymentStatus}>
                                        <IconButton aria-label="Mark as Unpaid">
                                            <MoneyOffIcon />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    (orderGroups.filter(o => !o.paid).length === orderGroups.length) ? (
                                        <Tooltip title="Mark As Paid" onClick={confirmPaymentStatus}>
                                            <IconButton aria-label="Mark as Paid">
                                                <MonetizationOnIcon />
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        <Typography variant="caption">Selected order group contains different payment status</Typography>
                                    )
                                )
                            ) : (
                                <Typography variant="caption">Selected order group contains credit card payment method</Typography>
                            )
                        }
                    </>
                ) : (
                    <Tooltip title="Download Order Groups" onClick={downloadOrderGroups}>
                        <IconButton aria-label="Download Order Groups">
                            <CloudDownloadIcon />
                        </IconButton>
                    </Tooltip>
                )
            }
        </Toolbar>
    );
};