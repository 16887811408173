import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { downloadPayoutReceipt, getAllPayouts, uploadPayoutFile } from '../../../api/payoutAPI';
import { saveAs } from 'file-saver';

export const payoutSlice = createSlice({
  name: 'payout',
  initialState: {
    payouts: [],
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    sort: 'desc',
    sortBy: 'payoutDate',
    selectedId: null,
    selectedPayout: null,
    uploadPayoutModalOpen: false,
    successMessage: "",
    errorMessage: ""
  },
  reducers: {
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setSelectedPayout: (state, action) => {
      state.selectedPayout = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setPayouts: (state, action) => {
      state.payouts = action.payload;
    },
    showUploadPayoutModal: (state, action) => {
      state.uploadPayoutModalOpen = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  }
});

export const { showUploadPayoutModal, setSelectedId, setSelectedPayout, setPage, setRowsPerPage, setPagination, setSort, setSortBy, setPayouts, setSuccessMessage, setErrorMessage } = payoutSlice.actions;

export const selectState = state => state.payout;

export const handleGetPayouts = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const response = await getAllPayouts(state.payout.page, state.payout.rowsPerPage, state.payout.sort, state.payout.sortBy);
    const { content, ...pagination } = response.data;

    dispatch(setPagination(pagination));
    dispatch(setPayouts(content));
  } catch (error) {
    dispatch(setErrorMessage(error.response?.data?.message ?? error.message));
    dispatch(setPayouts([]));
  } finally {
    dispatch(hideLoading());
  }
}

export const handleDownloadPayoutReceipt = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const response = await downloadPayoutReceipt(state.payout.selectedId);
    let filename = `payout-${state.payout.selectedPayout.yearMonth}.pdf`
    saveAs(new Blob([response.data], { type: 'application/pdf' }), filename);
    dispatch(setSuccessMessage("Successfully download payout receipt!"));

  } catch (error) {
    dispatch(setErrorMessage(error.response?.data?.message ?? error.message));
  } finally {
    dispatch(hideLoading());
  }
}

export const handleUploadPayoutFile = (passCode, yearMonth, file) => async (dispatch) => {
  dispatch(showLoading());
  try {
    const [year, month] = yearMonth.split('-');
    const formData = new FormData();
    formData.append('csvFile', file, file.name);
    formData.append('year', year);
    formData.append('month', month);
    formData.append('passcode', passCode);
    formData.append('overwrite', true);

    await uploadPayoutFile(formData);
    dispatch(showUploadPayoutModal(false));
    dispatch(setSuccessMessage("Successfully uploaded payout file!"));
    dispatch(handleGetPayouts());
  } catch (error) {
    dispatch(setErrorMessage(error.response?.data?.message ?? error.message + " (Unauthorized)"));
  } finally {
    dispatch(hideLoading());
  }
}

export default payoutSlice.reducer;