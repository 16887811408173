import { createSlice } from "@reduxjs/toolkit";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import {
  getBuildingEnquiries,
  updateBuildingEnquiry,
  deleteBuildingEnquiry
} from "../../../api/enquiriesAPI";

export const buildingEnquiriesSlice = createSlice({
  name: "buildingEnquiries",
  initialState: {
    enquiries: [],
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    sort: 'desc',
    sortBy: 'startDate',
    selectedId: null,
    selectedEnquiry: null,
    editModalOpen: false,
    convertToRentingModalOpen: false,
    successMessage: "",
    errorMessage: "",
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setEnquiries: (state, action) => {
      state.enquiries = action.payload;
      state.status = "success";
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setSelectedEnquiry: (state, action) => {
      state.selectedEnquiry = action.payload;
    },
    showEditModal: (state, action) => {
      state.editModalOpen = action.payload;
    },
    showConvertToRentingModal: (state, action) => {
      state.convertToRentingModalOpen = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  },
});

export const {
  setPage,
  setRowsPerPage,
  setPagination,
  setSort,
  setSortBy,
  setEnquiries,
  setSelectedId,
  setSelectedEnquiry,
  showEditModal,
  showConvertToRentingModal,
  setSuccessMessage,
  setErrorMessage,
} = buildingEnquiriesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.vendor.value)`
export const selectState = (state) => state.buildingEnquiries;

export const handleGetEnquiries = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const response = await getBuildingEnquiries(
      state.buildingEnquiries.page,
      state.buildingEnquiries.rowsPerPage,
      state.buildingEnquiries.sort,
      state.buildingEnquiries.sortBy
    );
    const { content, ...pagination } = response.data;

    dispatch(setPagination(pagination));
    dispatch(setEnquiries(content));
  } catch (error) {
    alert(error.message);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleUpdateEnquiry = (id, enquiry) => async (dispatch) => {
  dispatch(showLoading());

  try {
    await updateBuildingEnquiry(id, enquiry);
    dispatch(showEditModal(false));
    dispatch(setSuccessMessage("Successfully updated building enquiry!"));
    dispatch(handleGetEnquiries());
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleConvertToRentingEnquiry = (id) => async (dispatch) => {
  dispatch(showLoading());

  try {
    await deleteBuildingEnquiry(id);
    dispatch(showConvertToRentingModal(false));
    dispatch(setSuccessMessage("Successfully convert building enquiry to renting!"));
    dispatch(handleGetEnquiries());
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export default buildingEnquiriesSlice.reducer;
