import { Button, Toolbar, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useDispatch } from "react-redux";
import { showEditModal } from "./deliveryRateSlice";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: 2,
        paddingRight: 1,
    },
    title: {
        flex: '1 1 100%',
    },
    toolTipContainer: {
        position: "absolute",
        right: 10
    }
}));

export const TableToolbar = (props) => {
    const dispatch = useDispatch();
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar>
            {numSelected > 0 ? (
                <>
                    <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                        {numSelected} selected
                    </Typography>
                    <Button variant="text" size="small" onClick={() => dispatch(showEditModal(true))} sx={{ textTransform: "none" }}>
                        Edit
                    </Button>
                </>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Delivery Rates
                </Typography>
            )}
        </Toolbar>
    );
};