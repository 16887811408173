import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { downloadPayoutReports, getAllPayoutReports } from '../../../api/payoutAPI';
import { saveAs } from 'file-saver';

export const payoutReportsSlice = createSlice({
  name: 'payoutReports',
  initialState: {
    payoutReports: [],
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    sort: 'desc',
    sortBy: 'createdAt',
    selectedId: null,
    selectedReport: null,
    successMessage: "",
    errorMessage: ""
  },
  reducers: {
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setSelectedReport: (state, action) => {
      state.selectedReport = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setPayoutReports: (state, action) => {
      state.payoutReports = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  }
});

export const { setSelectedId, setSelectedReport, setPage, setRowsPerPage, setPagination, setSort, setSortBy, setPayoutReports, setSuccessMessage, setErrorMessage } = payoutReportsSlice.actions;

export const selectState = state => state.payoutReports;

export const handleGetPayoutReports = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const response = await getAllPayoutReports(state.payoutReports.page, state.payoutReports.rowsPerPage, state.payoutReports.sort, state.payoutReports.sortBy);
    const { content, ...pagination } = response.data;

    dispatch(setPagination(pagination));
    dispatch(setPayoutReports(content));
  } catch (error) {
    dispatch(setErrorMessage(error.response?.data?.message ?? error.message));
    dispatch(setPayoutReports([]));
  } finally {
    dispatch(hideLoading());
  }
}

export const handleDownloadReports = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const response = await downloadPayoutReports(state.payoutReports.selectedId);
    let filename = `payout-report-${state.payoutReports.selectedReport.yearMonth}.csv`
    saveAs(new Blob([response.data], { type: 'text/csv' }), filename);
    dispatch(setSuccessMessage("Successfully download payout reports!"));

  } catch (error) {
    dispatch(setErrorMessage(error.response?.data?.message ?? error.message));
  } finally {
    dispatch(hideLoading());
  }
}

export default payoutReportsSlice.reducer;