import { configureStore } from '@reduxjs/toolkit';
import { loadingBarReducer } from 'react-redux-loading-bar';
import homeReducer from '../features/home/homeSlice';
import userReducer from '../features/user/userSlice';
import walletReducer from '../features/wallet/walletSlice';
import voucherReducer from '../features/voucher/voucherSlice';
import subscriptionReducer from '../features/subscription/subscriptionSlice';
import snackbarReducer from '../features/snackbar/snackbarSlice';
import parkitNavReducer from '../components/ParkitNav/parkitNavSlice';
import deliveritNavReducer from '../components/DeliveritNav/deliveritNavSlice';

import deliverItDashboardReducer from '../features/deliverit/dashboard/dashboardSlice';
import deliveryRateReducer from '../features/deliverit/delivery_rate/deliveryRateSlice';
import orderGroupReducer from '../features/deliverit/order_group/orderGroupSlice';
import orderReducer from '../features/deliverit/order/orderSlice';
import vendorReducer from '../features/deliverit/vendor/vendorSlice';

import appointmentReducer from '../features/parkit/appointment/appointmentSlice';
import parkingAreaReducer from '../features/parkit/parkingArea/parkingAreaSlice';
import buildingEnquiriesReducer from '../features/parkit/buildingEnquiries/buildingEnquiriesSlice';
import ownerListingsReducer from '../features/parkit/ownerListings/ownerListingsSlice';
import listingReducer from '../features/parkit/listing/listingSlice';
import masterlistReducer from '../features/parkit/masterlist/masterlistSlice';
import rentingReducer from '../features/parkit/renting/rentingSlice';
import trackingSheetReducer from '../features/parkit/trackingSheet/trackingSheetSlice';
import terminationReducer from '../features/parkit/termination/terminationSlice';
import bulkEnquiriesReducer from '../features/parkit/bulkEnquiries/bulkEnquiriesSlice';
import archivedListingsReducer from '../features/parkit/archivedListings/archivedListingsSlice';
import archivedRentingsReducer from '../features/parkit/archivedRentings/archivedRentingsSlice';
import insuranceReducer from '../features/parkit/insurance/insuranceSlice';
import deliveryRateParkItReducer from '../features/parkit/deliveryRate/deliveryRateSlice';
import affiliateCampaignReducer from '../features/parkit/affiliateCampaign/affiliateCampaignSlice';
import affiliateCampaignReferralReducer from '../features/parkit/affiliateCampaignReferral/affiliateCampaignReferralSlice';
import parkitUserReducer from '../features/parkit/user/userSlice';
import deleteRequestReducer from '../features/parkit/deleteRequest/deleteRequestSlice';
import payoutReportsReducer from '../features/parkit/payoutReports/payoutReportsSlice';
import payoutReducer from '../features/parkit/payout/payoutSlice';
import parkitSubscriptionReducer from '../features/parkit/subscription/subscriptionSlice';
import parkitFailedSubscriptionReducer from '../features/parkit/failedSubscription/failedSubscriptionSlice';

export const store = configureStore({
  reducer: {
    loadingBar: loadingBarReducer,
    home: homeReducer,
    user: userReducer,
    deliveryRate: deliveryRateReducer,
    deliverItDashboard: deliverItDashboardReducer,
    orderGroup: orderGroupReducer,
    order: orderReducer,
    appointment: appointmentReducer,
    parkingArea: parkingAreaReducer,
    buildingEnquiries: buildingEnquiriesReducer,
    ownerListings: ownerListingsReducer,
    listing: listingReducer,
    masterlist: masterlistReducer,
    renting: rentingReducer,
    trackingSheet: trackingSheetReducer,
    termination: terminationReducer,
    bulkEnquiries: bulkEnquiriesReducer,
    archivedListings: archivedListingsReducer,
    archivedRentings: archivedRentingsReducer,
    insurance: insuranceReducer,
    deliveryRateParkIt: deliveryRateParkItReducer,
    affiliateCampaign: affiliateCampaignReducer,
    affiliateCampaignReferral: affiliateCampaignReferralReducer,
    parkitUser: parkitUserReducer,
    deleteRequest: deleteRequestReducer,
    payoutReports: payoutReportsReducer,
    payout: payoutReducer,
    parkitSubscription: parkitSubscriptionReducer,
    parkitFailedSubscription: parkitFailedSubscriptionReducer,
    wallet: walletReducer,
    voucher: voucherReducer,
    vendor: vendorReducer,
    snackbar: snackbarReducer,
    subscription: subscriptionReducer,
    parkitNav: parkitNavReducer,
    deliveritNav: deliveritNavReducer,
  },
});
