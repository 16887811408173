import React, { useEffect, useState } from "react";
import {
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  styled,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import { openDeliveritNav, closeDeliveritNav, selectState as selectDeliveritNav } from './deliveritNavSlice';

import { ReactComponent as CollapseMenuIcon } from '../../assets/icons/collapse_menu.svg';
import { ReactComponent as DashboardIcon } from '../../assets/icons/dashboard_di.svg';
import { ReactComponent as DeliveryRateIcon } from '../../assets/icons/delivery_rate.svg';
import { ReactComponent as OrderGroupIcon } from '../../assets/icons/order_group.svg';
import { ReactComponent as VendorIcon } from '../../assets/icons/vendor.svg';

const DeliveritNav = () => {
  const { open } = useSelector(selectDeliveritNav);
  const dispatch = useDispatch();
  const [toolbarHeight, setToolbarHeight] = useState(69);

  useEffect(() => {
    const handleResize = () => {
      const toolbar = document.getElementById('appBar');
      if (toolbar) {
        setToolbarHeight(toolbar.offsetHeight);
      }
    };

    // Initial measurement
    handleResize();

    // Listen for resize events
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const pages = [{
    label: 'Dashboard',
    path: '/deliverit',
    icon: <DashboardIcon width={20} height={20} />,
  }, {
    label: 'Order Groups',
    path: '/deliverit/order-groups',
    icon: <OrderGroupIcon width={20} height={20} />,
  }, {
    label: 'Delivery Rates',
    path: '/deliverit/delivery-rates',
    icon: <DeliveryRateIcon width={20} height={20} />,
  }, {
    label: 'Vendors',
    path: '/deliverit/vendors',
    icon: <VendorIcon width={20} height={20} />,
  }];

  const drawerWidth = 240;

  const openedMixin = (theme) => ({
    backgroundColor: '#152C7D',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    top: toolbarHeight,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  });

  const closedMixin = (theme) => ({
    backgroundColor: '#152C7D',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    top: toolbarHeight,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  });

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );

  return (
    <div>
      <Drawer variant="permanent" open={open}>
        <div className="flex items-center p-3 bg-[#1C389A]">
          <IconButton sx={{ color: "white" }} onClick={() => open ? dispatch(closeDeliveritNav()) : dispatch(openDeliveritNav())}>
            {open ? (<div className="flex items-center h-[24px]"><CollapseMenuIcon width={18} height={18} /></div>) : (<MenuIcon />)}
          </IconButton>
        </div>
        <Divider />
        <List>
          {pages.map((page) => (
            <ListItem key={page.label} disablePadding className="block">
              <ListItemButton
                onClick={() => window.location.href = page.path}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: 'white',
                  }}
                >
                  {page.icon}
                </ListItemIcon>
                <ListItemText primary={page.label} className={`text-white ${open ? 'opacity-100' : 'opacity-0'}`} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default DeliveritNav;
