import { createSlice } from "@reduxjs/toolkit";
import { showLoading, hideLoading } from "react-redux-loading-bar";

export const deliveryRateSlice = createSlice({
  name: "deliveryRate",
  initialState: {
    deliveryRates: [],
    status: "idle",
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null,
    selectedRate: null,
    editModalOpen: false,
    successMessage: "",
    errorMessage: "",
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setDeliveryRates: (state, action) => {
      state.deliveryRates = action.payload;
      state.status = "success";
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setSelectedRate: (state, action) => {
      state.selectedRate = action.payload;
    },
    showEditModal: (state, action) => {
      state.editModalOpen = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  },
});

export const {
  setPage,
  setRowsPerPage,
  setPagination,
  setDeliveryRates,
  setSelectedId,
  setSelectedRate,
  showEditModal,
  setSuccessMessage,
  setErrorMessage,
} = deliveryRateSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.vendor.value)`
export const selectState = (state) => state.deliveryRateParkIt;

export const handleGetRates = () => async (dispatch, getState) => {
  dispatch(showLoading());
  // const state = getState();

  try {
    // TODO: update API
    // const response = await getDeliveryRates(
    //   state.deliveryRateParkIt.page,
    //   state.deliveryRateParkIt.rowsPerPage
    // );
    // const { content, ...pagination } = response.data;
    // dispatch(setPagination(pagination));
    // dispatch(setDeliveryRates(content));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleUpdateRate = (id, data) => async (dispatch) => {
  dispatch(showLoading());

  try {
    // await updateDeliveryRate(id, data); // TODO: update API
    dispatch(showEditModal(false));
    dispatch(setSuccessMessage("Successfully updated delivery rate!"));
    dispatch(handleGetRates());
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export default deliveryRateSlice.reducer;
