import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getUserWallet } from '../../../api/walletAPI';
import { getAllSubscribers, updateSubscriber } from '../../../api/subscriberAPI';
import { updateUser } from "../../../api/usersAPI";
import { updateWallet } from "../../../api/walletAPI";
import { updateVehicle } from '../../../api/vehicleAPI';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    users: [],
    adminTopUpModalOpen: false,
    walletTransactionModalOpen: false,
    editUserModalOpen: false,
    editCustomerModalOpen: false,
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    sort: 'desc',
    sortBy: 'id',
    selectedId: null,
    selectedUser: null,
    selectedWalletId: null,
    successMessage: "",
    errorMessage: "",
  },
  reducers: {
    showAdminTopUpModal: (state, action) => {
      state.adminTopUpModalOpen = action.payload;
    },
    showWalletTransactionModal: (state, action) => {
      state.walletTransactionModalOpen = action.payload;
    },
    showEditUserModal: (state, action) => {
      state.editUserModalOpen = action.payload;
    },
    showEditCustomerModal: (state, action) => {
      state.editCustomerModalOpen = action.payload;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    setSelectedWalletId: (state, action) => {
      state.selectedWalletId = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  },
});

export const { showAdminTopUpModal, showWalletTransactionModal, showEditUserModal, showEditCustomerModal, setSelectedId, setSelectedWalletId, setPage, setRowsPerPage, setPagination, setUsers, setSuccessMessage, setSelectedUser, setErrorMessage } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.parkitUser.value)`
export const selectState = state => state.parkitUser;

const handleAddWalletToUsers = async (subscribers) => {
  return await Promise.all(
    subscribers.map(async (subscriber) => {
      try {
        const wallet = await getUserWallet(subscriber.user.id);
        const user = { ...subscriber.user, wallet };
        return { ...subscriber, user};
      } catch {
        return { ...subscriber };
      }
    })
  );
}

export const handleGetUsers = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const response = await getAllSubscribers(state.parkitUser.page, state.parkitUser.rowsPerPage, state.parkitUser.sort, state.parkitUser.sortBy)
    const { content, ...pagination } = response.data;

    const usersWithWallet = await handleAddWalletToUsers(content);
    dispatch(setPagination(pagination));
    dispatch(setUsers(usersWithWallet));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleUpdateUser = (id, user) => async (dispatch) => {
  dispatch(showLoading());

  try {
    await updateUser(id, user);
    dispatch(showEditUserModal(false));
  } catch (error) {
    dispatch(setErrorMessage("Failed to update user: " + error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleUpdateSubscriber = (subscriber) => async (dispatch) => {
  dispatch(showLoading());

  try {
    await updateSubscriber(subscriber);
    dispatch(showEditUserModal(false));
  } catch (error) {
    dispatch(setErrorMessage("Failed to update subscriber: " + error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleUpdateWallet = (id, payload) => async (dispatch) => {
  dispatch(showLoading());

  try {
    await updateWallet(id, payload);
  } catch (error) {
    dispatch(setErrorMessage("Failed to update wallet: " + error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleUpdateVehicle = (id, payload) => async (dispatch) => {
  dispatch(showLoading());

  try {
    await updateVehicle(id, payload);
  } catch (error) {
    dispatch(setErrorMessage("Failed to update vehicle: " + error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export default userSlice.reducer;
