import React from 'react';
import { useDispatch } from 'react-redux';
import { closeDeactivateVoucherModal, handleGetVouchers } from './voucherSlice';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import { deactivateVoucher } from './voucherAPI';

export function DeactivateVoucher(props) {
    const { voucher } = props;
    const dispatch = useDispatch();
    
    const submit = async () => {
        try {
            let voucherPayload = {...voucher};
            if (voucher.whiteList && voucher.whiteList.length > 0) {
                voucherPayload = {
                    ...voucher,
                    whiteList: [
                        ...voucher.whiteList.map(u => u.id),
                    ]
                }
            }

            const res = await deactivateVoucher(voucher.id, voucherPayload);
            if(res.error) throw res.error;
    
            dispatch(closeDeactivateVoucherModal());
            dispatch(handleGetVouchers());
        } catch (err) {
            alert(err);
        }
    }

    return (
        <div>
        <Dialog open={true} onClose={() => dispatch(closeDeactivateVoucherModal())} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Deactivate Voucher</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to deactivate this voucher?
                </DialogContentText>  
                <br/>
            </DialogContent>
            <DialogActions>
                <Button onClick={() =>  dispatch(closeDeactivateVoucherModal())} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => submit()} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}