import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableRow, TableSortLabel, Paper, Checkbox, TablePagination, TableFooter } from "@mui/material";
import Moment from 'moment';
import styles from './BulkEnquiries.module.css';
import { StickyTableHead } from '../../../helpers/GeneralComponent';

import ParkitNav from '../../../components/ParkitNav/ParkitNav';
import DeleteListingEnquiryModal from './DeleteListingEnquiryModal';
import DeleteRentingEnquiryModal from './DeleteRentingEnquiryModal';
import { ListingTableToolbar } from './ListingTableToolbar';
import { RentingTableToolbar } from './RentingTableToolbar';
import TablePaginationActions from './TablePaginationActions';
import { handleGetBulkListingEnquiries, handleGetBulkRentingEnquiries, selectState, setErrorMessage, setListingPage, setListingRowsPerPage, setListingSort, setListingSortBy, setRentingSort, setRentingSortBy, setRentingPage, setRentingRowsPerPage, setSelectedListingId, setSelectedRentingId, setSuccessMessage } from './bulkEnquiriesSlice';
import { selectState as selectParkitNav } from '../../../components/ParkitNav/parkitNavSlice';
import useToasts from '../../../hooks/useToasts';

export function BulkEnquiries() {
  const state = useSelector(selectState);
  const parkitNavState = useSelector(selectParkitNav);
  const dispatch = useDispatch();
  const { toastError, toastSuccess } = useToasts();

  useEffect(() => {
    dispatch(handleGetBulkListingEnquiries());
  }, [state.listingSort, state.listingSortBy, dispatch]);

  useEffect(() => {
    dispatch(handleGetBulkRentingEnquiries());
  }, [state.rentingSort, state.rentingSortBy, dispatch]);

  useEffect(() => {
    if (state.successMessage !== "") {
      toastSuccess(state.successMessage);
      dispatch(setSuccessMessage(""));
    }
  }, [dispatch, toastSuccess, state.successMessage]);

  useEffect(() => {
    if (state.errorMessage !== "") {
      toastError(state.errorMessage);
      dispatch(setErrorMessage(""));
    }
  }, [dispatch, toastError, state.errorMessage]);

  const handleChangeListingPage = (event, newPage) => {
    dispatch(setListingPage(newPage));
    dispatch(handleGetBulkListingEnquiries());
  };

  const handleChangeListingRowsPerPage = (event) => {
    dispatch(setListingRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setListingPage(0));
    dispatch(handleGetBulkListingEnquiries());
  };

  const handleChangeRentingPage = (event, newPage) => {
    dispatch(setRentingPage(newPage));
    dispatch(handleGetBulkRentingEnquiries());
  };

  const handleChangeRentingRowsPerPage = (event) => {
    dispatch(setRentingRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setRentingPage(0));
    dispatch(handleGetBulkRentingEnquiries());
  };

  const handleListingRequestSort = (property) => {
    const isDescending = state.listingSortBy === property && state.listingSort === 'desc';
    dispatch(setListingSort(isDescending ? 'asc' : 'desc'));
    dispatch(setListingSortBy(property));
  };

  const handleRentingRequestSort = (property) => {
    const isDescending = state.rentingSortBy === property && state.rentingSort === 'desc';
    dispatch(setRentingSort(isDescending ? 'asc' : 'desc'));
    dispatch(setRentingSortBy(property));
  };

  return (
    <div className={`${parkitNavState.open ? 'ml-60' : 'ml-12'}`}>
      <ParkitNav />
      <div className="p-10 text-left">
        <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
          <div className="h-[64px] sticky left-0 top-0 z-[1] right-0 bg-white">
            <div className="h-[64px] absolute left-0 top-0 right-0">
              <ListingTableToolbar
                numSelected={state.selectedListingId === null ? 0 : 1}
                title="Bulk Listing Enquiries"
              />
            </div>
          </div>
          <Table size="small">
            <StickyTableHead>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Name</TableCell>
                <TableCell align="left" className={styles.tableHeader150}>Email</TableCell>
                <TableCell align="left" className={styles.tableHeader150}>HP</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Company</TableCell>
                <TableCell align="left" className={styles.tableHeader250}>Address</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Location (lat, lng)</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Total Bay Available</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Message</TableCell>
                <TableCell align="left" className={styles.tableHeader110}>
                  <TableSortLabel
                    active={state.listingSortBy === 'createdAt'}
                    direction={state.listingSortBy === 'createdAt' ? state.listingSort : 'desc'}
                    onClick={() => handleListingRequestSort('createdAt')}
                  >
                    Created At
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {
                state.listings.length > 0 ? (
                  state.listings.map((listing) => (
                    <TableRow
                      key={listing.id}
                      hover
                      onClick={() => dispatch(setSelectedListingId(state.selectedListingId === listing.id ? null : listing.id))}
                      role="checkbox"
                      aria-checked={state.selectedListingId === listing.id ? true : false}
                      selected={state.selectedListingId === listing.id ? true : false}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={state.selectedListingId === listing.id ? true : false}
                        />
                      </TableCell>
                      {/* TODO: update fields */}
                      <TableCell align="left" className={styles.tableCell}>{listing.username}</TableCell>
                      <TableCell align="left" className={styles.tableCell150}>{listing.email}</TableCell>
                      <TableCell align="left" className={styles.tableCell150}>{listing.contactNumber}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.company}</TableCell>
                      <TableCell align="left" className={styles.tableCell250}>{listing.address}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.location.latitude} {listing.location.longitude}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.requestBayCount}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{listing.message}</TableCell>
                      <TableCell align="left" className={styles.tableCell110}>{listing.createdAt && Moment(listing.createdAt).format('YYYY-MM-DD HH:mm')}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={15} align="center">
                      <p>No bulk listing enquiries.</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={4}
                  count={state.listingPagination?.totalElements ?? 0}
                  rowsPerPage={state.listingRowsPerPage}
                  page={state.listingPage}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangeListingPage}
                  onRowsPerPageChange={handleChangeListingRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <TableContainer component={Paper} className={styles.denseTable} sx={{ maxHeight: 800}}>
          <div className="h-[64px] sticky left-0 top-0 z-[1] right-0 bg-white">
            <div className="h-[64px] absolute left-0 top-0 right-0">
              <RentingTableToolbar
                numSelected={state.selectedRentingId === null ? 0 : 1}
                title="Bulk Renting Enquiries"
                actionables={state.rentings.filter(r => r.id === state.selectedRentingId)[0]?.actionables}
              />
            </div>
          </div>
          <Table size="small">
            <StickyTableHead className={styles.tableHeader}>
              <TableRow>
                <TableCell align="left" className={styles.tableHeader}>{" "}</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Name</TableCell>
                <TableCell align="left" className={styles.tableHeader150}>Email</TableCell>
                <TableCell align="left" className={styles.tableHeader150}>HP</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Company</TableCell>
                <TableCell align="left" className={styles.tableHeader250}>Address</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Location (lat, lng)</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Request Bay Count</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Currency</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Budget</TableCell>
                <TableCell align="left" className={styles.tableHeader}>
                  <TableSortLabel
                    active={state.rentingSortBy === 'startDate'}
                    direction={state.rentingSortBy === 'startDate' ? state.rentingSort : 'desc'}
                    onClick={() => handleRentingRequestSort('startDate')}
                  >
                    Start Date
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left" className={styles.tableHeader}>End Date</TableCell>
                <TableCell align="left" className={styles.tableHeader}>Purpose</TableCell>
                <TableCell align="left" className={styles.tableHeader110}>
                  <TableSortLabel
                    active={state.rentingSortBy === 'createdAt'}
                    direction={state.rentingSortBy === 'createdAt' ? state.rentingSort : 'desc'}
                    onClick={() => handleRentingRequestSort('createdAt')}
                  >
                    Created At
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </StickyTableHead>
            <TableBody>
              {
                state.rentings.length > 0 ? (
                  state.rentings.map((renting) => (
                    <TableRow
                      key={renting.id}
                      hover
                      onClick={() => dispatch(setSelectedRentingId(state.selectedRentingId === renting.id ? null : renting.id))}
                      role="checkbox"
                      aria-checked={state.selectedRentingId === renting.id ? true : false}
                      selected={state.selectedRentingId === renting.id ? true : false}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={state.selectedRentingId === renting.id ? true : false}
                        />
                      </TableCell>
                      {/* TODO: update fields */}
                      <TableCell align="left" className={styles.tableCell}>{renting.username}</TableCell>
                      <TableCell align="left" className={styles.tableCell150}>{renting.email}</TableCell>
                      <TableCell align="left" className={styles.tableCell150}>{renting.contactNumber}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.company}</TableCell>
                      <TableCell align="left" className={styles.tableCell250}>{renting.address}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.location.latitude} {renting.location.longitude}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.requestBayCount}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.currency}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.budget}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.startDate}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.endDate}</TableCell>
                      <TableCell align="left" className={styles.tableCell}>{renting.purpose}</TableCell>
                      <TableCell align="left" className={styles.tableCell110}>{renting.createdAt && Moment(renting.createdAt).format('YYYY-MM-DD HH:mm')}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={15} align="center">
                    <p>No bulk renting enquiries.</p>
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  colSpan={4}
                  count={state.rentingPagination?.totalElements ?? 0}
                  rowsPerPage={state.rentingRowsPerPage}
                  page={state.rentingPage}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={handleChangeRentingPage}
                  onRowsPerPageChange={handleChangeRentingRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
      {state.deleteListingEnquiryModalOpen && <DeleteListingEnquiryModal />}
      {state.deleteRentingEnquiryModalOpen && <DeleteRentingEnquiryModal />}
    </div>
  );
}