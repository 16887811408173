import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Dashboard.module.css';
import { Card, CardMedia, CardContent, Typography, CardActions, Button, Grid, Chip, TableContainer, Paper, Table, TableRow, TableCell, Divider, ToggleButton, ToggleButtonGroup } from "@mui/material";
import DatePicker from "react-datepicker";
import { selectState, handleGetMonthlyReport, handleGetOrderGroups, handleGetUnpaidOrderGroups, handleGetOrderPerformance, handleGetOrderByDate } from './dashboardSlice';
import { selectState as selectDeliveritNav } from '../../../components/DeliveritNav/deliveritNavSlice';
import "react-datepicker/dist/react-datepicker.css";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import moment from 'moment';
import DeliveritNav from '../../../components/DeliveritNav/DeliveritNav';
import { StickyTableHead } from '../../../helpers/GeneralComponent';
Chart.register(...registerables);

export function Dashboard() {
  const [reportMonthYear, setReportMonthYear] = useState(new Date());
  const [trackOrderDate, setTrackOrderDate] = useState(new Date());
  const state = useSelector(selectState);
  const deliveritNavState = useSelector(selectDeliveritNav);
  const dispatch = useDispatch();

  useEffect(() => {
    if (state.status === 'idle') {
      if (localStorage.getItem('orderPerformanceType') !== 'daily' && localStorage.getItem('orderPerformanceType') !== 'weekly') {
        localStorage.setItem("orderPerformanceType", 'daily')
      }
      dispatch(handleGetOrderGroups("COMPLETED"));
      dispatch(handleGetOrderGroups("CONFIRMED"));
      dispatch(handleGetUnpaidOrderGroups());
      dispatch(handleGetOrderPerformance(localStorage.getItem('orderPerformanceType')));
      dispatch(handleGetOrderByDate(trackOrderDate));
    }
  });

  const downloadReport = () => {
    let year = reportMonthYear.getFullYear();
    let month = reportMonthYear.getMonth() + 1;

    dispatch(handleGetMonthlyReport(year, month));
  }

  const handleOrderPerformanceType = (event, value) => {
    localStorage.setItem("orderPerformanceType", value)
    dispatch(handleGetOrderPerformance(value))
  };

  /** react-chartjs */
  const chartData2 = {
    labels: state.orderPerformance.map(x => x.orderDate),
    datasets: [
      {
        label: 'Success Orders',
        data: state.orderPerformance.map(x => x.successOrders),
        backgroundColor: 'rgba(67,203,200,1)',
        barWidth: 60
      },
      {
        label: 'Fail Orders',
        data: state.orderPerformance.map(x => x.failOrders),
        backgroundColor: 'rgba(227,74,74,1)',
      }
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Overall Performance',
      }
    }
  }
  /** react-chartjs end */

  return (
    <div className={`${deliveritNavState.open ? 'ml-60' : 'ml-12'}`}>
      <DeliveritNav />
      <div className="mb-10 pl-10 pt-10 text-left flex">
        <Grid container spacing={2}>        
          <Grid container spacing={2} xs={12}>
            <Grid item xs={12} md={4}>
              <Card className={styles.greenCard}>
                <CardContent className={styles.cardContentContainer}>
                  <div style={{flex: 3}}>
                    <Typography className={styles.greenFont}>{state.totalCompletedOrderGroups}</Typography>
                  </div>
                  <div style={{flex: 3}}>
                    <Typography className={styles.mediumCardText} gutterBottom>Completed Order Groups</Typography>
                    <Button onClick={() => window.open("/deliverit/completed-order-groups", "_blank")} className={styles.gradientButton}>View</Button>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card className={styles.orangeCard}>
                <CardContent className={styles.cardContentContainer}>
                  <div style={{flex: 3}}>
                    <Typography className={styles.orangeFont}>{state.totalConfirmedOrderGroups}</Typography>
                  </div>
                  <div style={{flex: 3}}>
                    <Typography className={styles.mediumCardText} gutterBottom>Confirmed Order Groups</Typography>
                    <Button onClick={() => window.open("/deliverit/confirmed-order-groups", "_blank")} className={styles.gradientButton}>View</Button>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card className={styles.redCard}>
                <CardContent className={styles.cardContentContainer}>
                  <div style={{flex: 3}}>
                    <Typography className={styles.redFont}>{state.totalUnpaidOrderGroups}</Typography>
                  </div>
                  <div style={{flex: 3}}>
                    <Typography className={styles.mediumCardText} gutterBottom>Unpaid Invoices</Typography>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <div className="mt-4 flex w-full items-center">
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12} md={6}>
                <p className="mt-2 ml-5"><Typography>Overall Performance <Chip label="Past 24 Weeks" style={{marginLeft: "1%"}} /></Typography></p>
              </Grid>
              <Grid item xs={12} md={6} style={{textAlign: 'right', paddingRight: "1%"}}>
                <ToggleButtonGroup exclusive value={state.orderPerformanceType} onChange={handleOrderPerformanceType}>
                  <ToggleButton value="daily" aria-label="daily">daily</ToggleButton>
                  <ToggleButton value="weekly" aria-label="weekly">weekly</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </div>
          <Grid item xs={12}>
            <Card>
              <CardContent className={styles.cardContentContainer}>
                <div style={{flex: 2, height: "450px"}}>
                  <Bar options={options} data={chartData2} />
                </div>
              </CardContent>
            </Card>
          </Grid>
          <div className="mt-5 ml-5 flex w-full items-center">
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12}>
                <p><Typography>TIMELINESS <Chip label="Past 24 Weeks" style={{marginLeft: "1%"}} /></Typography></p>
              </Grid>
              <Grid item xs={12} md={3}>
                <Card>
                  <CardContent className={styles.cardContentContainer}>
                    <div style={{flex: 2}}>
                      <CircularProgressbar 
                        value={state.timeliness.ontime.percentage} 
                        text={`${state.timeliness.ontime.percentage}%`} 
                        styles={{text: {fill: '#000'}, path: {stroke: `green`}}} />
                    </div>
                    <div style={{flex: 6, paddingLeft: `10%`}}>
                      <Typography className={styles.mediumCardText} gutterBottom>On Time</Typography>
                      <Typography className={styles.mediumCardText} gutterBottom>{state.timeliness.ontime.count}</Typography>
                      <Typography className={styles.mediumCardText} gutterBottom>&nbsp;</Typography>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <Card>
                  <CardContent className={styles.cardContentContainer}>
                    <div style={{flex: 2}}>
                      <CircularProgressbar 
                        value={state.timeliness.delay15.percentage} 
                        text={`${state.timeliness.delay15.percentage}%`} 
                        styles={{text: {fill: '#000'}, path: {stroke: `yellow`}}} />
                    </div>
                    <div style={{flex: 6, paddingLeft: `10%`}}>
                      <Typography className={styles.mediumCardText} gutterBottom>Delayed</Typography>
                      <Typography className={styles.smallCardText} gutterBottom>15 Min</Typography>
                      <Typography className={styles.mediumCardText} gutterBottom>{state.timeliness.delay15.count}</Typography>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <Card>
                  <CardContent className={styles.cardContentContainer}>
                    <div style={{flex: 2}}>
                      <CircularProgressbar 
                        value={state.timeliness.delay30.percentage}
                        text={`${state.timeliness.delay30.percentage}%`} 
                        styles={{text: {fill: '#000'}, path: {stroke: `orange`}}} />
                    </div>
                    <div style={{flex: 6, paddingLeft: `10%`}}>
                      <Typography className={styles.mediumCardText} gutterBottom>Delayed</Typography>
                      <Typography className={styles.smallCardText} gutterBottom>15 - 30 Min</Typography>
                      <Typography className={styles.mediumCardText} gutterBottom>{state.timeliness.delay30.count}</Typography>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <Card>
                  <CardContent className={styles.cardContentContainer}>
                    <div style={{flex: 2}}>
                      <CircularProgressbar 
                        value={state.timeliness.delay30More.percentage} 
                        text={`${state.timeliness.delay30More.percentage}%`} 
                        styles={{text: {fill: '#000'}, path: {stroke: `red`}}} />
                    </div>
                    <div style={{flex: 6, paddingLeft: `10%`}}>
                      <Typography className={styles.mediumCardText} gutterBottom>Delayed</Typography>
                      <Typography className={styles.smallCardText} gutterBottom>{"> 30 Min"}</Typography>
                      <Typography className={styles.mediumCardText} gutterBottom>{state.timeliness.delay30More.count}</Typography>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
          <Grid item xs={12}>
            <Divider variant='middle' xs={12} style={{marginTop: 40, marginBottom: 20}} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid item xs={12}>
              <p className="ml-2 mb-4"><Typography>Report</Typography></p>
            </Grid>
            <Card className="ml-2">
              <CardMedia
                className={styles.media}
                image="https://images.unsplash.com/photo-1540921002383-b2a7ff6a716d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"
                title="Monthly Report"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Monthly Report
                </Typography>
                <div className={styles.textInputContainer}>
                  <DatePicker
                    selected={reportMonthYear}
                    onChange={date => {
                      setReportMonthYear(date)
                    }}
                    showMonthYearPicker
                    dateFormat="MMMM, yyyy"
                    className={styles.textInput}
                    placeholderText={"Select Report Month"}
                  />
                </div>
              </CardContent>
              <CardActions>
                <Button size="small" color="primary" onClick={() => downloadReport()}>
                  Download
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Divider variant='middle' xs={12} style={{marginTop: 40, marginBottom: 20}} />
          </Grid>
          <Grid item xs={12}>
            <div className="mb-3 w-full pr-4">
              <DatePicker
                selected={trackOrderDate}
                onChange={date => {
                  setTrackOrderDate(date);
                  dispatch(handleGetOrderByDate(date));
                }}
                dateFormat="yyyy-MM-dd"
                className="rounded p-2 w-full outline-none border-[#b8b8b8] border-2"
                placeholderText={"Select Order Tracking Date"}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12}>
                <p><Typography>Delivery in Progress <Chip label={moment(trackOrderDate).isSame(moment(), 'day') ? "Today" : moment(trackOrderDate).format('yyyy-MM-DD')} style={{backgroundColor:"rgba(27,185,110,1)", color:"#fff", marginLeft: "3%"}} /></Typography></p>
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper} style={{}} sx={{ maxHeight: 800}}>
                  <Table>
                    <StickyTableHead className="!top-0">
                      <TableRow>
                        <TableCell align="left" className={styles.tableHeader}>{"Pickup Recipient"}</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>{"Pickup Status"}</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>{"Pickup ETA"}</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>{"Dropoff Recipient"}</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>{"Dropoff Status"}</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>{"Dropoff ETA"}</TableCell>
                      </TableRow>
                    </StickyTableHead>
                    {
                      state.todayInProgressOrders.length > 0 ? state.todayInProgressOrders.map(order =>  (
                        <TableRow>
                          <TableCell align="left" className={styles.tableCellClickable} onClick={() => window.open("/deliverit/confirmed-order-groups/" + order.pickupId, "_self")}>{order.pickupRecipient}</TableCell>
                          <TableCell align="left">
                            <Chip 
                              label={order.pickupStatus}
                              style={order.pickupStatus === 'SUCCESS' ? order.pickupSuccessStatusStyle :
                                order.pickupStatus === 'IN_TRANSIT' ? {backgroundColor:"rgba(255,152,99,1)",color:"#fff"} :
                                order.pickupStatus === 'FAILED' ? {backgroundColor:"red",color:"#fff"} :
                                {backgroundColor:"grey",color:"#fff"}
                              } />
                          </TableCell>
                          {order.pickupStatus === 'IN_TRANSIT' ? 
                            <TableCell align="left" className={styles.tableCellClickable} onClick={() => window.open(order.pickupTrackingLink, "_blank")}>View</TableCell> : 
                            <TableCell align="left">N/A</TableCell>
                          }
                          <TableCell align="left">{order.dropoffRecipient}</TableCell>
                          <TableCell align="left">
                            <Chip 
                              label={order.dropoffStatus}
                              style={order.dropoffStatus === 'SUCCESS' ? order.dropoffSuccessStatusStyle :
                                order.dropoffStatus === 'IN_TRANSIT' ? {backgroundColor:"rgba(255,152,99,1)",color:"#fff"} :
                                order.dropoffStatus === 'FAILED' ? {backgroundColor:"red",color:"#fff"} :
                                {backgroundColor:"grey",color:"#fff"}
                              } />
                          </TableCell>
                          {order.dropoffStatus === 'IN_TRANSIT' ? 
                            <TableCell align="left" className={styles.tableCellClickable} onClick={() => window.open(order.dropoffTrackingLink, "_blank")}>View</TableCell> : 
                            <TableCell align="left">N/A</TableCell>
                          }
                        </TableRow>
                      )) : 
                      <TableRow>
                        <TableCell colSpan={6} align="left">No orders in progress today</TableCell>
                      </TableRow>
                    }
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12}>
                <p><Typography>Completed Order <Chip label={moment(trackOrderDate).isSame(moment(), 'day') ? "Today" : moment(trackOrderDate).format('yyyy-MM-DD')} style={{backgroundColor:"rgba(27,185,110,1)", color:"#fff", marginLeft: "3%"}} /></Typography></p>
              </Grid>
              <Grid item xs={12}>
                <TableContainer component={Paper} style={{}} sx={{ maxHeight: 800}}>
                  <Table>
                    <StickyTableHead className="!top-0">
                      <TableRow>
                        <TableCell align="left" className={styles.tableHeader}>{"Pickup Recipient"}</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>{"Pickup Status"}</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>{"Dropoff Recipient"}</TableCell>
                        <TableCell align="left" className={styles.tableHeader}>{"Dropoff Status"}</TableCell>
                      </TableRow>
                    </StickyTableHead>
                    {
                      state.todayCompletedOrders.length > 0 ? state.todayCompletedOrders.map(order =>  (
                        <TableRow>
                          <TableCell align="left" className={styles.tableCellClickable} onClick={() => window.open("/deliverit/completed-order-groups/" + order.pickupId, "_self")}>{order.pickupRecipient}</TableCell>
                          <TableCell align="left">
                            <Chip 
                              label={order.pickupStatus}
                              style={order.pickupStatus === 'SUCCESS' ? order.pickupSuccessStatusStyle :
                                order.pickupStatus === 'IN_TRANSIT' ? {backgroundColor:"rgba(255,152,99,1)",color:"#fff"} :
                                order.pickupStatus === 'FAILED' ? {backgroundColor:"red",color:"#fff"} :
                                {backgroundColor:"grey",color:"#fff"}
                              } />
                          </TableCell>
                          <TableCell align="left">{order.dropoffRecipient}</TableCell>
                          <TableCell align="left">
                            <Chip 
                              label={order.dropoffStatus}
                              style={order.dropoffStatus === 'SUCCESS' ? order.dropoffSuccessStatusStyle :
                                order.dropoffStatus === 'IN_TRANSIT' ? {backgroundColor:"rgba(255,152,99,1)",color:"#fff"} :
                                order.dropoffStatus === 'FAILED' ? {backgroundColor:"red",color:"#fff"} :
                                {backgroundColor:"grey",color:"#fff"}
                              } />
                          </TableCell>
                        </TableRow>
                      )) : 
                      <TableRow>
                        <TableCell colSpan={6} align="left">No order complete today</TableCell>
                      </TableRow>
                    }
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
