import { Toolbar, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useDispatch } from "react-redux";
import { setRentingActionUrl, setRentingActionMethod, showEditRentingModal, showEditListingModal, showCreateAppointmentModal, showTerminateModal, showSwapParkingModal, showFreezeRentingModal } from "./masterlistSlice";
import { ActionablesDto, ActionablesTitle } from "../../../app/constants";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: 2,
        paddingRight: 1,
    },
    title: {
        flex: '1 1 100%',
        minWidth: 100,
    },
    toolTipContainer: {
        position: "absolute",
        right: 10
    }
}));

export const TableToolbar = (props) => {
    const dispatch = useDispatch();
    const classes = useToolbarStyles();
    const { numSelected, title, actionables = [] } = props;

    const freezeAction = actionables.filter((actionable) => actionable.title === ActionablesTitle.Freeze)[0];
    const swapAction = actionables.filter((actionable) => actionable.title === ActionablesTitle.Swap)[0];
    const terminateAction = actionables.filter((actionable) => actionable.dto === ActionablesDto.TerminateRentingRequest && actionable.title === ActionablesTitle.Terminate)[0];

    return (
        <Toolbar>
            {numSelected > 0 ? (
                <div className="flex w-full flex-row justify-between items-center">
                    <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                        {numSelected} selected
                    </Typography>
                    <div className="flex flex-row items-center gap-2">
                        <button className="text-primaryblue text-sm font-medium hover:bg-slate-100 rounded px-4 py-2" onClick={() => dispatch(showEditRentingModal(true))}>Edit Renting</button>
                        <button className="text-primaryblue text-sm font-medium hover:bg-slate-100 rounded px-4 py-2" onClick={() => dispatch(showEditListingModal(true))}>Edit Listing</button>
                        {freezeAction && (
                            <button
                                className="text-primaryblue text-sm font-medium hover:bg-slate-100 rounded px-4 py-2"
                                onClick={() => {
                                    dispatch(showFreezeRentingModal(true));
                                    dispatch(setRentingActionUrl(freezeAction.url));
                                    dispatch(setRentingActionMethod(freezeAction.type));
                                }}
                            >
                                Freeze
                            </button>
                        )}
                        {swapAction && (
                            <button
                                className="text-primaryblue text-sm font-medium hover:bg-slate-100 rounded px-4 py-2"
                                onClick={() => {
                                    dispatch(showSwapParkingModal(true));
                                    dispatch(setRentingActionUrl(swapAction.url));
                                    dispatch(setRentingActionMethod(swapAction.type));
                                }}
                            >
                                Swap
                            </button>
                        )}
                        {terminateAction && (
                            <button
                                className="text-primaryblue text-sm font-medium hover:bg-slate-100 rounded px-4 py-2"
                                onClick={() => {
                                    dispatch(showTerminateModal(true));
                                    dispatch(setRentingActionUrl(terminateAction.url));
                                    dispatch(setRentingActionMethod(terminateAction.type));
                                }}
                            >
                                Terminate
                            </button>
                        )}
                        <button className="whitespace-nowrap text-primaryblue text-sm font-medium hover:bg-slate-100 rounded px-4 py-2" onClick={() => dispatch(showCreateAppointmentModal(true))}>Appointment</button>
                    </div>
                </div>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    {title}
                </Typography>
            )}
        </Toolbar>
    );
};