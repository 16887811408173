import { Toolbar, Typography, Tooltip, IconButton } from "@mui/material";
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: 2,
      paddingRight: 1,
    },
    title: {
      flex: '1 1 100%',
    },
    toolTipContainer: {
        position: "absolute",
        right: 10
    }
}));

export const TableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, createDeliveryRateProfile, editDeliveryRate, deleteDeliveryRateProfile } = props;
    return (
        <Toolbar>
            { numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Delivery Rate List
                </Typography>
            )}

            { numSelected > 0 ? (
                <div className={classes.toolTipContainer}>
                    <Tooltip title="Edit" onClick={editDeliveryRate}>
                        <IconButton aria-label="edit">
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete" onClick={deleteDeliveryRateProfile}>
                        <IconButton aria-label="delete">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            ) : (
                <button onClick={createDeliveryRateProfile} className="bg-gradient-to-r from-[#6AC4C5] to-[#387596] shadow-md text-white h-12 px-8 w-48 rounded">Create Rate</button>
            )}
        </Toolbar>
    );
};