import axiosApiInstance from "../../../app/axios";
import { PARKIT_API_BASE_URL } from "../../../app/utils";
import { getJwtToken } from "../../../app/common";

export const getAllParkingAreas = async (page, size, sort, sortBy, searchField, searchValue) =>
  axiosApiInstance.get(
    `${PARKIT_API_BASE_URL}/parkit/v2/parking-areas?&page=${page}&size=${size}&sort=${sort}&sortBy=${sortBy}${searchValue ? `&${searchField}=${searchValue}`: ''}`,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const getNearbyParkingAreas = async (latitude, longitude, distance) =>
  axiosApiInstance.get(
    `${PARKIT_API_BASE_URL}/parkit/v2/public/parking-areas?latitude=${latitude}&longitude=${longitude}&distance=${distance}`,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const createParkingArea = async (data) =>
  axiosApiInstance.post(
    `${PARKIT_API_BASE_URL}/parkit/v2/parking-areas`,
    data,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const updateParkingArea = async (id, data) =>
  axiosApiInstance.put(
    `${PARKIT_API_BASE_URL}/parkit/v2/parking-areas/${id}`,
    data,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const deleteParkingArea = async (id) =>
  axiosApiInstance.delete(
    `${PARKIT_API_BASE_URL}/parkit/v2/parking-areas/${id}`,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const uploadImage = async (id, data) =>
  axiosApiInstance.post(
    `${PARKIT_API_BASE_URL}/parkit/v2/parking-areas/${id}/photos`,
    data,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );