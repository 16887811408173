import axiosApiInstance from "../app/axios";
import { PARKIT_API_BASE_URL } from "../app/utils";
import { getJwtToken } from "../app/common";

export const getWorkflowTasks = async (page, size) =>
  axiosApiInstance.get(`${PARKIT_API_BASE_URL}/parkit/v2/workflow/tasks`, {
    headers: { Authorization: `Bearer ${getJwtToken()}` },
  });

export const getListings = async (page, size, sort, sortBy, status, search, searchType, filter) =>
  axiosApiInstance.get(
    `${PARKIT_API_BASE_URL}/parkit/v2/listings?page=${page}&size=${size}&sort=${sort}&sortBy=${sortBy}&status=${status}${search ? '&search=' + search + '&searchType=' + searchType : ''}${filter ? '&filter=' + filter : ''}`,
    { headers: { Authorization: `Bearer ${getJwtToken()}` } }
  );

export const getListingsWithFilter = async (page, size, sort, sortBy, status, filter) =>
  axiosApiInstance.get(
    `${PARKIT_API_BASE_URL}/parkit/v2/listings?page=${page}&size=${size}&sort=${sort}&sortBy=${sortBy}&status=${status}&${filter}=true`,
    { headers: { Authorization: `Bearer ${getJwtToken()}` } }
  );

export const updateListing = async (id, data) =>
  axiosApiInstance.post(`${PARKIT_API_BASE_URL}/parkit/v2/listings/${id}/patch`, data, {
    headers: { Authorization: `Bearer ${getJwtToken()}` },
  });