import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { selectState, setSelectedRentingFilter, showFilterRentingModal } from './terminationSlice';
import { RentingStatus, ListingStatus } from '../../../app/constants';

const FilterModal = () => {
  const state = useSelector(selectState);
  const dispatch = useDispatch();
  
  const selectedFilter = state.selectedRentingFilter;
  const [status, setStatus] = useState(selectedFilter.status);
  const [listingStatus, setListingStatus] = useState(selectedFilter.listingStatus);

  const submit = () => {
    try {
      dispatch(setSelectedRentingFilter({
        status,
        listingStatus
      }));
      dispatch(showFilterRentingModal(false));
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Modal
      open={true}
      aria-labelledby="filter-renting-modal"
      className="flex p-[10px] items-center justify-center"
    >
      <Paper className="scrollbar-hidden absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[80%] h-[30%] max-w-[800px] max-h-[80%] overflow-scroll p-[20px]">
        <Close
          className="float-right cursor-pointer"
          onClick={() => dispatch(showFilterRentingModal(false))}
        />
        <div className="px-[20px] pt-[10px] pb-[15px]">
          <p className="mb-[10px] text-[20px] font-medium">Filter Rentings</p>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl margin="normal" variant="outlined" fullWidth>
                <InputLabel htmlFor="status">Driver Status</InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                  input={<OutlinedInput name="status" id="status" label="Driver Status" />}
                >
                  <MenuItem key={"clearFilter"} value={null}>*** Clear Filter ***</MenuItem>
                  <MenuItem key={RentingStatus.TERMINATING} value={RentingStatus.TERMINATING}>{RentingStatus.TERMINATING}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl margin="normal" variant="outlined" fullWidth>
                <InputLabel htmlFor="listing-status">Owner Status</InputLabel>
                <Select
                  fullWidth
                  onChange={(e) => setListingStatus(e.target.value)}
                  value={listingStatus}
                  input={<OutlinedInput name="listingStatus" id="listing-status" label="Owner Status" />}
                >
                  <MenuItem key={"clearFilter"} value={null}>*** Clear Filter ***</MenuItem>
                  {Object.keys(ListingStatus).map((key) => (
                    <MenuItem key={key} value={ListingStatus[key]}>
                      {ListingStatus[key].replace(/_/g, ' ')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} className="flex flex-grow justify-center">
              <button
                onClick={submit}
                className="bg-[#DE4D65] mt-[20px] px-[50px] py-[8px] text-white text-[15px] font-medium rounded-[5px] shadow-md"
              >
                Filter
              </button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default FilterModal;