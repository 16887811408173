export const API_BASE_URL = window.REACT_APP_API_GATEWAY_URL;
export const PARKIT_API_BASE_URL = window.REACT_APP_PARKIT_API_BASE_URL;
export const KEYCLOAK_BASE_URL = window.REACT_APP_KEYCLOAK_BASE_URL;
export const ELASTIC_SEARCH_TOKEN = window.REACT_APP_ELASTIC_SEARCH_TOKEN;

export function centToDollar(dollar = 0) {
  return (dollar / 100).toFixed(2);
}

export const dollarToCent = (dollar) => Number((dollar * 100).toFixed(0));

export const addFilter = (list, key, value) => {
  if (value) {
    list.push(`${key}:${value}`);
  }
  return list;
};

export const formatCurrency = (currency) => {
  switch (currency) {
    case 'MYR':
      return 'RM';
    case 'SGD':
      return 'S$';
    case 'HKD':
      return 'HK$';
    default:
      return 'RM';
  }
};
