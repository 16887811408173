import React from "react";
import { useKeycloak } from "keycloak-react-web";
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Avatar, Button, Tooltip, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AdbIcon from '@mui/icons-material/Adb';
import { logout } from "../../app/common";

import { ReactComponent as HomeIcon } from '../../assets/icons/home.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/users.svg';
import { ReactComponent as VoucherIcon } from '../../assets/icons/vouchers.svg';
import ParkitIcon from '../../assets/icons/parkit_icon.png';
import DeliverItIcon from '../../assets/icons/deliverit_icon.png';

const Nav = () => {
  const { keycloak } = useKeycloak();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (setting) => {
    if (setting && setting === 'Logout') {
      logout();
    }
    setAnchorElUser(null);
  };

  const pages = [{
    label: 'Home',
    path: '/home',
    icon: <HomeIcon width={16} height={16} />,
  }, {
    label: 'Users',
    path: '/users',
    icon: <UserIcon className="text-[#233794]" width={18} height={18} />,
  }, {
    label: 'Vouchers',
    path: '/vouchers',
    icon: <VoucherIcon width={18} height={18} />,
  }, {
    label: 'DeliverIt',
    path: '/deliverit',
    icon: <img alt="deliverit" src={DeliverItIcon} width={16} height={20} />,
  }, {
    label: 'ParkIt',
    path: '/parkit',
    icon: <img alt="parkit" src={ParkitIcon} width={20} height={20} />,
  }];
  const settings = ['Logout'];
  return (
    <div className="sticky top-0 z-10">
      <AppBar position="static" sx={{ backgroundColor: "#EFF1F5" }}>
        <Container maxWidth="xl">
          <Toolbar id="appBar" disableGutters>
            <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 2, color: "#152C7D" }} />
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, color: "#152C7D" }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={'menuitem' + page.label} onClick={() => window.location.href = page.path}>
                    <Typography textAlign="center" sx={{ color: "#696969" }}>{page.label}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, color: "#152C7D" }} />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: "#152C7D",
                textDecoration: 'none',
              }}
            >
              Admin
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <Button
                  key={'button' + page.label}
                  onClick={() => window.location.href = page.path}
                  sx={{ my: 2, color: '#696969', display: 'block', textTransform: 'none', px: 3 }}
                >
                  <div className="flex items-center gap-1">
                    {page.icon}
                    {page.label}
                  </div>
                </Button>
              ))}
            </Box>

            {!keycloak.authenticated && (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Login">
                  <Button
                    onClick={() => keycloak.login()}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    Login
                  </Button>
                </Tooltip>
              </Box>
            )}

            {!!keycloak.authenticated && (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar sx={{backgroundColor: '#D8DEEE', color: '#152C7D'}} alt="Admin" src="/static/images/avatar/2.jpg" />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default Nav;