import { Toolbar, Typography, Tooltip, IconButton } from "@mui/material";
import { makeStyles } from '@mui/styles';
import BlockIcon from '@mui/icons-material/Block';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: 2,
      paddingRight: 1,
    },
    title: {
      flex: '1 1 100%',
    },
}));

export const TableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, cancelOrder, orderGroup } = props;
  
    return (
        <Toolbar>
            { numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Order List
                </Typography>
            )}

            {
                orderGroup && orderGroup.status === 'CONFIRMED' &&
                <>
                { 
                    numSelected > 0 &&
                    <Tooltip title="Cancel Order" onClick={cancelOrder}>
                        <IconButton aria-label="cancel">
                            <BlockIcon />
                        </IconButton>
                    </Tooltip>
                }
                </>
            }
        </Toolbar>
    );
};