import { Toolbar, Typography } from "@mui/material";

export const TableToolbar = (props) => {
    const { numSelected } = props;
    return (
        <Toolbar>
            { numSelected > 0 ? (
                <Typography color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography variant="h6" id="tableTitle" component="div" style={{flex: '1 1 100%'}}>
                    Wallet List
                </Typography>
            )}
        </Toolbar>
    );
};