import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getDeliveryRateProfiles } from './deliveryRateAPI';

export const deliveryRateSlice = createSlice({
  name: 'deliveryRate',
  initialState: {
    deliveryRates: [],
    status: 'idle',
    createDeliveryRateModalOpen: false,
    editDeliveryRateModalOpen: false,
    deleteDeliveryRateModalOpen: false,
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null
  },
  reducers: {
    openCreateDeliveryRateModal: (state) => {
      state.createDeliveryRateModalOpen = true;
    },
    closeCreateDeliveryRateModal: (state) => {
      state.createDeliveryRateModalOpen = false;
    },
    openEditDeliveryRateModal: (state) => {
      state.editDeliveryRateModalOpen = true;
    },
    closeEditDeliveryRateModal: (state) => {
      state.editDeliveryRateModalOpen = false;
    },
    openDeleteDeliveryRateModal: (state) => {
      state.deleteDeliveryRateModalOpen = true;
    },
    closeDeleteDeliveryRateModal: (state) => {
      state.deleteDeliveryRateModalOpen = false;
      state.selectedId = null;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setDeliveryRateProfiles: (state, action) => {
      state.deliveryRates = action.payload;
      state.status = 'success'
    },
  },
});

export const { openCreateDeliveryRateModal, closeCreateDeliveryRateModal, openEditDeliveryRateModal, closeEditDeliveryRateModal, openDeleteDeliveryRateModal, closeDeleteDeliveryRateModal, setSelectedId, setPage, setRowsPerPage, setPagination, setDeliveryRateProfiles } = deliveryRateSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.deliveryRate.value)`
export const selectState = state => state.deliveryRate;

export const handleGetDeliveryRateProfiles = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const response = await getDeliveryRateProfiles(state.deliveryRate.page, state.deliveryRate.rowsPerPage);
    const { content, ...pagination } = response.data;
    let deliveryRates = content;

    dispatch(setPagination(pagination));
    dispatch(setDeliveryRateProfiles(deliveryRates));
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(hideLoading());
  }
};


export default deliveryRateSlice.reducer;
