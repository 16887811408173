import { API_BASE_URL } from "../../app/utils";
import { getJwtToken } from "../../app/common";

export const getVouchers = (page, size) =>
  fetch(`${API_BASE_URL}/wallet/v1/promo-codes?page=${page}&size=${size}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const getRedeemedVouchers = (code) =>
  fetch(`${API_BASE_URL}/wallet/v1/vouchers?code=${code}&applied=true`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const createVoucher = (data) =>
  fetch(`${API_BASE_URL}/wallet/v1/promo-codes`, {
    crossDomain: true,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    },
    body: JSON.stringify(data)
  }).then(res => res.json());

export const deactivateVoucher = (id, data) =>
  fetch(`${API_BASE_URL}/wallet/v1/promo-codes/${id}`, {
    crossDomain: true,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    },
    body: JSON.stringify({
      ...data,
      active: false
    })
  }).then(res => res.json());

export const activateVoucher = (id, data) =>
  fetch(`${API_BASE_URL}/wallet/v1/promo-codes/${id}`, {
    crossDomain: true,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwtToken()}`
    },
    body: JSON.stringify({
      ...data,
      active: true
    })
  }).then(res => res.json());

export const getVoucherByCode = (code) =>
  fetch(`${API_BASE_URL}/wallet/v1/promo-codes?code=${code}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());