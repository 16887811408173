import { createSlice } from "@reduxjs/toolkit";
import { showLoading, hideLoading } from "react-redux-loading-bar";

export const deleteRequestSlice = createSlice({
  name: "deleteRequest",
  initialState: {
    deleteRequests: [],
    status: "idle",
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null,
    approveModalOpen: false,
    successMessage: "",
    errorMessage: "",
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setDeleteRequests: (state, action) => {
      state.deleteRequests = action.payload;
      state.status = "success";
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    showApproveModal: (state, action) => {
      state.approveModalOpen = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  },
});

export const {
  setPage,
  setRowsPerPage,
  setPagination,
  setDeleteRequests,
  setSelectedId,
  showApproveModal,
  setSuccessMessage,
  setErrorMessage,
} = deleteRequestSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.vendor.value)`
export const selectState = (state) => state.deleteRequest;

export const handleGetRequests = () => async (dispatch, getState) => {
  dispatch(showLoading());
  // const state = getState();

  try {
    // TODO: update API
    // const response = await getDeleteRequests(
    //   state.deleteRequest.page,
    //   state.deleteRequest.rowsPerPage
    // );
    // const { content, ...pagination } = response.data;
    // dispatch(setPagination(pagination));
    // dispatch(setDeleteRequests(content));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleApproveRequest = (id) => async (dispatch) => {
  dispatch(showLoading());

  try {
    // await approveDeleteRequest(id); // TODO: update API
    dispatch(showApproveModal(false));
    dispatch(setSuccessMessage("Successfully approved delete user request!"));
    dispatch(handleGetRequests());
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export default deleteRequestSlice.reducer;
