import { Toolbar, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: 2,
        paddingRight: 1,
    },
    title: {
        flex: '1 1 100%',
    },
    toolTipContainer: {
        position: "absolute",
        right: 10
    }
}));

export const TableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { title } = props;
    return (
        <Toolbar>
            <div className="flex w-full flex-row justify-between items-center">
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    {title}
                </Typography>
            </div>
        </Toolbar>
    );
};