import axiosApiInstance from "../app/axios";
import { API_BASE_URL, PARKIT_API_BASE_URL } from "../app/utils";
import { getJwtToken } from "../app/common";

export const getParkitSubscriptions = async (page, size, statuses) =>
  axiosApiInstance.get(
    `${PARKIT_API_BASE_URL}/parkit/v2/subscriptions?page=${page}&size=${size}&statuses=${statuses}`,
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );

export const manualChargeParkitSubscription = async (subscription_id) =>
  axiosApiInstance.post(
    `${API_BASE_URL}/wallet/v1/wallets/*/subscriptions/${subscription_id}/manual-charge`,
    {},
    { headers: { "Authorization": `Bearer ${getJwtToken()}` } }
  );
