import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { KeycloakProvider } from "keycloak-react-web";
import { store } from './app/store';
import App from './App';
import keycloak from './Keycloak';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { ToastProvider } from 'react-toast-notifications';

const container = document.getElementById('root');
const root = createRoot(container);

const keycloakProviderInitConfig = {
  onLoad: "login-required",
  checkLoginIframe: false,
};

root.render(
  <Provider store={store}>
    <KeycloakProvider
      client={keycloak}
      initOptions={keycloakProviderInitConfig}
    >
      <ToastProvider
        autoDismiss
        autoDismissTimeout={6000}
        placement="bottom-center"
      >
        <App />
      </ToastProvider>
    </KeycloakProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
