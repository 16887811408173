import axiosApiInstance from "../app/axios";
import { API_BASE_URL } from "../app/utils";
import { getJwtToken } from "../app/common";

export const getUserByUsername = async (username) =>
  axiosApiInstance.get(`${API_BASE_URL}/user-store/v1/users?userName=${username}`, {
    headers: { Authorization: `Bearer ${getJwtToken()}` },
  });

export const getUsers = (page, size, search) =>
  fetch(`${API_BASE_URL}/user-store/v1/users?page=${page}&size=${size}${search ? '&search=' + search : ''}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const getUser = (id) =>
  fetch(`${API_BASE_URL}/user-store/v1/users/${id}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const updateUser = (id, data) =>
  axiosApiInstance.put(`${API_BASE_URL}/user-store/v1/users/${id}`,
    data,
    { headers: { Authorization: `Bearer ${getJwtToken()}` } }
  );

export const searchUsers = (search) =>
  fetch(`${API_BASE_URL}/parkit-elastic-search/v1/user/search?term=${search}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());