import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Modal, Paper, Grid, Fade, Button, Switch } from "@mui/material";
import { closeEditVendorModal, handleGetVendors } from './vendorSlice';
import { Close } from "@mui/icons-material";
import styles from './Vendor.module.css';
import "react-datepicker/dist/react-datepicker.css";
import { updateVendor } from './vendorAPI';

export function EditVendor(props) {
  const { vendor } = props;
  const dispatch = useDispatch();
  const [vendorState, _setVendorState] = useStateWithCallbackLazy({
    form: {
      ...vendor
    },
    error: false,
    init: false,
    errorMessage: ""
  });

  const vendorStateRef = useRef(vendorState);

  const setVendorState = data => {
    vendorStateRef.current = data;
    _setVendorState(data);
  };

  useEffect(() => {
    if (vendor && !vendorState.init) {
      const {...vendorForm} = {...vendor};
      setVendorState({
        ...vendorState,
        init: true,
        form: {
          ...vendorForm
        }
      });
    }
  });

  const onPickupNotifcationChange = event => {
    setVendorState({
      ...vendorState,
      form: {
        ...vendorState.form,
        pickupNotification: event.target.checked
      }
    })
  }

  const submit = async (props) => {
    let error = false;
    if (!error) {
      try {
        await updateVendor(vendorState.form, vendorState.form.id);
        dispatch(closeEditVendorModal());
        dispatch(handleGetVendors());
      } catch (error) {
        setVendorState({...vendorState, error: true, errorMessage: error.message});
      }
    }
  }

  return (
    <Modal
      open={true}
      aria-labelledby="add-order-group-modal"
      className={styles.modal}
    >
      <Paper className={`${styles.modalContainer} scrollbar-hidden`}>
        <div className={styles.container}>
          <Close className={styles.closeButton} onClick={() => dispatch(closeEditVendorModal())}/>
          <h3 className={styles.subtitle}>Edit Vendor<em style={{fontSize: 12}}></em></h3>
          <Fade in={vendorState.error}>
            <p className={styles.errorMessage}>{vendorState.errorMessage}</p>
          </Fade>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <p className={styles.textInputLabel}>Pickup Notification</p>
              <Grid component="label" container alignItems="center" spacing={2}>
                  <Grid item>Disable</Grid>
                  <Grid item>
                      <Switch
                          color="primary"
                          checked={vendorState.form.pickupNotification === true}
                          onChange={onPickupNotifcationChange}
                      />
                  </Grid>
                  <Grid item>Enable</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={() => submit(props)} className={styles.gradientButton}>Save</Button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
}
