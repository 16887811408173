import axiosApiInstance from "../../../app/axios";
import { API_BASE_URL } from "../../../app/utils";
import { getJwtToken } from "../../../app/common";

export const getMonthlyReport = async (year, month) =>
  axiosApiInstance.get(
    `${API_BASE_URL}/deliverit/v1/reportings?year=${year}&month=${month}&status=OPEN,CONFIRMED,ASSIGNED,IN_TRANSIT,SUCCESS,PARTIAL_SUCCESS,FAILED,CANCELLED`,
    {
      responseType: 'blob',
      headers: {
        "Authorization": `Bearer ${getJwtToken()}`,
        "Content-Type": 'text/csv'
      }
    }
  );