import { IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import TopUpIcon from '@mui/icons-material/MonetizationOn';
import WalletIcon from '@mui/icons-material/AccountBalanceWallet';

export const TableToolbar = (props) => {
    const { selectedId, numSelected, adminTopUp } = props;
    return (
        <Toolbar>
            { numSelected > 0 ? (
                <div className="flex w-full flex-row justify-between items-center">
                    <Typography color="inherit" variant="subtitle1" component="div">
                        {numSelected} selected
                    </Typography>
                    <div className="flex flex-row items-center gap-2">
                        <Tooltip title="Manual top up" onClick={adminTopUp}>
                            <IconButton aria-label="topup">
                                <TopUpIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="View subscriptions" onClick={() => window.open(`/users/${selectedId}/subscriptions`, '_blank')}>
                            <IconButton aria-label="subscriptions">
                                <WalletIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            ) : (
                <>
                    <Typography variant="h6" id="tableTitle" component="div" style={{flex: '1 1 100%'}}>
                        User List
                    </Typography>
                </>
            )}
        </Toolbar>
    );
};