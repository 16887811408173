import { API_BASE_URL } from "../../app/utils";
import { getJwtToken } from "../../app/common";

export const getUsers = (page, size, search) =>
  fetch(`${API_BASE_URL}/parkit-elastic-search/v1/user/search?page=${page}&size=${size}${search ? '&term=' + search : ''}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

export const getUser = (id) =>
  fetch(`${API_BASE_URL}/user-store/v1/users/${id}`, {
    crossDomain: true,
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwtToken()}`
    }
  }).then(res => res.json());

