import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getUserWallet } from '../../api/walletAPI';
import * as Api from "./userAPI";

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    users: [],
    status: 'idle',
    adminTopUpModalOpen: false,
    walletTransactionModalOpen: false,
    searchValue: '',
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    selectedId: null,
    selectedWalletId: null,
  },
  reducers: {
    openAdminTopUpModal: (state) => {
      state.adminTopUpModalOpen = true;
    },
    closeAdminTopUpModal: (state) => {
      state.adminTopUpModalOpen = false;
    },
    openWalletTransactionModal: (state) => {
      state.walletTransactionModalOpen = true;
    },
    closeWalletTransactionModal: (state) => {
      state.walletTransactionModalOpen = false;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setSelectedWalletId: (state, action) => {
      state.selectedWalletId = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
      state.status = 'success';
    },
    getUsers: (state, action) => {
      state.users = action.payload;
      state.status = 'success'
    },
  },
});

export const { openAdminTopUpModal, closeAdminTopUpModal, openWalletTransactionModal, closeWalletTransactionModal, setSelectedId, setSelectedWalletId, setSearchValue, setPage, setRowsPerPage, setPagination, getUsers } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.user.value)`
export const selectState = state => state.user;

const handleAddWalletToUsers = async (users) => {
  return await Promise.all(
    users.map(async (user) => {
      try {
        const wallet = await getUserWallet(user.id);
        return { ...user, wallet };
      } catch {
        return { ...user };
      }
    })
  );
}

export const handleGetUsers = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const response = await Api.getUsers(state.user.page, state.user.rowsPerPage, state.user.searchValue);
    const { content, pagination } = response;

    let users = [];
    users = content;
    dispatch(setPagination(pagination));

    const usersWithWallet = await handleAddWalletToUsers(users);
    dispatch(getUsers(usersWithWallet));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(hideLoading());
  }
};

export default userSlice.reducer;
