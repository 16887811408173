import axiosApiInstance from "../app/axios";
import { PARKIT_API_BASE_URL } from "../app/utils";
import { getJwtToken } from "../app/common";

export const getBuildingEnquiries = async (page, size, sort, sortBy) =>
  axiosApiInstance.get(
    `${PARKIT_API_BASE_URL}/parkit/v2/enquiries?page=${page}&size=${size}&sort=${sort}&sortBy=${sortBy}`,
    { headers: { Authorization: `Bearer ${getJwtToken()}` } }
  );

export const updateBuildingEnquiry = async (id, data) =>
  axiosApiInstance.put(
    `${PARKIT_API_BASE_URL}/parkit/v2/enquiries/${id}`,
    data,
    { headers: { Authorization: `Bearer ${getJwtToken()}` } }
  );

export const deleteBuildingEnquiry = async (id) =>
  axiosApiInstance.delete(
    `${PARKIT_API_BASE_URL}/parkit/v2/enquiries/${id}`,
    { headers: { Authorization: `Bearer ${getJwtToken()}` } }
  );

export const getBulkListingEnquiries = async (page, size, sort, sortBy) =>
  axiosApiInstance.get(
    `${PARKIT_API_BASE_URL}/parkit/v2/bulk-listing-enquiry-form?page=${page}&size=${size}&sort=${sort}&sortBy=${sortBy}`,
    { headers: { Authorization: `Bearer ${getJwtToken()}` } }
  );

export const deleteBulkListingEnquiry = async (id) =>
  axiosApiInstance.delete(
    `${PARKIT_API_BASE_URL}/parkit/v2/bulk-listing-enquiry-form/${id}`,
    { headers: { Authorization: `Bearer ${getJwtToken()}` } }
  );

export const getBulkRentingEnquiries = async (page, size, sort, sortBy) =>
  axiosApiInstance.get(
    `${PARKIT_API_BASE_URL}/parkit/v2/bulk-renting-enquiry-form?page=${page}&size=${size}&sort=${sort}&sortBy=${sortBy}`,
    { headers: { Authorization: `Bearer ${getJwtToken()}` } }
  );

export const deleteBulkRentingEnquiry = async (id) =>
  axiosApiInstance.delete(
    `${PARKIT_API_BASE_URL}/parkit/v2/bulk-renting-enquiry-form/${id}`,
    { headers: { Authorization: `Bearer ${getJwtToken()}` } }
  );
