import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
};

const deliveritNavSlice = createSlice({
  name: 'deliveritNav',
  initialState,
  reducers: {
    openDeliveritNav: (state) => {
      state.open = true;
    },
    closeDeliveritNav: (state) => {
      state.open = false;
    },
  },
});

export const selectState = state => state.deliveritNav;
export const { openDeliveritNav, closeDeliveritNav } = deliveritNavSlice.actions;
export default deliveritNavSlice.reducer;